import React from 'react';
import thanks from '../resources/images/thankyou/done.png'
import Footer2 from '../components/Footer/footer2'

export default function ThankYou() {
  return (
   <div style={{paddingTop: '16px'}}>
      <div style={{width: '100%', maxWidth: '800px', margin: '40px auto 0px', border: '1px solid #333', backgroundColor: 'aliceblue'}} >
	<div style={{display: 'grid', justifyContent: 'center'}}>
	<h1>Thank You!</h1>
		
	<img src={thanks} width="100" height="100" style={{margin: '0px auto 20px'}} alt=""/>
		
     <p style={{textAlign: 'center'}} >
	Thanks a bunch for filling that out. It means a lot to us, 
	just like you do! We really appreciate you giving us a 
	moment of your time today. thanks for being you. </p>		
			
	<p style={{margin: '20px 0', textAlign: 'center'}}>
		Copyright © 2021 - 2023, Farehuts. All rights reserved</p>		
	
  </div>
	
	
	</div>
    <Footer2/>
    </div>
  )
}
