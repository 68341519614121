/* eslint-disable no-unused-vars */
import React from 'react';
import '../resources/css/onlinecheckin.css'
import Footer from '../components/Footer/footer';
import MetaTitle from './metaTitle';
import SITE_CONFIG from '../SiteController/SiteController';

export default function CheckIn() {
	const { apiIP, siteName, siteID } = SITE_CONFIG;
	const openLink = (url) => {
		window.open(url, '_blank', 'width=650,height=550,left=300,top=50');
	  };
  return (
	<>
	<MetaTitle url={`${apiIP}/online-check-in`} pageTitle="Find Online Check-in info on Rawfares" pageDescription="You can easily Find Online Check-in info on Rawfares." />
    <div class="container-fluid">
			
			<div class="row">
			<div class="content_page_title" style={{marginTop: '75px'}}>
			<div class="container">
			<h1>Online Check-in</h1>
			</div>
			</div>
				
		<div class="textBox">
        <div class="container">
          <div class="row">
			<div class="content-wrap">
                <div class="tabcontent2"> 
	  
	  <div class="data table-responsive">
                 
             <div class="baggage-layer">
                        <div class="row">
                           
                            <div class="col-md 8 col-sm-7 col-xs-6">Airlines</div>
                            <div class="col-md-4 col-sm-5 col-xs-6 text-right">Online Check-in</div>
                          </div>
                      </div>
                       <div class="baggage-layer hidden-xs">
                        <div class="row">
                         
                            <div class="col-md 8 col-sm-7 col-xs-6">Airlines</div>
                            <div class="col-md-4 col-sm-5 col-xs-6  text-right ">Online Check-in</div>
                          </div>
                      </div>
           
           <div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src='https://imgfolders.com/farehutz/A3.png' alt="A3"/> <b>AEGEAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://en.aegeanair.com/plan/check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/EI.png" alt="EI"/> <b>AER LINGUS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://webcheckin.aerlingus.com/html/checkIn/checkin.html" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/SU.png" alt="SU"/> <b>AEROFLOT</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.aeroflot.ru/ru-en/information/airport/checkin/web_checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AR.png" alt="AR"/> <b>AEROLINEAS ARGENTINAS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.aerolineas.com.ar/en-nz/booking_and_services/webcheckindx?zrdct=true" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/VW.png" alt="VW"/> <b>AEROMAR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.aeromar.mx/en/web-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AM.png" alt="AM"/> <b>AEROMEXICO</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://aeromexico.com/en-us/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/V5.png" alt="V5"/> <b>AEROVIAS DAP</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://wc2-v5.kiusys.net/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AH.png" alt="AH"/> <b>AIR ALGERIE</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://airalgerie.dz/en/plan-your-trip/check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/G9.png" alt="G9"/> <b>AIR ARABIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://webcheckin.airarabia.com/accelaero/en/index.html#/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/E5.png" alt="E5"/> <b>AIR ARABIA EGYPT</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airarabia.com/en/check-in-online-sita" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/UU.png" alt="UU"/> <b>AIR AUSTRAL</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.air-austral.com/en/prepare-your-flight/check-in-online.html" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/BT.png" alt="BT"/> <b>AIR BALTIC</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airbaltic.com/en-ZZ/index" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/BP.png" alt="BP"/> <b>Air Botswana</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airbotswana.co.bw/check-times" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/2J.png" alt="2J"/> <b>AIR BURKINA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.air-burkina.com/en/preparer-son-voyage/bagages/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AC.png" alt="AC"/> <b>AIR CANADA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.aircanada.com/ca/en/aco/home/ssci.html#/identification" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TX.png" alt="TX"/> <b>AIR CARAIBES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.aircaraibes.com/services/avant-le-vol/services-vip/speed-bag" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/CA.png" alt="CA"/> <b>AIR CHINA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airchina.us/US/GB/info/online-check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/HF.png" alt="HF"/> <b>AIR COTE D IVOIRE</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.aircotedivoire.com/en/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/EN.png" alt="EN"/> <b>AIR DOLOMITI</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airdolomiti.eu/online-check-in-instructions" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AF.png" alt="AF"/> <b>AIR FRANCE</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://wwws.airfrance.us/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/GL.png" alt="GL"/> <b>AIR GREENLAND</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airgreenland.com/travel-info/at-the-airport/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/NY.png" alt="NY"/> <b>AIR ICELAND Connect</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airicelandconnect.is/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AI.png" alt="AI"/> <b>AIR INDIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://ota.airindia.in/vandebharat/Default.aspx" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/IX.png" alt="IX"/> <b>AIR INDIA EXPRESS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airindiaexpress.in/en" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/IG.png" alt="IG"/> <b>AIR ITALY</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airitaly.com/en/your-flights/web-check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/MD.png" alt="MD"/> <b>AIR MADAGASCAR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airmadagascar.com/fr/mes-reservations" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/KM.png" alt="KM"/> <b>AIR MALTA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airmalta.com/information/check-in/online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/SW.png" alt="SW"/> <b>AIR NAMIBIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.airnamibia.com/check-in-online/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/NZ.png" alt="NZ"/> <b>AIR NEW ZEALAND</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airnewzealand.com/online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/PX.png" alt="PX"/> <b>AIR NIUGINI</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.airniugini.com.pg/mobile/check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/4N.png" alt="4N"/> <b>AIR NORTH</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flyairnorth.com/flights/checking" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/JU.png" alt="JU"/> <b>AIR SERBIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airserbia.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/HM.png" alt="HM"/> <b>AIR SEYCHELLES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airseychelles.com/en/before-you-fly/web-checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/4D.png" alt="4D"/> <b>AIR SINAI</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.egyptair.com/en/fly/check-in/Pages/web-check-in.aspx" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/VT.png" alt="VT"/> <b>AIR TAHITI</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airtahiti.com/online-checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TN.png" alt="TN"/> <b>AIR TAHITI NUI</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airtahitinui.com/us-en/web-check" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TC.png" alt="TC"/> <b>AIR TANZANIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airtanzania.co.tz/#web-checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/6C.png" alt="6C"/> <b>AIR TIMOR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://air-timor.tl/travel-planning/check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TS.png" alt="TS"/> <b>AIR TRANSAT</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airtransat.com/en-CA/online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/NF.png" alt="NF"/> <b>AIR VANUATU</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airvanuatu.com/plan-and-book/online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/UM.png" alt="UM"/> <b>AIR ZIMBABWE</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.airzimbabwe.aero/prepare-travel/check" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AS.png" alt="AS"/> <b>ALASKA AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://webselfservice.alaskaair.com/checkinweb/default.aspx?language=en_US" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AZ.png" alt="AZ"/> <b>ALITALIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.alitalia.com/en_us" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AA.png" alt="AA"/> <b>AMERICAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.aa.com/i18n/travel-info/baggage/checked-baggage-policy.jsp#cost" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/OZ.png" alt="OZ"/> <b>ASIANA AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://flyasiana.com/C/US/EN/contents/online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/RC.png" alt="RC"/> <b>ATLANTIC AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.atlanticairways.com/en" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/HZ.png" alt="HZ"/> <b>AURORA AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flyaurora.ru/jp/buy/registration-passengers/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/OS.png" alt="OS"/> <b>AUSTRIAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.austrian.com/us/en/online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/YK.png" alt="YK"/> <b>AVIA TRAFFIC COMPANY</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.aero.kg/vash-polyot/faq-bagazh" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AV.png" alt="AV"/> <b>AVIANCA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.avianca.com/ca/en/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/J2.png" alt="J2"/> <b>AZERBAIJAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.azal.az/en/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/PG.png" alt="PG"/> <b>BANGKOK AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.bangkokair.com/pages/view/online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/B2.png" alt="B2"/> <b>BELAVIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://en.belavia.by/registration/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/BZ.png" alt="BZ"/> <b>BLUE BIRD AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://booking.bluebirdair.com/TRAVEL-INFORMATION/Checked-baggage" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/BV.png" alt="BV"/> <b>BLUE PANORAMA AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.blue-panorama.com/main" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/OB.png" alt="OB"/> <b>BOLIVIANA DE AVIACION</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://flightcheckinonline.com/boa-boliviana-de-aviacion-online-check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/4B.png" alt="4B"/> <b>BOUTIQUE AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.boutiqueair.com/reservation_authorization/new" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/BA.png" alt="BA"/> <b>BRITISH AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.britishairways.com/travel/olcilandingpageauthreq/public/en_us?link=main_nav" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/SN.png" alt="SN"/> <b>BRUSSELS AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://checkin.brusselsairlines.com/CI/WebForms/PaxByQuery.aspx" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/K6.png" alt="K6"/> <b>CAMBODIA ANGKOR AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.cambodiaangkorair.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/9K.png" alt="9K"/> <b>CAPE AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.capeair.com/check-in/index.html" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/BW.png" alt="BW"/> <b>CARIBBEAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.caribbean-airlines.com/#/plan-your-trip/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/CX.png" alt="CX"/> <b>CATHAY PACIFIC</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.cathaypacific.com/cx/en_US.html#online-checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/KX.png" alt="KX"/> <b>CAYMAN AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://dx.checkin.caymanairways.com/dx/KXCI/#/check-in/start?locale=en-US" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/C2.png" alt="C2"/> <b>CEIBA INTERCONTINENTAL</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.aeropuertomadrid-barajas.com/airlines/CeibaIntercontinental.php" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/5Z.png" alt="5Z"/> <b>CEMAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.flycemair.co.za/index.php?checkin=true" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/CI.png" alt="CI"/> <b>CHINA AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://calec.china-airlines.com/echeckin_tn/eCheckin_home.aspx?country=us&amp;locale=en" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/MU.png" alt="MU"/> <b>CHINA EASTERN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://us.ceair.com/en/check-in.html" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/CZ.png" alt="CZ"/> <b>CHINA SOUTHERN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.csair.com/us/en/online_checkin/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/PN.png" alt="PN"/> <b>CHINA WEST AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.westair.cn/portal/en/pageDetail/254.html?menu=SalesNetwork" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/QG.png" alt="QG"/> <b>CITILINK</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://book.citilink.co.id/searchwebcheckin.aspx" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/CC.png" alt="CC"/> <b>CM AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.cmairlines.com/check-in-online/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/DE.png" alt="DE"/> <b>CONDOR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.condor.com/us/flight-preparation/check-in/online-check-in.jsp" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/CM.png" alt="CM"/> <b>COPA AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.copaair.com/en/web/us#/webcheckin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/XC.png" alt="XC"/> <b>CORENDON DUTCH AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://fly.corendon.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/SS.png" alt="SS"/> <b>CORSAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://upg.plusgrade.com/start/partner/bzQNYofRSS?lang=en&amp;?apiKey=L40fXLYsRgJuVYeTDeflotSA" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/OU.png" alt="OU"/> <b>CROATIA AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://wci.croatiaairlines.hr/ck.fly#ck_retrieve" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/CU.png" alt="CU"/> <b>CUBANA DE AVIACION</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.cubana.cu/information/Aeropuerto/1" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/OK.png" alt="OK"/> <b>CZECH AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.csa.cz/cz-en/check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/DX-R6.png" alt="DX-R6"/> <b>DANISH AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://dat.dk/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/DL.png" alt="DL"/> <b>DELTA AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.delta.com/apac/en" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/KB.png" alt="KB"/> <b>DRUK AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.drukair.com.bt/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/ZE.png" alt="ZE"/> <b>Eastar Jet</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.eastarjet.com/newstar/PGWIW00001" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/T3.png" alt="T3"/> <b>EASTERN AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.easternairways.com/online-booking-help" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/U2.png" alt="U2"/> <b>EASYJET</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.easyjet.com/en/help/booking-and-check-in/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/WK.png" alt="WK"/> <b>EDELWEISS AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://checkin.flyedelweiss.com/ck.fly#ck_retrieve" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/MS.png" alt="MS"/> <b>EGYPTAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.egyptair.com/en/fly/check-in/Pages/web-check-in.aspx" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/LY.png" alt="LY"/> <b>EL AL ISRAEL AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.elal.com/en/PassengersInfo/Check-In/Pages/Internet-Check-In.aspx" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/EL.png" alt="EL"/> <b>ELLINAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://en.ellinair.com/article/check_in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/EK.png" alt="EK"/> <b>EMIRATES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.emirates.com/english/manage-booking/online-check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/ET.png" alt="ET"/> <b>ETHIOPIAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.ethiopianairlines.com/aa/book/booking/web-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/EY.png" alt="EY"/> <b>ETIHAD AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.etihad.com/en-in/manage/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/EW.png" alt="EW"/> <b>EUROWINGS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.eurowings.com/en/my-trip/checkin.html?culture=en-GB" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/BR.png" alt="BR"/> <b>EVA AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://booking.evaair.com/flyeva/eva/b2c/manage-your-trip/online-checked-in-login.aspx?lang=en-global" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/FJ.png" alt="FJ"/> <b>FIJI AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.fijiairways.com/en-fj/bookings/online-check-in-service/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AY.png" alt="AY"/> <b>FINNAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.finnair.com/US/GB/information-services/Check-In/online-mobile-Check-In" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/F8.png" alt="F8"/> <b>FLAIR AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://reservation.flyflair.com/#/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/W2.png" alt="W2"/> <b>FLEXFLIGHT</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://flexflight.dk/online-check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/FZ.png" alt="FZ"/> <b>FLYDUBAI</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flydubai.com/en/flying-with-us/check-in/online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/XY.png" alt="XY"/> <b>FLYNAS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flynas.com/ar/help/online-checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/F9.png" alt="F9"/> <b>FRONTIER AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flyfrontier.com/travel/my-trips/check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/GA.png" alt="GA"/> <b>GARUDA INDONESIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.garuda-indonesia.com/id/en/garuda-indonesia-experience/on-ground/check-in/index" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/A9.png" alt="A9"/> <b>GEORGIAN AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.georgian-airways.com/en/information/conditions" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/GF.png" alt="GF"/> <b>GULF AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.gulfair.com/manage-my-booking/web-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/HU.png" alt="HU"/> <b>HAINAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.hainanairlines.com/HUPortal/dyn/portal/DisplayPage?COUNTRY_SITE=US&amp;SITE=CBHZCBHZ&amp;LANGUAGE=US&amp;PAGE=SSCI" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/HA.png" alt="HA"/> <b>HAWAIIAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.hawaiianairlines.com/manage/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/2L.png" alt="2L"/> <b>HELVETIC AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.helvetic.com/en/travelinfo#!info=checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/H9.png" alt="H9"/> <b>HIMALAYA AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.himalaya-airlines.com/travel/boarding-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/HX.png" alt="HX"/> <b>HONG KONG AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.hongkongairlines.com/en_HK/airport/hongkong/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/QX.png" alt="QX"/> <b>HORIZON AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.alaskaair.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/IB.png" alt="IB"/> <b>IBERIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.iberia.com/us/online-checkin/?language=en&amp;market=us#/ibcose" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/FI.png" alt="FI"/> <b>ICELAND AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.icelandair.com/support/pre-flight/check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/6E.png" alt="6E"/> <b>INDIGO</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.goindigo.in/travel-information/en/check-in-options.html" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/JY.png" alt="JY"/> <b>INTERCARIBBEAN AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.intercaribbean.com/travel-information/airport-check-in.html" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/4O.png" alt="4O"/> <b>INTERJET</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.interjet.com/en-us/manage-your-trip/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/JL.png" alt="JL"/> <b>JAPAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.jal.co.jp/en/inter/baggage/checked/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/J9.png" alt="J9"/> <b>JAZEERA AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.jazeeraairways.com/en-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/7C.png" alt="7C"/> <b>JEJU AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.jejuair.net/jejuair/en/serviceinfo/airport/web_checkin.do" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/JQ.png" alt="JQ"/> <b>JETSTAR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.jetstar.com/au/en/help/articles/can-i-check-in-online" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/3K.png" alt="3K"/> <b>JETSTAR ASIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.jetstar.com/us/en/help/articles/checking-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/LJ.png" alt="LJ"/> <b>JIN AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.jinair.com/booking/index" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/HO.png" alt="HO"/> <b>JUNEYAO AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://global.juneyaoair.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/RQ.png" alt="RQ"/> <b>KAM AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://book-kamair.crane.aero/ibe/checkin/search" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/M5.png" alt="M5"/> <b>KENMORE AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.kenmoreair.com/Before-you-fly/faq-s/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/KQ.png" alt="KQ"/> <b>Kenya Airways</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.kenya-airways.com/prepare-for-travel/manage-booking/online-check-in/en/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/KL.png" alt="KL"/> <b>KLM</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.klm.com/travel/us_en/prepare_for_travel/checkin_options/all_about/index.htm" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/WA.png" alt="WA"/> <b>KLM CITYHOPPER</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.klm.com/travel/us_en/prepare_for_travel/checkin_options/all_about/index.htm" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/KE.png" alt="KE"/> <b>KOREAN AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.koreanair.com/global/en/booking/check-in-gate.html" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/KU.png" alt="KU"/> <b>KUWAIT AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.kuwaitairways.com/en/is/check-in-times" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/LR.png" alt="LR"/> <b>LACSA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.avianca.com/otr/en/before-your-trip/prepare-your-baggage/checked/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TM.png" alt="TM"/> <b>LAM MOZAMBIQUE</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.lam.co.mz/en/Travel-Information/Your-Trip/Check-In-Online" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/QV.png" alt="QV"/> <b>LAO AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.laoairlines.com/?contentkey=pages&amp;id=38" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/4M.png" alt="4M"/> <b>LATAM AIRLINES ARGENTINA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.latam.com/en_us/travel-information/how-to-check-in-online/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/XL.png" alt="XL"/> <b>LATAM AIRLINES ECUADOR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.latam.com/en_us/travel-information/how-to-check-in-online/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/PZ.png" alt="PZ"/> <b>LATAM AIRLINES PARAGUAY</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.latam.com/en_us/travel-information/how-to-check-in-online/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/LM.png" alt="LM"/> <b>LOGANAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.loganair.co.uk/travel-help/check-in-times/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/LO.png" alt="LO"/> <b>LOT POLISH AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.lot.com/us/en/checked-baggage" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/8L.png" alt="8L"/> <b>Lucky Air</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.luckyair.net/login.html?done=%2Fflight%2Fcheckin.html" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/LH.png" alt="LH"/> <b>LUFTHANSA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.lufthansa.com/in/en/homepage" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/LG.png" alt="LG"/> <b>LUXAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.luxair.lu/en/checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/MH.png" alt="MH"/> <b>MALAYSIA AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.malaysiaairlines.com/my/en/plan-your-trip.html" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AE.png" alt="AE"/> <b>MANDARIN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.mandarin-airlines.com/Content/%E7%B6%B2%E4%B8%8A%E5%A0%B1%E5%88%B0-2016110004-6?AspxAutoDetectCookieSupport=1" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/JE.png" alt="JE"/> <b>MANGO</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flymango.com/en/checkin/identification" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/2M.png" alt="2M"/> <b>MAYA ISLAND AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.mayaislandair.com/belize-flights-baggage" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/ME.png" alt="ME"/> <b>MIDDLE EAST AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.mea.com.lb/english/Plan-and-Book/Online-Check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/8M.png" alt="8M"/> <b>Myanmar Airways International</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.maiair.com/at-the-airport/check-in-procedures" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/UB.png" alt="UB"/> <b>MYANMAR NATIONAL AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flymna.com/checkin/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/IN.png" alt="IN"/> <b>NAM AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://webcheckin.sriwijayaair.co.id/webcheckin/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/ON.png" alt="ON"/> <b>NAURU AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.nauruair.com/travel-info/before-you-fly" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/RA.png" alt="RA"/> <b>NEPAL AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.nepalairlines.com.np/home/page/baggage/checked-in-baggage-policy" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/MM.png" alt="MM"/> <b>PEACH AVIATION</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flypeach.com/en/lm/ai/airports/how_to_checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/PR.png" alt="PR"/> <b>PHILIPPINE AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.philippineairlines.com/en/faqs/onlinecheckin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/PU.png" alt="PU"/> <b>PLUS ULTRA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.aeropuertomadrid-barajas.com/eng/checkin-madrid-airport.htm" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/PU.png" alt="PU"/> <b>PLUS ULTRA LINEAS AEREAS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://plusultra.com/#checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/PD.png" alt="PD"/> <b>PORTER AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flyporter.com/en-ca/#check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/P0.png" alt="P0"/> <b>PROFLIGHT ZAMBIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://proflight-zambia.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/QF.png" alt="QF"/> <b>QANTAS AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.qantas.com/us/en/travel-info/baggage/checked-baggage.html" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/QR.png" alt="QR"/> <b>QATAR AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.qatarairways.com/en-us/services-checking-in.html" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/8N.png" alt="8N"/> <b>REGIONAL AIR SERVICES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://storage.aerocrs.com/74/system/Baggage%20policy.pdf" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/ZL.png" alt="ZL"/> <b>REGIONAL EXPRESS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.rex.com.au/Booking/WebCheckin.aspx" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/AT.png" alt="AT"/> <b>ROYAL AIR MAROC</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.royalairmaroc.com/us-en" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/7R.png" alt="7R"/> <b>RUSLINE</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.rusline.aero/en/services/registraciya-na-reis/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/WB.png" alt="WB"/> <b>RWANDAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.rwandair.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/FR.png" alt="FR"/> <b>RYANAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.ryanair.com/ie/en/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/FA.png" alt="FA"/> <b>SAFAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flysafair.co.za/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/SV.png" alt="SV"/> <b>SAUDI ARABIAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.saudia.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/SK.png" alt="SK"/> <b>SCANDINAVIAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flysas.com/us-en/checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TR.png" alt="TR"/> <b>SCOOT</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://makeabooking.flyscoot.com/checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TR.png" alt="TR"/> <b>SCOOT TIGERAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://makeabooking.flyscoot.com/checkin" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/BB.png" alt="BB"/> <b>SEABORNE AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.seaborneairlines.com/check-in-times/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/D2.png" alt="D2"/> <b>SEVERSTAL AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://severstal-avia.ru/en/vash-polyet/registratsiya-na-reys.php" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/SC.png" alt="SC"/> <b>SHANDONG AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.sda.cn//" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/FM.png" alt="FM"/> <b>SHANGHAI AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.ceair.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/MI.png" alt="MI"/> <b>SILKAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.singaporeair.com/en_UK/plan-and-book/your-booking/checkin/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/3M.png" alt="3M"/> <b>SILVER AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.silverairways.com/home" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/SQ.png" alt="SQ"/> <b>SINGAPORE AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.singaporeair.com/en_UK/plan-and-book/your-booking/checkin/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/H8.png" alt="H8"/> <b>SKY AIRLINE</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.skyairline.com/chile/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/ZA.png" alt="ZA"/> <b>SKY ANGKOR AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://skyangkorair.com/service.php?cat=3&amp;id=14" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/QS.png" alt="QS"/> <b>SMARTWINGS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.smartwings.com/en/online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/IE.png" alt="IE"/> <b>SOLOMON AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flysolomons.com/flight-information/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/SG.png" alt="SG"/> <b>SPICEJET</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://book.spicejet.com/SearchWebCheckin.aspx" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/NK.png" alt="NK"/> <b>SPIRIT AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.spirit.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/9C.png" alt="9C"/> <b>SPRING AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://en.ch.com/flights/excess-baggage" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/UL.png" alt="UL"/> <b>SRILANKAN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.srilankan.com/en_uk/coporate/online-checkin-info" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/2I.png" alt="2I"/> <b>STAR PERU</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.starperu.com/es" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/SY.png" alt="SY"/> <b>SUN COUNTRY</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://suncountry.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/WG.png" alt="WG"/> <b>SUNWING AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.sunwing.ca/pages/en/sunwing-airlines/flight-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/LX.png" alt="LX"/> <b>SWISS GLOBAL AIR LINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.swiss.com/us/en" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/LX.png" alt="LX"/> <b>SWISS INTERNATIONAL</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.swiss.com/us/EN/prepare/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/DT.png" alt="DT"/> <b>TAAG</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.taag.com/en/Plan/Check-in/Online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TP.png" alt="TP"/> <b>TAP PORTUGAL</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.flytap.com/en-us/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/RO.png" alt="RO"/> <b>TAROM</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.tarom.ro/en/flights-and-reservations/online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TG.png" alt="TG"/> <b>THAI AIRWAYS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.thaiairways.com/en/before_you_fly/check_in/i_checkin.page" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/VZ.png" alt="VZ"/> <b>THAI VIETJET AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.vietjetair.com/Sites/Web/en-US/NewsDetail/faq/2282/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/GS.png" alt="GS"/> <b>TIANJIN AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://global.tianjin-air.com/AU/GB/plan-and-book/manage-my-booking/online-check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/IT.png" alt="IT"/> <b>TIGERAIR TAIWAN</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.tigerairtw.com/en/welcome-on-board/baggage" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/PH.png" alt="PH"/> <b>TRANSAVIA DENMARK</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.transavia.com/en-EU/my-transavia/check-in/login/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TO.png" alt="TO"/> <b>TRANSAVIA FRANCE</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.transavia.com/en-EU/my-transavia/check-in/login/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/9N.png" alt="9N"/> <b>Tropic Air</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.tropicair.com/travel-info/baggage-policy/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TU.png" alt="TU"/> <b>TUNISAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="http://www.tunisair.com/site/publish/content/#" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/TK.png" alt="TK"/> <b>TURKISH AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.turkishairlines.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/UA.png" alt="UA"/> <b>UNITED AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.united.com/travel/checkin/start.aspx?LangCode=en-US" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/U6.png" alt="U6"/> <b>URAL AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://checkin.uralairlines.ru/en/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/V4.png" alt="V4"/> <b>VIEQUES AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.viequesairlink.com/baggage-policy/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/VN.png" alt="VN"/> <b>VIETNAM AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.vietnamairlines.com/vn/en/travel-information/check-in/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/VS.png" alt="VS"/> <b>VIRGIN ATLANTIC</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.virginatlantic.com/mytrips/findPnr.action" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/VA.png" alt="VA"/> <b>VIRGIN AUSTRALIA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.virginaustralia.com/eu/en/beta/?adobe_mc_sdid=SDID%3D7D4175A34A5EDDC7-3B67EE5365AE0668%7CMCORGID%3D66F360B351E56BF50A490D4D%40AdobeOrg%7CTS%3D1598014322&amp;adobe_mc_ref=https%3A%2F%2Fwww.virginaustralia.com%2Feu%2Fen%2F_cookiesAcceptance%2F#checkIn" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/Y4.png" alt="Y4"/> <b>VOLARIS</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://cms.volaris.com/en/travel-info/before-your-flight/easy-travel/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/V7.png" alt="V7"/> <b>VOLOTEA</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.volotea.com/en/your-booking/check-in-online/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/EB.png" alt="EB"/> <b>WAMOS AIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.wamosair.com/en/information/luggage/#checked-luggage" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/WS.png" alt="WS"/> <b>WESTJET</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.westjet.com/en-ca/my-trips/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/WR.png" alt="WR"/> <b>WESTJET ENCORE</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.westjet.com/en-ca/my-trips/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/WF.png" alt="WF"/> <b>WIDEROE</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.wideroe.no/en/home/check-in" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/YL.png" alt="YL"/> <b>YAMAL AIRLINES</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://book.yamal.aero/web-checkin/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		<div class="airline-layer">
		                      <div class="row">
		                    	<div class="col-xs-8"><img src="https://imgfolders.com/farehutz/B4.png" alt="B4"/> <b>ZANAIR</b> </div>  
		                      	<div class="col-xs-4 text-center"> 
								<a href="https://www.zanair.com/" onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}} class="link">View Details</a>
								</div>
		                      </div>
		                     </div>
                 		</div>
            
	</div>
              </div>	
          </div>
        </div>
      </div>
				
			
				
		      </div>
				
			</div> 
			<Footer />
			</>
  )
}
