/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import '../resources/css/thankyou-style.css';
import html2canvas from 'html2canvas';
import Logo from '../resources/images/logo-png--28.png';
import SITE_CONFIG from '../SiteController/SiteController';
import axios from 'axios';
import airlinesName from '../controller/Controller';

function formatDateAndTime(isoDate) {
    const dateOptions = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    };
  
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    const formattedDate = new Date(isoDate);
    const formattedDateString = new Intl.DateTimeFormat('en-US', dateOptions).format(formattedDate);
    const formattedTimeString = new Intl.DateTimeFormat('en-US', timeOptions).format(formattedDate);
  
    return { formattedDateString, formattedTimeString };
  }
  
  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hrs ${remainingMinutes} min`;
  }

export default function Acknowledge() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  const [bid, setBid] = useState('');
  const captureContainerRef = useRef(null);
  const [bookingId, setBookingId] = useState('');
  const [bookingResponse1, setBookingResponse1] = useState({ response: [] });
  const [bookingResponse2, setBookingResponse2] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [airportNames, setAirportNames] = useState({});
const [airportNamesFetched, setAirportNamesFetched] = useState(false);

useEffect(() => {
  async function fetchAirportName(airportCode) {
    try {
      const response = await axios.get(`https://www.tgsapi.com/flights/CrossAirportList?data=${airportCode}&authcode=farehuts`);
      const matchingAirport = response.data.find((item) => item.airportCode === airportCode);
      const name = matchingAirport ? matchingAirport.airportName : 'Unknown';
      return name;
    } catch (error) {
      return 'Unknown';
    }
  }

  if (bookingResponse1?.response?.[0]?.SectorDetail) {
    const sectorDetail = bookingResponse1.response[0].SectorDetail || [];
    

    const airportCodes = [...sectorDetail].reduce((codes, item) => {
      if (item.fromDestination) codes.push(item.fromDestination);
      if (item.toDestination) codes.push(item.toDestination);
      return codes;
    }, []);

    if (airportCodes.length > 0) {
      const airportNamePromises = airportCodes.map((code) => fetchAirportName(code));

      Promise.all(airportNamePromises).then((names) => {
        const updatedNames = {};
        airportCodes.forEach((code, index) => {
          updatedNames[code] = names[index];
        });
        setAirportNames(updatedNames);
        setAirportNamesFetched(true);
      });
    } else {
      setAirportNamesFetched(true); // If there are no airport codes, set the flag to true
    }
  } else {
    setAirportNamesFetched(true); // If SectorDetail is not available, set the flag to true
  }
}, [bookingResponse1?.response?.[0]?.SectorDetail]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = window.location.pathname;
        const bidFromPath = path.split('/').pop();

        if (bidFromPath) {
          setBid(bidFromPath);

          let decryptedTID = '';
          try {
            let fulValue = bidFromPath;
            const lengths = fulValue.length;
            fulValue = fulValue.substring(0, lengths - 14);
            fulValue = fulValue.substring(7);
            decryptedTID = fulValue;
            setBookingId(decryptedTID);
          } catch (error) {
            //console.error(error);
          }
        }
      } catch (error) {
        //console.error(error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only once after initial render

  useEffect(() => {
    if (bookingId.length > 0) {
      const fetchDataWithBookingId = async () => {
        try {
          //console.log(bookingId);
          const requestPayload = {
            bookingId: parseInt(bookingId)
          };
          //console.log('Request Payload:', JSON.stringify(requestPayload));

          const response = await axios.post(`${apiIP}:8585/api/viewbooking`, requestPayload);
          const response2 = await axios.get(`${apiIP}:8686/api/viewCharges/${bookingId}`);
          //console.log(response2);
          //console.log(response);

          setBookingResponse1(response.data);
          setBookingResponse2(response2.data);
          
          const totalAmount = response2.data.response.reduce((sum, item) => sum + parseFloat(item.amount), 0);
          setTotalAmount(totalAmount.toFixed(2));
        } catch (error) {
          //console.error(error);
        }
      };

      fetchDataWithBookingId();
    }
  }, [bookingId]);

  //console.log(bookingResponse1.response);

  // const handleSubmit = () => {
  //     //console.log("Submit")
  // if (captureContainerRef.current) {
  //      // Capture the content inside the specified DOM element
  //      html2canvas(captureContainerRef.current).then((canvas) => {
  //        // Convert the canvas to a base64-encoded image
  //        const imgData = canvas.toDataURL('image/png');
  //        //console.log(imgData)
  //        // Send the image to your API
  //        axios
  //          .post('YOUR_API_ENDPOINT', {
  //            image: imgData,
  //            // Other data you want to send with the image
  //          })
  //          .then((response) => {
  //            // Handle the API response here
  //            //console.log('Screenshot sent to API: ', response.data);
  //          })
  //          .catch((error) => {
  //            //console.error('Error sending screenshot to API: ', error);
  //          });
  //      });
  //    }
  //   }
  const handleSubmit = async () => {
    if (captureContainerRef.current) {
      // Capture the content inside the specified DOM element
      html2canvas(captureContainerRef.current).then((canvas) => {
        // Log the canvas to check if it is created successfully
        //console.log('Canvas:', canvas);

        // Convert the canvas to a base64-encoded image
        var imgData = canvas.toDataURL('image/png');
        imgData = imgData.replace('data:image/png;base64,', '');
        // Log the base64-encoded image data
        //console.log('Image Data:', imgData);

        // Send the image to your API using axios
        var formData = {
          "id":bookingId,
         "content":imgData,
         "s_type":"Acknowledged"
     };
     var json = JSON.stringify(formData)
     //console.log(json);
        axios
          .post(`${apiIP}:8888/api/screenshot`, json)
          .then((response) => {
            //console.log('Screenshot sent to API: ', response.data);
            const requestPayload = {
              bookingId: parseInt(bookingId)
            };
            
            axios.post(`${apiIP}:9090/api/acknowledgemail`, requestPayload);
            
          })
          .catch((error) => {
            //console.error('Error sending screenshot to API: ', error);
          });
      });
    }
    const responseIP = await axios.get("https://api.ipify.org?format=json");
            const userIP = responseIP.data.ip;
            const remarkObj = {
              bookingId: bookingId,
              remarks: `User IP: ${userIP} , Customer Clicked The Acknowledgement Link.`,
              agentId: 'Admin'
            };
            // Handle the API response here
            if(userIP){
            axios.post(`${apiIP}:8787/api/addnotes`, remarkObj)
            } else {
              //console.log("Error we do not have userIP")
            }
  };
  

  return (
    <div class="container" style={{paddingTop: '80px'}} ref={captureContainerRef} id="capture-container">
  <div class="row">
    <div class="col-md-12"> <br/>
      <div class="confirm-detail-container">
        <div class="row wrapperAk">
						
          <div class="col-md-12">
			  <div style={{textAlign: 'center'}}>
				  <img src={Logo} width="300" height="60" alt=""/>
			  </div>
			  
            <div class="comfirm-summry">
              <h4>Thank You, we have received your booking request.</h4>
               <p class="confirm-booked">Your Booking reference ID is mentioned below. In case of any further assistance please get in touch with us at our customer support number. Booking ID: <span>{/*{bookingResponse1.response[0].invoiceNum}*/}</span></p> 
              <p class="confirm-booked">Total price inclusive of all taxes and fees for the complete trip is USD <span>{totalAmount}</span></p>
                {bookingResponse2?.response && bookingResponse2?.response[1] ? (
                  <>
                    <p class="confirm-booked">Please note that your credit card statement may display a Multiple charges, first transaction of USD {bookingResponse2.response[0].amount} from {bookingResponse2.response[0].merchantName}, the second transaction of USD {bookingResponse2.response[1]?.amount} from {bookingResponse2.response[1]?.merchantName} which includes service fees and taxes. total charge stays the same as mentioned above in case of split transaction.</p>
                    {bookingResponse2?.response && bookingResponse2.response[2] ? (
                      <p class="confirm-booked">Please note that your credit card statement may display a Multiple charges, Third transaction of USD {bookingResponse2.response[2].amount} from {bookingResponse2.response[2].merchantName} which includes service fees and taxes. total charge stays the same as mentioned above in case of split transaction.</p>
                    ) : null}
                  </>
                ) : (
                  <p class="confirm-booked">Please note that your credit card statement may display a Single charges, first transaction of USD {bookingResponse2.response && bookingResponse2.response[0]?.amount} from {bookingResponse2.response && bookingResponse2.response[0]?.merchantName} which includes service fees and taxes. total charge stays the same as mentioned above in case of split transaction.</p>
                )}
                <div style={{ clear: 'both' }}></div>

            </div>
			  
			  
			  
            <div class="cnfr-marb15">
              <div class="confirm-title">
                <h5><img src="resources/images/icons/user-icon.png" alt="" /> Flight Details</h5>
              </div>
              {/* <!--next-section--> */}
              {/* <div class="confirm-boxsadow">
                <div class="confirm-detail-head">
                  <div class="confirm-head-depar">Departure</div>
                   <div class="confirm-head-Baggage"> {/*<strong>Baggage:</strong> 2pc | <strong>Cabin</strong> Economy 
                    {/* <p><strong>Brond Name:</strong> Basic Economy</p> 
                  </div>
                </div>
                {bookingResponse1?.response?.[0]?.SectorDetail?.map((segment, index) => (
  <div key={index} className="confirm-detail-row">
    <div className="confirm-mobl-respons">
      <div className="confirm-detail-airline">
        <img
          src={`https://imgfolders.com/farehutz/${segment.airline}.png`}
          alt={segment.airline}
        />
        <div className="confirm-airline-name-fno">
          <div className="confirm-airlineName">
            {airlinesName[segment.airline]}
          </div>
          <div className="confirm-flightno">
            Flight No. {segment.flightNo} | EQP-{segment.equipmentType}
          </div>
          {segment.airline !== segment.opratingAirline ? (
            <div className="confirm-flightno">
              Operated by {airlinesName[segment.opratingAirline]}
            </div>
          ) : null}
        </div>
      </div>
    </div>

    <div className="confirm-mobl-respons-next">
      <div className="confirm-detail-from-to">
        <div className="confirm-detail-from">
          <div className="confirm-from-city">
            <strong>{segment.fromDestination}</strong> - {airportNames[segment.fromDestination]}
          </div>
          <div className="confirm-from-time">
            <strong>
              {formatDateAndTime(segment.fromDateTime).formattedTimeString}
            </strong>{' '}
            {formatDateAndTime(segment.fromDateTime).formattedDateString}
          </div>
        </div>
        <div className="confirm-ddinfo-durastop">
          <div className="confirm-duration">
            {`${convertMinutesToHoursAndMinutes(segment.eft)}`}
          </div>
          <div className="confirm-Detailsstop-graph"></div>
        </div>
        <div className="confirm-detail-to">
          <div className="confirm-from-city">
            <strong>{segment.toDestination}</strong> - {airportNames[segment.toDestination]}
          </div>
          <div className="confirm-from-time">
            <strong>
              {formatDateAndTime(segment.toDateTime).formattedTimeString}
            </strong>{' '}
            {formatDateAndTime(segment.fromDateTime).formattedDateString !==
              formatDateAndTime(segment.toDateTime).formattedDateString ? (
                <sup>+1</sup>
              ) : null}{' '}
            {formatDateAndTime(segment.toDateTime).formattedDateString}
          </div>
          {formatDateAndTime(segment.fromDateTime).formattedDateString !==
            formatDateAndTime(segment.toDateTime).formattedDateString ? (
              <p className="cnfrm-arrivnext">Arrival next day</p>
            ) : null}
        </div>
      </div>
      {segment.layOverTime > 0 ? (
        <h2 className="confirm-layover-divider">
          <span>
            <i className="fa fa-clock"></i>(
            {convertMinutesToHoursAndMinutes(segment.layOverTime)}) At{' '}
            {airportNames[segment.toDestination]} ({segment.toDestination})
          </span>
        </h2>
      ) : null}
    </div>
  </div>
))}


                <div class="payment-detail-footer">
                  <div class="confirm-head-Baggage">
                    <p>Total Trip Duration: 12h 15m</p>
                  </div>
                </div>
              </div> */}
              <div className="confirm-boxsadow">
              <div className="confirm-boxsadow">
              <div className="confirm-detail-head">
          <div className="confirm-head-depar">Departure</div>
          <div className="confirm-head-Baggage">
            <strong>Cabin:</strong> {bookingResponse1.response[0]?.SectorDetail[0]?.cabinclass === 1 ? 'Economy' : bookingResponse1.response[0]?.SectorDetail[0].cabinclass === 2 ? 'Premium Economy' : bookingResponse1.response[0]?.SectorDetail[0].cabinclass === 3 ? 'Business' : bookingResponse1.response[0]?.SectorDetail[0].cabinclass === 4 ? 'First' : ''}
          </div>
        </div>
  {bookingResponse1?.response?.[0]?.SectorDetail?.map((segment, index, array) => (
    <React.Fragment key={index}>
      {/* Your existing JSX code for rendering the segment details */}
      <div key={index} className="confirm-detail-row">
        <div className="confirm-mobl-respons">
          <div className="confirm-detail-airline">
            <img
              src={`https://imgfolders.com/farehutz/${segment.airline}.png`}
              alt={segment.airline}
            />
            <div className="confirm-airline-name-fno">
              <div className="confirm-airlineName">
                {airlinesName[segment.airline]}
              </div>
              <div className="confirm-flightno">
                Flight No. {segment.flightNo} | EQP-{segment.equipmentType}
              </div>
              {segment.airline !== segment.opratingAirline ? (
                <div className="confirm-flightno">
                  Operated by {airlinesName[segment.opratingAirline]}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="confirm-mobl-respons-next">
          <div className="confirm-detail-from-to">
            <div className="confirm-detail-from">
              <div className="confirm-from-city">
                <strong>{segment.fromDestination}</strong> - {airportNames[segment.fromDestination]}
              </div>
              <div className="confirm-from-time">
                <strong>
                  {formatDateAndTime(segment.fromDateTime).formattedTimeString}
                </strong>{' '}
                {formatDateAndTime(segment.fromDateTime).formattedDateString}
              </div>
            </div>
            <div className="confirm-ddinfo-durastop">
              <div className="confirm-duration">
                {`${convertMinutesToHoursAndMinutes(segment.eft)}`}
              </div>
              <div className="confirm-Detailsstop-graph"></div>
            </div>
            <div className="confirm-detail-to">
              <div className="confirm-from-city">
                <strong>{segment.toDestination}</strong> - {airportNames[segment.toDestination]}
              </div>
              <div className="confirm-from-time">
                <strong>
                  {formatDateAndTime(segment.toDateTime).formattedTimeString}
                </strong>{' '}
                {formatDateAndTime(segment.fromDateTime).formattedDateString !==
                  formatDateAndTime(segment.toDateTime).formattedDateString ? (
                    <sup>+1</sup>
                  ) : null}{' '}
                {formatDateAndTime(segment.toDateTime).formattedDateString}
              </div>
              {formatDateAndTime(segment.fromDateTime).formattedDateString !==
                formatDateAndTime(segment.toDateTime).formattedDateString ? (
                  <p className="cnfrm-arrivnext">Arrival next day</p>
                ) : null}
            </div>
          </div>
          {/* Check for layover time and display layover line */}
      {index < array.length - 1 && segment.layOverTime > 0 && (
        <h2 className="confirm-layover-divider">
          <span>
            <i className="fa fa-clock"></i>(
            {convertMinutesToHoursAndMinutes(segment.layOverTime)}) At{' '}
            {airportNames[segment.toDestination]} ({segment.toDestination})
          </span>
        </h2>
      )}
        </div>
      </div>

      

      {/* Check for matching fromDestination and toDestination for departure line */}
      {index < array.length - 1 && segment.layOverTime === 0 && segment.toDestination === array[index + 1].fromDestination && (
        <div className="confirm-detail-head">
          <div className="confirm-head-depar">Return</div>
          <div className="confirm-head-Baggage">
            <strong>Cabin:</strong> {
  (() => {
    switch (segment.cabinclass) {
      case 1:
        return 'Economy';
      case 2:
        return 'Business';
      case 3:
        return 'Premium Economy';
      case 4:
        return 'First';
      default:
        return '';
    }
  })()
}
          </div>
        </div>
      )}
    </React.Fragment>
  ))}

  <div className="payment-detail-footer">
    <div className="confirm-head-Baggage">
      <p>Total Trip Duration: 12h 15m</p>
    </div>
  </div>
</div>





</div>

              {/* <!--next-section close-here--> 
              <!--next itnery--> */}
              
              {/* <!--next-section close-here-->  */}
              
            </div>
			  
			  
			  <style>
				
			  </style>
			  
			  
			  
            <div class="cnfr-marb15">
              <div class="confirm-title">
                <h5><img src="resources/images/icons/user-icon.png" alt=""/> Traveler Details</h5>
              </div>
              <div class="confirm-boxsadow">
                <table class="tblwidth">
                  <tr class="tblhead">
                    <td class="tblpadd">S.No</td>
                    <td class="tblpadd">Passenger(s) Name</td>
                    {/* <td class="tblpadd">Middle Name</td>
                    <td class="tblpadd">Last Name</td> */}
                    <td class="tblpadd">Gender</td>
                    <td class="tblpadd">Date of Birth</td>
                  </tr>
                  {bookingResponse1.response[0]?.PassengerDetails?.map((passenger, index) => {
  const dateOfBirth = new Date(passenger.paxDob);
  const formattedDateOfBirth = `${dateOfBirth.getDate()}-${dateOfBirth.getMonth() + 1}-${dateOfBirth.getFullYear()}`;

  return (
    <tr className="tblrow" key={index}>
      <td className="tblpadd">{index + 1}.</td>
      <td className="tblpadd">{passenger.title}. {passenger.paxFirstName}{" "}{passenger.paxMiddleName}{" "}{passenger.paxLastName}</td>
      <td className="tblpadd">{passenger.paxSex === 1 ? "Male" : "Female"}</td>
      <td className="tblpadd">{formattedDateOfBirth}</td>
    </tr>
  );
})}



                  
                </table>
                <div class="disclaimerbg">
                  <div class="row">
                    <div class="col-md-1 confirm-discseat">Disclaimer&nbsp;:</div>
                    <div class="col-md-11 confirm-seat-disc"> Any specific request you may have will be sent to the airline(s). Please contact your airline(s) directly, prior to your departure date, to confirm
                      special regrading Special requests.</div>
                  </div>
                </div>
              </div>
            </div>
			  
			 <div class="cnfr-marb15">
              <div class="confirm-title">
                <h5><img src="resources/images/icons/user-icon.png" alt=""/> Contact Details</h5>
              </div>
              <div class="confirm-boxsadow">
                <div>Mobile No :  {bookingResponse1.response[0]?.bookingDetail.mobileNo}</div>
				<div>Email ID :  {bookingResponse1.response[0]?.bookingDetail.emailId}</div>
                
              </div>
            </div> 
			  
            <div class="cnfr-marb15">
              <div class="pay-booked">
                    <h6 style={{marginBottom: '0px'}}> Total Price :
                      <div style={{float: 'right'}}>$<span>{bookingResponse1.response[0] && bookingResponse1.response[0].TransactionsDetails && bookingResponse1.response[0].TransactionsDetails.amount &&
  parseFloat(bookingResponse1.response[0].TransactionsDetails.amount).toFixed(2)}
</span></div>
                    </h6>
                  </div>
				
				<div class="confirm-boxsadow">
				
			  <div class="disclaimerbg">
                  <div class="row">
                    <div class="col-md-1 confirm-discseat">Disclaimer&nbsp;:</div>
                    <div class="col-md-11 confirm-seat-disc"> Any specific request you may have will be sent to the airline(s). Please contact your airline(s) directly, prior to your departure date, to confirm
                      special regrading Special requests.</div>
                  </div>
                </div>
				
				
					
				<div class="flight-price-selectAk">
			  <button type="button" onClick={handleSubmit}>I,Acknowledge</button>
			  </div>
				
			<h5>Booking Policies</h5>
                <div class="tmcaply">
                  <ul style={{marginBottom: '10px'}}>
                    <li>Above itinerary and agency reference number is for information only. Your credit card payment is under process.</li>
                    <li>You would receive your E-tickets and airlines confirmation in a separate email.</li>
                    <li> Your tickets would be issued once the payment is charged Fares are not guaranteed until the payment is charged.</li>
                    <li>Travodeals do not hold any responsibility for the fare or tax difference while the tickets are in issuance process.</li>
                    <li>Any fare or tax difference has to pay by the passenger before the tickets are issued</li>
                    <li>If there is a problem in processing the payment, you would be notified instantly. in case you do not receive an email or call within hours of making this reservation. Then please visit us or call us on the following number www.domain ( +1-123-123-1234)</li>
                    <li>We strongly recommend all travelers to confirm the status of their flight at least 72 hours before departure. </li>
                  </ul>
                </div>		
					
				</div>
              
            </div>
			  
			  
			  
            <div class="cnfr-marb15">
              <div class="confirm-title">
                <h5><img src="resources/images/icons/user-icon.png" alt=""/> Flight Booking Terms & Policies</h5>
              </div>
              <div class="confirm-boxsadow">
                <h5>Booking Policies</h5>
                <div class="tmcaply">
                  <ul style={{marginBottom: '10px'}}>
                    <li>Above itinerary and agency reference number is for information only. Your credit card payment is under process.</li>
                    <li>You would receive your E-tickets and airlines confirmation in a separate email.</li>
                    <li> Your tickets would be issued once the payment is charged Fares are not guaranteed until the payment is charged.</li>
                    <li>Travodeals do not hold any responsibility for the fare or tax difference while the tickets are in issuance process.</li>
                    <li>Any fare or tax difference has to pay by the passenger before the tickets are issued</li>
                    <li>If there is a problem in processing the payment, you would be notified instantly. in case you do not receive an email or call within hours of making this reservation. Then please visit us or call us on the following number www.domain ( +1-123-123-1234)</li>
                    <li>We strongly recommend all travelers to confirm the status of their flight at least 72 hours before departure. </li>
                  </ul>
                </div>
                <div class="disclaimerbg">
                  <h5 style={{textAlign: 'center', marginBottom: '5px'}}>For immediate help on your booking, please call us at +1-123-123-1234</h5>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
  )
}
