import PaymentForm from "./PaymentFormNMI";
import { CollectJSProvider, injectCollectJS } from "@lydiagoulding/react-nmi-collectjs";

const collectJS = injectCollectJS('https://secure.networkmerchants.com/token/Collect.js', 'checkout_public_4PdRaZbRW9Zq8aV3WKj4E68nBXj956x5');

function PaymentFormNMI() {
  return (
    <div className="App">
        <CollectJSProvider collectJSPromise={collectJS}>
            <PaymentForm/>
        </CollectJSProvider>
    </div>
  );
}

export default PaymentFormNMI;