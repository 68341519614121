/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import SITE_CONFIG from '../SiteController/SiteController';
import html2canvas from 'html2canvas';
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  browserName,
  isAndroid,
  isDesktop,
  isIOS,
  isMobile,
} from "react-device-detect";
import "./styles.css";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme css file
//import background from "../resources/images/search_banner/Homepage-Main-banner.png";
//import Loader from '../resources/loader/loader.gif'
import Loader from '../resources/loader/looader.gif'
import swap from "../resources/images/icons/swap.png";
import calendar from "../resources/images/icons/calendar.svg";
import user from "../resources/images/icons/user-black.svg";
//import transport from "../resources/images/transport.png";
import transport from "../resources/images/icons/flight-icon.png";
import LoaderBtn from '../resources/loader/wifi-search-css-spinner-unscreen.gif'
import { isNull } from "lodash";
import "../resources/js/blurEffect";
import "../resources/css/search_engine.css";
import '../resources/css/searchdown.css'

export default function CarSearchbar({ backgroundImage }) {
  // const bannerStyle = {
  //   backgroundImage: background,
  //   // Other styles for your banner
  // };
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  const captureContainerRef = useRef(null);
  const [APIData, setAPIData] = useState([]);
  const [filteredOriginResults, setFilteredOriginResults] = useState([]);
  const [filteredDestinationResults, setFilteredDestinationResults] = useState(
    []
  );
  const [originAirport, setOriginAirport] = useState("");
  const [destinationAirport, setDestinationAirport] = useState("");
  const [selectedOriginOption, setSelectedOriginOption] = useState(null);
  const [selectedDestinationOption, setSelectedDestinationOption] =
    useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isVisibledes, setIsVisibledes] = useState(true);
  const [adult, setAdult] = useState(1);
  const [child, setChild] = useState(0);
  const [infant, setInfant] = useState(0);
  const [infantWs, setInfantWs] = useState(0);
  const [error, setError] = useState("");
  const [travellerBlockVisible, setTravellerBlockVisible] = useState(false);
  const [searchOverlayVisible, setSearchOverlayVisible] = useState(false);
  const [activeField, setActiveField] = useState(false);
  const [totalTraveller, setTotalTraveller] = useState("Travellor(s)");
  const [responseData, setResponseData] = useState(null);
  const [selectTripType, setSelectTripType] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();
  const oneYearFromToday = new Date();
  oneYearFromToday.setFullYear(today.getFullYear() + 1); // Add one year to the current date
  const [selectedStartDate, setSelectedStartDate] = useState(today);
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [selectedOneDate, setSelectedOneDate] = useState(selectedStartDate);
  const [engineError, setEngineError] = useState(false)
  const [headingText, setHeadingText] = useState('');
  const [searchId, setSearchId] = useState('')
  const initialText = '';
  const typingInterval = 80;
  //const blinkInterval = 800;
  
  useEffect(() => {
    let currentIndex = 0;
    let isQuestionMarkVisible = false;

    const typingEffect = () => {
      if (currentIndex < initialText.length) {
        setHeadingText((prevText) => prevText + initialText.charAt(currentIndex++));
        setTimeout(typingEffect, typingInterval);
      } 
      // else {
      //   // Start blinking the question mark without modifying the existing text
      //   setInterval(() => {
      //     isQuestionMarkVisible = !isQuestionMarkVisible;
      //     setHeadingText((prevText) =>
      //       prevText.replace(/\?/g, '') + (isQuestionMarkVisible ? '?' : '')
      //     );
      //   }, blinkInterval);
      // }
    };

    typingEffect(); // Start the typing effect when the component mounts
  }, []);

  const [selectedBtn, setSelectedBtn] = useState(0);

  const handleCardBtnClick = (index) => {
    setSelectedBtn(index);
  };

  const showTravellerBlock = () => {
    setTravellerBlockVisible(true);
    setSearchOverlayVisible(true);
    setActiveField(true);
  };

  const hideTravellerBlock = () => {
    setTravellerBlockVisible(false);
    setSearchOverlayVisible(false);
    setActiveField(false);
    calculateTraveller();
  };

  // const handleMinusClick = (pax, event) => {
  //   event.stopPropagation();
  //   setFormValues((prevState) => ({
  //     ...prevState,
  //     adults: adult, // Assuming your formValues object has 'adults' field
  //     child: child, // Assuming your formValues object has 'children' field
  //     infants: infant, // Assuming your formValues object has 'infants' field
  //     infantsWs: infantWs, // Assuming your formValues object has 'infantsWs' field
  //     cabinType: prevState.cabinType, // Retain the cabinType value
  //   }));
  //   if (pax === "adult") {
  //     setAdult((prevAdult) => Math.max(prevAdult - 1, 1));
  //     if (infant > adult - 1) {
  //       setInfant((prevInfant) => Math.max(prevInfant - 1, 0));
  //     }
  //     if (infantWs > adult - 1) {
  //       setInfantWs((prevInfantWs) => Math.max(prevInfantWs - 1, 0));
  //     }
  //   } else if (pax === "child") {
  //     setChild((prevChild) => Math.max(prevChild - 1, 0));
  //   } else if (pax === "infant" && infant <= adult) {
  //     setInfant((prevInfant) => Math.max(prevInfant - 1, 0));
  //   } else if (pax === "infantWs") {
  //     setInfantWs((prevInfantWs) => Math.max(prevInfantWs - 1, 0));
  //   }
  //   setError("");
  //   calculateTraveller();
  // };

  

  // const handlePlusClick = (pax, event) => {
  //   event.stopPropagation();
  //   const totalCount = adult + child + infant + infantWs;
  //   if (totalCount < 9) {
  //     setFormValues((prevState) => ({
  //       ...prevState,
  //       adults: adult, // Assuming your formValues object has 'adults' field
  //       child: child, // Assuming your formValues object has 'children' field
  //       infants: infant, // Assuming your formValues object has 'infants' field
  //       infantsWs: infantWs, // Assuming your formValues object has 'infantsWs' field
  //       cabinType: prevState.cabinType, // Retain the cabinType value
  //     }));
  //     if (pax === "adult") {
  //       setAdult((prevAdult) => prevAdult + 1);
  //     } else if (pax === "child") {
  //       setChild((prevChild) => prevChild + 1);
  //     } else if (pax === "infant") {
  //       if (infant < adult - 0) {
  //         // Constraint: infant should be less than adult
  //         setInfant((prevInfant) => prevInfant + 1);
  //       } else {
  //         setError("Infant lap can't be greater than adult");
  //       }
  //     } else if (pax === "infantWs") {
  //       if (infantWs < adult - 0) {
  //         // Constraint: infantWs should be less than adult
  //         setInfantWs((prevInfantWs) => prevInfantWs + 1);
  //       } else {
  //         setError("Infant on seat can't be greater than adult");
  //       }
  //     }
  //   } else {
  //     setError("Total traveler can't be greater than 9");
  //   }
  //   calculateTraveller();
  // };

  const handleMinusClick = (pax, event) => {
    event.stopPropagation();
    if (pax === "adult") {
      setAdult((prevAdult) => Math.max(prevAdult - 1, 1));
    } else if (pax === "child") {
      setChild((prevChild) => Math.max(prevChild - 1, 0));
    } else if (pax === "infant" && infant <= adult) {
      setInfant((prevInfant) => Math.max(prevInfant - 1, 0));
    } else if (pax === "infantWs") {
      setInfantWs((prevInfantWs) => Math.max(prevInfantWs - 1, 0));
    }
  };

  const handlePlusClick = (pax, event) => {
    event.stopPropagation();
    const totalCount = adult + child + infant + infantWs;

    if (totalCount < 9) {
      if (pax === "adult") {
        setAdult((prevAdult) => prevAdult + 1);
      } else if (pax === "child") {
        setChild((prevChild) => prevChild + 1);
      } else if (pax === "infant") {
        if (infant < adult) {
          setInfant((prevInfant) => prevInfant + 1);
        } else {
          // Handle the error condition if infant count exceeds adult count
        }
      } else if (pax === "infantWs") {
        if (infantWs < adult) {
          setInfantWs((prevInfantWs) => prevInfantWs + 1);
        } else {
          // Handle the error condition if infantWs count exceeds adult count
        }
      }
    } else {
      // Handle the error condition if the total traveler count exceeds 9
    }
  };

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      adults: adult,
      child: child,
      infants: infant,
      infantsWs: infantWs,
    }));
  }, [adult, child, infant, infantWs]);


  const calculateTraveller = () => {
    const totalCount = adult + child + infant + infantWs;
    setTotalTraveller(totalCount || "Travellor(s)");
  };

  useEffect(() => {
    calculateTraveller();
  }, [adult, child, infant, infantWs]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const cachedData = localStorage.getItem("cachedData");
  //     if (cachedData) {
  //       const parsedData = JSON.parse(cachedData);
  //       setAPIData(parsedData);
  //       //setFilteredResults(parsedData);
  //     } else {
  //       const response = await axios.get(
  //         `http://54.70.41.205/FlightEngine/Service/AirportList?authcode=farehuts&data=${originAirport}`,
          
  //       );
  //       const fetchedData = response.data;
  //         //console.log(fetchData)
  //       localStorage.setItem("cachedData", JSON.stringify(fetchedData));

  //       setAPIData(fetchedData);
  //       //setFilteredResults(fetchedData);
  //     }
  //   };
  //   fetchData();
  // }, [originAirport]);

  useEffect(() => {
    const fetchData = async () => {
      const cachedData = localStorage.getItem("cachedData");
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        setAPIData(parsedData);
      } else {
        try {
          // const response = await axios.get(`http://54.70.41.205/flights/CrossAirportList?data=${originAirport}&authcode=farehuts`);
          const response = await axios.get(`https://www.tgsapi.com/flights/CrossAirportList?data=${originAirport}&authcode=farehuts`);
  
          const fetchedData = response.data;
          localStorage.setItem("cachedData", JSON.stringify(fetchedData));
          setAPIData(fetchedData);
          // //console.log(fetchedData)
        } catch (error) {
          // Handle the error, e.g., display an error message
          // //console.error(error);
        }
       }
    };
    fetchData();
  }, [originAirport]);

  // useEffect(() => {
  //   //if(formValues.segment[0].originAirport.length > 2){
  //     const fetchData = async () => {
  //       const cachedData = localStorage.getItem("cachedData");
  //       try {
  //         const response = await axios.get('http://localhost:9000/api/airportList', {
  //           params: { originAirport }
  //         });
  
  //         const fetchedData = response.data;
  //         localStorage.setItem("cachedData", JSON.stringify(fetchedData));
  //         setAPIData(fetchedData);
  //         //console.log(fetchedData);
  //       } catch (error) {
  //         // Handle the error, e.g., display an error message
  //         //console.error(error);
  //       }
  //     };
  //     fetchData();
  //   //}
  // }, [originAirport]);
  

  // const searchItems = (event) => {
  //   const searchValue = event.target.value;
  //   setOriginAirport(searchValue);

  //   if (searchValue.length > 2) {
  //     const filtered = APIData.filter(
  //       (item) => item.airportCode.toLowerCase() === searchValue.toLowerCase()
  //     );
  //     setFilteredOriginResults(filtered);
  //     // //console.log(filteredOriginResults);
  //   } else {
  //     setFilteredOriginResults([isNull]);
  //   }
  // };
  const searchItems = (event) => {
    const searchValue = event.target.value;
    setOriginAirport(searchValue);
  
    if (searchValue.length > 2) {
      const matchingAirportCode = APIData.find(
        (item) => item.airportCode.toLowerCase() === searchValue.toLowerCase()
      );
  
      const filteredByName = APIData.filter(
        (item) =>
          item.airportCode.toLowerCase() !== searchValue.toLowerCase() &&
          (item.airportName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.cityName.toLowerCase().includes(searchValue.toLowerCase()))
      ).slice(0, 9);
  
      const filteredResults = matchingAirportCode
        ? [matchingAirportCode, ...filteredByName]
        : filteredByName;
  
      setFilteredOriginResults(filteredResults);
    } else {
      setFilteredOriginResults([]);
    }
  };
  

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const cachedData = localStorage.getItem("cachedData");
  //     if (cachedData) {
  //       const parsedData = JSON.parse(cachedData);
  //       setAPIData(parsedData);
  //       //setFilteredResults(parsedData);
  //     } else {
  //       const response = await axios.get(
  //         `http://54.70.41.205/FlightEngine/Service/AirportList?authcode=farehuts&data=${destinationAirport}`
          
  //       );
  //       const fetchedData = response.data;

  //       localStorage.setItem("cachedData", JSON.stringify(fetchedData));

  //       setAPIData(fetchedData);
  //       //setFilteredResults(fetchedData);
  //     }
  //   };
  //   fetchData();
  // }, [destinationAirport]);

  useEffect(() => {
    //if(formValues.segment[0].originAirport.length > 2){
    const fetchData = async () => {
      const cachedData = localStorage.getItem("cachedData");
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        setAPIData(parsedData);
      } else {
        try {
          // const response = await axios.get(`http://54.70.41.205/flights/CrossAirportList?data=${destinationAirport}&authcode=farehuts`);
          const response = await axios.get(`https://www.tgsapi.com/flights/CrossAirportList?data=${destinationAirport}&authcode=farehuts`);
  
          const fetchedData = response.data;
          localStorage.setItem("cachedData", JSON.stringify(fetchedData));
          setAPIData(fetchedData);
        } catch (error) {
          // Handle the error, e.g., display an error message
          //console.error(error);
        }
       }
    };
    fetchData();
  //}
  }, [destinationAirport]);

  // const searchItemsDestination = (event) => {
  //   const searchValueDestination = event.target.value;
  //   setDestinationAirport(searchValueDestination);
  
  //   if (searchValueDestination.length > 2) {
  //     const filteredData = APIData.filter(
  //       (item) =>
  //         item.airportCode.toLowerCase() ===
  //         searchValueDestination.toLowerCase()
  //     );
  //     setFilteredDestinationResults(filteredData);
  //   } else {
  //     setFilteredDestinationResults([]);
  //   }
  // };
  
  // const searchItemsDestination = (event) => {
  //   event.persist(); // Add this line
  //   const searchValueDestination = event.target.value;
  //   setDestinationAirport(searchValueDestination);
  
  //   if (searchValueDestination.length > 2) {
  //     const filteredData = APIData.filter(
  //       (item) =>
  //         item.airportCode.toLowerCase().includes(searchValueDestination.toLowerCase()) ||
  //         item.airportName.toLowerCase().includes(searchValueDestination.toLowerCase()) ||
  //         item.cityName.toLowerCase().includes(searchValueDestination.toLowerCase())
  //     );
  
  //     setFilteredDestinationResults(filteredData);
  //     //console.log('here we have', filteredDestinationResults);
  //   } 
  //   //else {
  //   //  setFilteredDestinationResults([]);
  //   //}
  // };
  const searchItemsDestination = (event) => {
    event.persist();
    const searchValueDestination = event.target.value;
    setDestinationAirport(searchValueDestination);
  
    if (searchValueDestination.length > 2) {
      const matchingAirportCode = APIData.find(
        (item) =>
          item.airportCode.toLowerCase() === searchValueDestination.toLowerCase()
      );
  
      const filteredByName = APIData.filter(
        (item) =>
          item.airportCode.toLowerCase() !== searchValueDestination.toLowerCase() &&
          (item.airportName.toLowerCase().includes(searchValueDestination.toLowerCase()) ||
            item.cityName.toLowerCase().includes(searchValueDestination.toLowerCase()))
      ).slice(0, 9);
  
      const filteredResults = matchingAirportCode
        ? [matchingAirportCode, ...filteredByName]
        : filteredByName;
  
      setFilteredDestinationResults(filteredResults);
      //console.log('here we have', filteredResults);
    } else {
      setFilteredDestinationResults([]);
    }
  };
  

  const handleOnSelectOrigin = (item) => {
    setSelectedOriginOption(item);
    setOriginAirport(item.airportCode);
    setFormValues((prevState) => ({
      ...prevState,
      segment: [
        {
          ...prevState.segment[0],
          originAirport: item.airportCode.toUpperCase(),
        },
        ...prevState.segment.slice(1),
      ],
    }));
    // Handle selection logic here
  };

  const handleOnSelectDestination = (item) => {
    setSelectedDestinationOption(item);
    setDestinationAirport(item.airportCode);
    setFormValues((prevState) => ({
      ...prevState,
      segment: [
        {
          ...prevState.segment[0],
          destinationAirport: item.airportCode.toUpperCase(),
        },
        ...prevState.segment.slice(1),
      ],
    }));
    // Handle selection logic here
  };

  const handleClickOrigin = () => {
    setIsVisible(!isVisible);
  };

  const handleClickDestination = () => {
    setIsVisibledes(!isVisibledes);
  };

  const [formValues, setFormValues] = useState({
    searchID: searchId,
    client: 2,
    segment: [
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: formatDate(selectedStartDate),
        originCountry: "US",
        destinationCountry: "ALL",
      },
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: formatDate(selectedStartDate),
      },
    ],
    searchDirectFlight: false,
    flexibleSearch: false,
    tripType: "",
    adults: adult,
    child: child,
    infants: infant,
    infantsWs: infantWs,
    cabinType: "1",
    airline: "All",
    currencyCode: "USD",
    siteId: siteID,
    source: "Online",
    media: siteName,
    sID: "",
    rID: "",
    locale: "en",
    isNearBy: false,
    limit: 200,
    userIP: "",
    serverIP: "",
    device: "",
    browser: browserName,
    // ...(isRound ? {} : { dateOfReturn }),
  });
  //console.log(formValues)
  const navigate = useNavigate();

  //For Box Hide And Show
  const [tripType, setTripType] = useState(true);

  const [isActive, setIsActive] = useState(false);
  ////console.log(isActive);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const [calendarVisible, setCalendarVisible] = useState(false);

  const handleInputFocus = () => {
    setCalendarVisible(true);
  };

  
  

  const handleSelect = (ranges) => {
    setSelectedStartDate(ranges.selection.startDate);
    setSelectedEndDate(ranges.selection.endDate);
    setSelectedOneDate(ranges.selection.startDate);
  };

  

  const handleOneWaySelect = (range) => {
    setSelectedOneDate(range.selection.startDate);
  };

  const handleApply = () => {
    ////console.log("Selected Range:", selectedStartDate, selectedEndDate);
    // You can perform any actions here with the selected range
    setCalendarVisible(false);
  };

  const handleCancel = () => {
    setSelectedStartDate([
      {
        startDate: today,
        key: "selection",
      },
    ]);
    setSelectedEndDate([
      {
        endDate: today,
        key: "selection",
      },
    ]);
    setCalendarVisible(false);
    setSelectedOneDate(today);
  };

  const isDayDisabled = (date) => {
    return date < today;
  };

  const HandleSwapping = () => {
    setDestinationAirport(originAirport);
    setSelectedDestinationOption(selectedOriginOption);
    setOriginAirport(destinationAirport);
    setSelectedOriginOption(selectedDestinationOption);
  };

  // const handleSubmit = async (e) => {
  //   //console.log(navigator.userAgent);
  //   //console.log("hello");
  //   e.preventDefault();

  //   const deviceName = isMobile
  //     ? "Mobile Device"
  //     : isDesktop
  //     ? "Desktop"
  //     : isAndroid
  //     ? "Android"
  //     : isIOS;
  //   const response = await axios.get("https://api.ipify.org?format=json");
  //   const userIP = response.data.ip;
  //   const newSearchID = uuidv4().slice(0, 16);

  //   // Update formValues with the searchID from the URL
  //   const updatedFormValues = {
  //     ...formValues,
  //     searchID: newSearchID,
  //     userIP: userIP,
  //     device: deviceName,
  //   };

  //   // Convert formValues to JSON format
  //   const jsonData = JSON.stringify(updatedFormValues);
  //   //console.log(jsonData);
  //   //console.log(updatedFormValues);

  //   // Navigate to the result page with the searchID in the URL
  //   navigate(`/home?searchID=${newSearchID}`);

  //   searchFlights();
  // };

  // const searchFlights = async () => {
  //   try {
  //     const response = await axios.post(
  //       "http://localhost:8080/api/flight-result",
  //       formValues
  //     );

  //     if (response.status === 200) {
  //       setResponseData(response.data); // Update the state with the response data
  //       //console.log(response.data);
  //       navigate("/flight-list", { state: { data: response.data } });
  //     } else {
  //       //console.error("Request failed with status:", response.status);
  //     }
  //   } catch (error) {
  //     //console.error("Error making POST request:", error);
  //   }
  // };
  const handleSubmit = async (e) => {
    ////console.log("handleSubmit called");
      ////console.log(navigator.userAgent);
      ////console.log("hello");
      // if (captureContainerRef.current) {
      //   // Capture the content inside the specified DOM element
      //   html2canvas(captureContainerRef.current).then((canvas) => {
      //     // Convert the canvas to a base64-encoded image
      //     const imgData = canvas.toDataURL('image/png');
      //     ////console.log(imgData)
      //     // Send the image to your API
      //     axios
      //       .post('YOUR_API_ENDPOINT', {
      //         image: imgData,
      //         // Other data you want to send with the image
      //       })
      //       .then((response) => {
      //         // Handle the API response here
      //         ////console.log('Screenshot sent to API: ', response.data);
      //       })
      //       .catch((error) => {
      //         //console.error('Error sending screenshot to API: ', error);
      //       });
      //   });
      // }
//       document.body.style.overflowY = 'hidden';
//       const homeBody = document.querySelector('.HomeBody');
// if (homeBody) {
//   const elementsToBlur = homeBody.querySelectorAll(':not(.loader-overlay)');
//   elementsToBlur.forEach((element) => {
//     element.style.filter = 'blur(8px)';
//   });
// }
       
      e.preventDefault();
  
      const deviceName = isMobile
        ? "Mobile Device"
        : isDesktop
        ? "Desktop"
        : isAndroid
        ? "Android"
        : isIOS;
      const response = await axios.get("https://api.ipify.org?format=json");
      const userIP = response.data.ip;
      const newSearchID = uuidv4().slice(0, 16);
      setSearchId(newSearchID)
      
  
      // Convert formValues to JSON format
      
  
      // Navigate to the result page with the searchID in the URL
      //navigate(`/${newSearchID}`);
      
      let updatedSegment;
      if (selectTripType === 2) {
        updatedSegment = [
          {
            originAirport: formValues.segment[0].originAirport,
            destinationAirport: formValues.segment[0].destinationAirport,
            travelDate: formValues.segment[0].travelDate,
            originCountry: "US",
            destinationCountry: "ALL",
          },
          {
            originAirport: formValues.segment[0].destinationAirport,
            destinationAirport: formValues.segment[0].originAirport,
            travelDate: formValues.segment[1].travelDate || "",
          },
        ];
      } else {
        if(selectTripType === 1){
        updatedSegment = [
          {
            originAirport: formValues.segment[0].originAirport,
            destinationAirport: formValues.segment[0].destinationAirport,
            travelDate: formValues.segment[0].travelDate,
            originCountry: "US",
            destinationCountry: "ALL",
          },
          
        ];
      }}
      //console.warn(updatedSegment);
      const tripTypeAsString = selectTripType.toString();
      const updatedFormValues = {
        ...formValues,
        tripType: tripTypeAsString,
        segment: updatedSegment,
        searchID: newSearchID,
        userIP: userIP,
        device: deviceName,
      };
    
      //setFormValues(updatedFormValues);
  
      const jsonData = JSON.stringify(updatedFormValues);
      //console.log(jsonData);
      //console.log(updatedFormValues);
  
      //searchFlights();
      if(formValues.segment[0].originAirport !== formValues.segment[0].destinationAirport){
        setEngineError(false)
        setIsLoading(true)
      try {
        const response = await axios.post(
          `${apiIP}:7080/api/flight-result`,
          updatedFormValues
        );
  
        if (response.status === 200) {
          setResponseData(response.data); // Update the state with the response data
          //console.log(response.data);
          const mergedData = {
              responseData: response.data,
            formData: updatedFormValues,
          };
          navigate(`/flight-list?searchID=${newSearchID}`, { state: mergedData });
        } else {
          //console.error("Request failed with status:", response.status);
        }
      } catch (error) {
        //console.error("Error making POST request:", error);
      }
    } else {
      setEngineError(true)
      //alert("The Origin and destination must be different")
    }
    };

  const [calendarValue, setCalendarValue] = useState([
    formValues.segment[0]?.travelDate
      ? new Date(formValues.segment[0].travelDate)
      : null,
    formValues.segment[1]?.travelDate
      ? new Date(formValues.segment[1].travelDate)
      : null,
  ]);
  ////console.log(calendarValue);

  function formatDate(date) {
    const formattedDate = new Date(date);
    const month = formattedDate.getMonth() + 1;
    const day = formattedDate.getDate();
    const year = formattedDate.getFullYear();
    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;
  }

  const handleTripTypeChange = (event) => {
    setSelectTripType(Number(event.target.value));
    
  };
  useEffect(() => {
    ////console.log("Updated Segment:", formValues.segment);
  }, [formValues.segment]);

  const getCabinTypeLabel = (cabinTypeValue) => {
    switch (cabinTypeValue) {
      case "1":
        return "Economy";
      case "2":
        return "Premium Economy";
      case "3":
        return "Business";
      case "4":
        return "First";
      default:
        return "Unknown";
    }
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        // On smaller screens, display calendars vertically
        setDirection('vertical');
        setMonths(1);
      } else {
        // On larger screens, display calendars horizontally
        setDirection('horizontal');
        setMonths(2);
      }
    }

    // Initial setup
    handleResize();

    // Add event listener to update the layout when the screen size changes
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [direction, setDirection] = useState('horizontal');
  const [months, setMonths] = useState(2);

  const getAirportInfo = (originAirportCode, airportData) => {
    const matchingAirport = airportData.find(
      (airport) => airport.airportCode === originAirportCode
    );
  
    if (matchingAirport) {
      return `${matchingAirport.airportCode} - \n${matchingAirport.airportName}`;
    } else {
      return '';
    }
  };
  
  const destinationInputRef = useRef(null);

  useEffect(() => {
    if (selectedOriginOption) {
      // When selectedOriginOption changes, focus on the destination input element
      //destinationInputRef.current.focus();
    }
  }, [selectedOriginOption]);

  return (
    <>
    {/* <div ref={captureContainerRef} id="capture-container"> */}
    <div style={{display: 'none'}}>
      {responseData ? (
        <div>
          <h2>API Response:</h2>
          <pre>{JSON.stringify(responseData, null, 2)}</pre>
        </div>
      ) : (
        <div>
          <p>No response yet. Click the button to fetch data.</p>
          <button className="btn btn-primary" onClick={handleSubmit}>
            Click Me
          </button>
        </div>
      )}
      </div>
      {isLoading && (
        <div style={{width: '100%', position: 'relative'}}>        
        <div class="loader-overlay">
            <div style={{position: 'absolute', textAlign: 'center', color: 'aliceblue', top: '140px'}}>
                <p>{formValues.segment[0].originAirport}-{selectedOriginOption.airportName}  - {formValues.segment[0].destinationAirport}-{selectedDestinationOption.airportName}</p>
                <p>Departure Date: {formValues.segment[0].travelDate} - Return Date: {formValues.segment[1].travelDate}</p>
                <p>Passenger(s) {formValues.adults}Adult{formValues.child !==0 ? <span>/{formValues.child}Child</span> : null }{formValues.infants !==0 ? <span>/{formValues.infants}Infant</span> : null}{formValues.infantsWs !==0 ? <span>/{formValues.infantsWs}InfantWs</span> : null}</p>        
            </div>
            
            <img src={Loader} alt="Loader" />
        </div>
        </div>
      )}
      
      <form id="SearchflightRqust" onSubmit={handleSubmit}>
        <div
          className="bannerBox"
          style={{ backgroundImage: `url(${backgroundImage})` }}
          //style={{backgroundImage : `url(https://imgfolders.com/rawfares/rawfaresUS/home_banner/cover.jpg)`}}
        >
          <div className="searchcntr">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="bnrheading">
                    {/* <h2>
                      <h2 style={{display: 'inline-block',
      overflow: 'hidden',
      color:'black',
      fontSize: '22px',
      fonFamily: 'fangsong',
      fontWeight: '500',
      textDecoration: 'underline',
      // borderRight: '0.08em solid #000',
      whiteSpace: 'nowrap',
      letterSpacing: '0.15em',
      animation: 'typing 2s steps(30) infinite, blink-caret 0.7s step-end infinite'}}>{headingText}</h2>
                    </h2> */}
                  </div>
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <Link class="nav-link" to="/">Flight</Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/Hotel">Hotel</Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link active" aria-current="page" to="/Car">Cars</Link>
                    </li>
                  </ul>
                  <div className="block_search">
                    <h4>Book Flight Tickets</h4>

                    {/* <!--Radio start here --> */}
                    <div className="wrapper">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio1"
                          onChange={handleClick}
                          value="2"
                          onClick={(e) => {
                            setTripType(true);
                            handleTripTypeChange(e);
                          }}
                          autoComplete="off"
                          defaultChecked
                        />
                        <label
                          className={`btnRoundTrip ${selectedBtn === 0 ? "selected" : ""}`}
                          onClick={() => handleCardBtnClick(0)}
                          htmlFor="btnradio1"
                          // style={{
                          //   padding: "8px",
                          //   borderRadius: "3px",
                          //   border: "none",
                          //   margin: "5px",
                          //   color: 'white',
                          //   background: 'rgb(49 56 209)'
                          // }}
                        >
                          Round Trip
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio2"
                          value="1"
                          onClick={(e) => {
                            setTripType(false);
                            handleTripTypeChange(e);
                          }}
                          autoComplete="off"
                        />
                        <label
                          className={`btnRoundTrip ${selectedBtn === 1 ? "selected" : ""}`}
                          onClick={() => handleCardBtnClick(1)}
                          htmlFor="btnradio2"
                          // style={{
                          //   padding: "8px",
                          //   borderRadius: "3px",
                          //   border: "none",
                          //   margin: "5px",
                          // }}
                        >
                          One Way
                        </label>
                      </div>
                    </div>
                    {/* <!--Radio end here --> */}
                    <div className="EngineBox">
                      <div className="row">
                        <div className="col-lg-8 col-md-12">
                          <div className="row">
                            <div className="col-lg-7 col-md-12">
                              <div className="holder relative">
                                <div className="leavto input_block from_input dcol-50" onClick={handleApply}>
                                  <span className="icons bi bi-geo-alt-fill"></span>
                                  <div>
                                    <input
                                      id="txtleavingFrom"
                                      type="text"
                                      // value={
                                      //   selectedOriginOption
                                      //     ? selectedOriginOption.airportCode.toUpperCase()
                                      //     : formValues.segment[0].originAirport.toUpperCase()
                                      // }
                                      value={
                                        selectedOriginOption
                                          ? selectedOriginOption.airportCode.toUpperCase()
                                          : originAirport.toUpperCase()
                                      }
                                      onChange={(event) => {
                                        searchItems(event);
                                        setSelectedOriginOption(null);
                                        setFormValues((prevState) => ({
                                          ...prevState,
                                          segment: [
                                            {
                                              ...prevState.segment[0],
                                              originAirport: event.target.value.toUpperCase(),
                                            },
                                            ...prevState.segment.slice(1),
                                          ],
                                        }));
                                      }}

                                      onFocus={() => {
                                        setIsVisible(true)
                                        // Hide the <p> element when the input field is focused
                                        document.querySelector('.OriginAirport').style.display = 'none';
                                      }}
                                      onBlur={() => {
                                        // Show the <p> element when the input field loses focus
                                        document.querySelector('.OriginAirport').style.display = 'block';
                                      }}

                                      style={{ textTransform: "uppercase" }}
                                      required
                                    />

                                    <label
                                      htmlFor="input"
                                      placeholder="Leaving From"
                                    ></label>
                                    <p className="OriginAirport">
                                      {selectedOriginOption
                                        ? selectedOriginOption.airportName +
                                          (selectedOriginOption.countryName
                                            ? " , " +
                                              selectedOriginOption.countryName
                                            : "")
                                        : ""}
                                    </p>
                                  </div>
                                  <ul
                                  className="uldown"
                                    // style={{
                                    //   backgroundColor: "white",
                                    //   textAlign: "left",
                                    //   fontSize: "12px",
                                    //   width: "100%",
                                    //   // display: "flex",
                                    //   position: "absolute",
                                    //   zIndex: "99",
                                    //   paddingLeft: "0rem"
                                    // }}
                                    onClick={handleClickOrigin}
                                  >
                                    {originAirport.length > 2
                                      ? filteredOriginResults.map((item) => {
                                          return (
                                            <>
                                              {isVisible && (
                                                <li key={item.airportCode}
                                                  // style={{
                                                  //   display: "block",
                                                  //   padding: "5px",
                                                  //   border:
                                                  //     "0.5px dashed #FFA500",
                                                  //   width: "100%",
                                                  //   // textAlign: "centre",
                                                  //   backgroundColor:
                                                  //     selectedOriginOption ===
                                                  //     item
                                                  //       ? "lightblue"
                                                  //       : "white",
                                                  //       cursor: 'pointer',
                                                  //       height: '50px',
                                                  //       fontSize: '12px'
                                                  // }}
                                                  className="lidown"
                                                  onClick={() =>{
                                                    handleOnSelectOrigin(item);
                                                    handleClickOrigin()
                                                  }
                                                  }
                                                  //onClick={handleClickOrigin}
                                                >
                                                  <div style={{display: 'flex'}}>
                                                  <img
                                                    src={transport}
                                                    style={{height: '15px', marginRight: '8px'}}
                                                    alt="transport"
                                                    onClick={handleClickOrigin}
                                                  />
                                                  <div
                                                    style={{
                                                      padding: "0px",
                                                      height: "2px",
                                                      display: "table-caption",
                                                      margin: "0px",
                                                    }}
                                                    onClick={handleClickOrigin}
                                                  >
                                                    {item.airportCode}-{item.airportName}, {item.cityName}, {item.countryCode}
                                                  </div>{" "}
                                                  </div>
                                                  {/* <br /> */}
                                                   {" "}
                                                  
                                                 
                                                </li>
                                              )}
                                              {/* <li style={{ display: 'block',padding: "5px",border: '1px solid #222', textAlign: 'centre' }}></li> */}
                                            </>
                                          );
                                        })
                                      : null}
                                  </ul>
                                </div>

                                <div
                                  className="swap_icon"
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={swap}
                                    alt="swap"
                                    style={{ transition: "all 0.5s" }}
                                    onClick={HandleSwapping}
                                  />
                                </div>

                                <div className="leavto input_block to_input dcol-50" onClick={handleApply}>
                                  <span className="icons bi bi-geo-alt-fill"></span>
                                  <input
                                  ref={destinationInputRef}
                                    id="txtGoingTo"
                                    type="text"
                                    // value={
                                    //   selectedDestinationOption
                                    //     ? selectedDestinationOption.airportCode.toUpperCase()
                                    //     : formValues.segment[0]
                                    //         .destinationAirport.toUpperCase()
                                    // }
                                    value={
                                      selectedDestinationOption
                                        ? selectedDestinationOption.airportCode.toUpperCase()
                                        : destinationAirport.toUpperCase()
                                    }
                                    onChange={(event) => {
                                      searchItemsDestination(event);
                                      setSelectedDestinationOption(null);
                                      // setFormValues((prevState) => ({
                                      //   ...prevState,
                                      //   segment: [
                                      //     {
                                      //       ...prevState.segment[0],
                                      //       destinationAirport:
                                      //         event.target.value.toUpperCase(),
                                      //     },
                                      //     ...prevState.segment.slice(1),
                                      //   ],
                                      // }));
                                    }}

                                    onFocus={() => {
                                      setIsVisibledes(true)
                                      // Hide the <p> element when the input field is focused
                                      document.querySelector('.DestinationAirport').style.display = 'none';
                                    }}
                                    onBlur={() => {
                                      // Show the <p> element when the input field loses focus
                                      document.querySelector('.DestinationAirport').style.display = 'block';
                                    }}

                                    style={{ textTransform: "uppercase" }}
                                    required
                                  />
                                  <label
                                    htmlFor="input"
                                    placeholder="Going To"
                                  ></label>
                                  <p className="DestinationAirport">
                                    {selectedDestinationOption
                                      ? selectedDestinationOption.airportName +
                                        (selectedDestinationOption.countryName
                                          ? " , " +
                                            selectedDestinationOption.countryName
                                          : "")
                                      : ""}
                                  </p>
                                  <ul className="uldown"
                                    // style={{
                                    //   backgroundColor: "white",
                                    //   textAlign: "left",
                                    //   fontSize: "12px",
                                    //   width: "100%",
                                    //   // display: "flex",
                                    //   position: "absolute",
                                    //   zIndex: "99",
                                    //   paddingLeft: "0rem"
                                    // }}
                                    onClick={handleClickDestination}
                                  >
                                    {destinationAirport.length > 2
                                      ? filteredDestinationResults.map(
                                          (item) => {
                                            return (
                                              <>
                                                {isVisibledes && (
                                                  <li key={item.airportCode}
                                                    // style={{
                                                    //   display: "block",
                                                    // padding: "5px",
                                                    // border:
                                                    //   "0.5px dashed #FFA500",
                                                    // width: "100%",
                                                    // //textAlign: "centre",
                                                    //   backgroundColor:
                                                    //     selectedDestinationOption ===
                                                    //     item
                                                    //       ? "lightblue"
                                                    //       : "white",
                                                    //       cursor: 'pointer',
                                                    //     height: '50px',
                                                    //     fontSize: '12px'
                                                    // }}
                                                    className="lidown"
                                                    onClick={() =>
                                                      handleOnSelectDestination(
                                                        item
                                                      )
                                                    }
                                                  >
                                                    <div style={{display: 'flex'}}>
                                                   <img
                                                    src={transport}
                                                    style={{height: '15px', marginRight: '8px'}}
                                                    alt="transport"
                                                  />
                                                  <div
                                                    style={{
                                                      padding: "0px",
                                                      height: "2px",
                                                      display: "table-caption",
                                                      margin: "0px",
                                                    }}
                                                  >
                                                    {item.airportCode}-{item.airportName}, {item.cityName}, {item.countryCode}
                                                  </div>{" "}
                                                  </div>
                                                  {/* <br />  */}
                                                  
                                                  
                                                  </li>
                                                )}
                                                {/* <li style={{ display: 'block',padding: "5px", textAlign: 'centre' }}>name: {item.airportName}</li> */}
                                              </>
                                            );
                                          }
                                        )
                                      : null}
                                  </ul>
                                  <i
                                    className="inputClear flyingClear"
                                    style={{ display: "none" }}
                                  ></i>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-5 col-md-12">
                              {tripType ? (
                                <div className="dates">
                                  <div className="search_overlay"></div>

                                  <div
                                    className="leavto input_block from_input dcol-50"
                                    id="departDiv"
                                  >
                                    <span className="label_text">
                                      Departure
                                    </span>

                                    <input
                                      type="text"
                                      value={selectedStartDate.toLocaleDateString()}
                                      onFocus={handleInputFocus}
                                    />

                                    <span className="icons">
                                      <img src={calendar} alt="calendar" />
                                    </span>
                                    <div className="two-month-range-calendar-container" style={{zIndex: '5'}}>
                                      {/* <h2>Two-Month Range Calendar</h2> */}
                                      {calendarVisible && (
                                        <div>
                                          <div
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <DateRangePicker
                                              onChange={(selectedDates) => {
                                                handleSelect(selectedDates);

                                                // Update selectedStartDate and selectedEndDate directly
                                                setSelectedStartDate(
                                                  selectedDates.selection
                                                    .startDate
                                                );
                                                setSelectedEndDate(
                                                  selectedDates.selection
                                                    .endDate
                                                );

                                                setFormValues((prevState) => ({
                                                  ...prevState,
                                                  segment: [
                                                    {
                                                      ...prevState.segment[0],
                                                      travelDate: formatDate(
                                                        selectedDates.selection
                                                          .startDate
                                                      ),
                                                    },
                                                    {
                                                      ...prevState.segment[1],
                                                      travelDate: formatDate(
                                                        selectedDates.selection
                                                          .endDate
                                                      ),
                                                    },
                                                  ],
                                                }));

                                                setCalendarValue(selectedDates);
                                              }}
                                              ranges={[
                                                {
                                                  startDate: selectedStartDate,
                                                  endDate: selectedEndDate,
                                                  key: "selection",
                                                },
                                              ]}
                                              showSelectionPreview={true}
                                              moveRangeOnFirstSelection={false}
                                              months={months}
                                              showMonthAndYearPickers={true} // Show month and year picker buttons
                                              showDateDisplay={true} // Show the date display on the top
                                              direction={direction}
                                              minDate={today}
                                              maxDate={oneYearFromToday}
                                              isDayBlocked={isDayDisabled} // Disable past dates
                                              style={{
                                                width: "300px",
                                                height: "250px",
                                                borderColor: "gray",
                                                borderRadius: "3px",
                                                zIndex: '5',
                                              }}
                                            />
                                            <div
                                              className="button-container"
                                              style={{
                                                backgroundColor: "white",
                                                display: "flex",
                                                flexDirection: "row-reverse",
                                                alignItems: "center",
                                                margin: "5px",
                                              }}
                                            >
                                              <button style={{zIndex: '5', margin: '7px 0px'}} onClick={handleApply}>
                                                Apply
                                              </button>
                                              {/* <button style={{zIndex: '1', margin: '7px 0px'}} onClick={handleCancel}>
                                                Cancel
                                              </button> */}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  
                                    <div className="search_overlay"></div>

                                    <div
                                      className="leavto input_block from_input dcol-50"
                                      id="returnDiv"
                                      // style={{ width: "48%" }}
                                    >
                                      <span className="label_text">Return</span>
                                      <input
                                        type="text"
                                        value={selectedEndDate.toLocaleDateString()}
                                        onFocus={handleInputFocus}
                                      />
                                      <span className="icons">
                                        <img src={calendar} alt="calendar" />
                                      </span>
                                    </div>
                                  </div>
                                
                              ) : (
                                <div className="dates">
                                  <div className="search_overlay"></div>
                                  <div
                                    className="leavto input_block from_input dcol-50"
                                    id="departDiv"
                                    style={{ width: "100%" }}
                                  >
                                    <span className="label_text">
                                      Departure
                                    </span>
                                    <input
                                      type="text"
                                      value={selectedOneDate.toLocaleDateString()}
                                      onFocus={handleInputFocus}
                                    />
                                    <span className="icons">
                                      <img src={calendar} alt="calendar" />
                                    </span>
                                    {calendarVisible && (
                                      <div style={{ backgroundColor: "white" }}>
                                        <div
                                          className="range-calendar-container"
                                          style={{ position: "absolute" }}
                                        >
                                          {/* <h2>Range Calendar</h2> */}
                                          <DateRangePicker
                                            onChange={(e) => {
                                              handleOneWaySelect(e);
                                              setFormValues((prevState) => ({
                                                ...prevState,
                                                segment: [
                                                  {
                                                    ...prevState.segment[0],
                                                    travelDate: formatDate(
                                                      selectedOneDate.toLocaleDateString()
                                                    ),
                                                  },
                                                ],
                                              }));
                                            }}
                                            ranges={[
                                              {
                                                startDate: selectedOneDate,
                                                endDate: selectedOneDate,
                                                key: "selection",
                                              },
                                            ]}
                                            months={months}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            direction={direction}
                                            minDate={today}
                                            isDayBlocked={isDayDisabled} // Disable past dates
                                            style={{
                                              width: "300px",
                                              height: "250px",
                                            }}
                                          />
                                          <div
                                            className="button-container"
                                            style={{
                                              backgroundColor: "white",
                                              display: "flex",
                                              flexDirection: "row-reverse",
                                              alignItems: "center",
                                              margin: "0px",
                                            }}
                                          >
                                            <button style={{zIndex: '5'}} onClick={handleApply}>
                                              Apply
                                            </button>
                                            {/* <button style={{zIndex: '1'}} onClick={handleCancel}>
                                              Cancel
                                            </button> */}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12" onClick={showTravellerBlock}>
                          <div className="class_div relative" onClick={handleApply}>
                            <div
                              className="input_block traveller_click"
                              onClick={showTravellerBlock}
                            >
                              <span className="label_text">
                                Traveler(s) & Cabin{" "}
                                <i className="bi bi-caret-down-fill traveller_icon"></i>
                              </span>
                              <input
                                type="text"
                                value={
                                  totalTraveller === 0
                                    ? "Traveler(s)"
                                    : `${totalTraveller} Traveler(s), ${getCabinTypeLabel(
                                        formValues.cabinType
                                      )}`
                                }
                                className="inputField traveller cursor"
                                onClick={showTravellerBlock}
                                readOnly
                              />
                              <span className="icons person-icon">
                                <img src={user} alt="user" />
                              </span>
                            </div>

                            <div className="search_overlay"></div>
                            <div className="dateoverlay"></div>
                            {/* <!-- traveller design start--> */}
                            {travellerBlockVisible && (
                              <div
                                className="traveller_block"
                                // style={{ display: "none" }}
                              >
                                <div className="input_block d-block d-sm-none ">
                                  <a href="/" className="searchwindowclose">
                                    <i className="bi bi-x"></i>
                                  </a>
                                  <input
                                    type="text"
                                    value="Travelers Economy"
                                    className="inputField traveller cursor"
                                    readOnly
                                    style={{height: ''}}
                                  />
                                  <span className="icons person-icon bi bi-person "></span>
                                </div>
                                <div className="innerSet">
                                  <ul className="traveller_list">
                                    <li>
                                      <div className="traveller_title">
                                        Adults
                                      </div>
                                      <div className="actionbtn">
                                        <span
                                          className="addicons minus"
                                          onClick={(e) =>
                                            handleMinusClick("adult", e)
                                          }
                                          data-value="adult"
                                        >
                                          <i className="bi bi-dash"></i>
                                        </span>
                                        <input
                                          id="adult"
                                          name="adult"
                                          className="number"
                                          value={
                                            adult ? adult : formValues.adults
                                          }
                                          readOnly
                                          type="text"
                                        />
                                        <span
                                          className="addicons plus"
                                          onClick={(e) =>
                                            handlePlusClick("adult", e)
                                          }
                                          data-value="adult"
                                        >
                                          <i className="bi bi-plus-lg"></i>
                                        </span>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="traveller_title">
                                        Children{" "}
                                        <span className="small">
                                          (2-11 Yrs)
                                        </span>
                                      </div>
                                      <div className="actionbtn">
                                        <span
                                          className="addicons minus"
                                          onClick={(e) =>
                                            handleMinusClick("child", e)
                                          }
                                          data-value="child"
                                        >
                                          <i className="bi bi-dash"></i>
                                        </span>
                                        <input
                                          id="child"
                                          name="child"
                                          className="number"
                                          value={
                                            child ? child : formValues.child
                                          }
                                          readOnly
                                          type="text"
                                        />
                                        <span
                                          className="addicons plus"
                                          onClick={(e) =>
                                            handlePlusClick("child", e)
                                          }
                                          data-value="child"
                                        >
                                          <i className="bi bi-plus-lg"></i>
                                        </span>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="traveller_title">
                                        Infants{" "}
                                        <span className="small">(lap)</span>
                                      </div>
                                      <div className="actionbtn">
                                        <span
                                          className="addicons minus"
                                          onClick={(e) =>
                                            handleMinusClick("infant", e)
                                          }
                                          data-value="infant"
                                        >
                                          <i className="bi bi-dash"></i>
                                        </span>
                                        <input
                                          id="infant"
                                          name="infant"
                                          className="number"
                                          value={
                                            infant ? infant : formValues.infants
                                          }
                                          readOnly
                                          type="text"
                                        />
                                        <span
                                          className="addicons plus"
                                          onClick={(e) =>
                                            handlePlusClick("infant", e)
                                          }
                                          data-value="infant"
                                        >
                                          <i className="bi bi-plus-lg"></i>
                                        </span>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="traveller_title">
                                        Infants{" "}
                                        <span className="small">(On Seat)</span>
                                      </div>
                                      <div className="actionbtn">
                                        <span
                                          className="addicons minus"
                                          onClick={(e) =>
                                            handleMinusClick("infantWs", e)
                                          }
                                          data-value="infantWs"
                                        >
                                          <i className="bi bi-dash"></i>
                                        </span>
                                        <input
                                          id="infantWs"
                                          name="infantWs"
                                          className="number"
                                          value={
                                            infantWs
                                              ? infantWs
                                              : formValues.infantsWs
                                          }
                                          readOnly
                                          type="text"
                                        />
                                        <span
                                          className="addicons plus"
                                          onClick={(e) =>
                                            handlePlusClick("infantWs", e)
                                          }
                                          data-value="infantWs"
                                        >
                                          <i className="bi bi-plus-lg"></i>
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                  <div className="clearfix"></div>
                                  <div className="cabinClass_row">
                                    <label className="traveller_label">
                                      Cabin
                                    </label>
                                    <input
                                      id="cabin"
                                      name="cabin"
                                      type="hidden"
                                      value={formValues.cabinType}
                                      onChange={(event) =>
                                        setFormValues((prevState) => ({
                                          ...prevState,
                                          cabinType: event.target.value,
                                          ...prevState.segment.slice(0),
                                        }))
                                      }
                                    />
                                    <ul className="inputSet cabinClass">
                                      <li className={`cabin ${formValues.cabinType === "1" ? "selected" : ""}`} onClick={() => setFormValues((prevState) => ({ ...prevState, cabinType: "1" }))} style={{
                                            // border: "1px",
                                            // borderColor: "orange",
                                          }}>
                                        <label
                                          
                                        >
                                          <input
                                            type="radio"
                                            name="classtype"
                                            value="1"
                                            checked={
                                              formValues.cabinType === "1"
                                            }
                                            onChange={(event) =>
                                              setFormValues((prevState) => ({
                                                ...prevState,
                                                cabinType: event.target.value,
                                              }))
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span>Economy</span>
                                        </label>
                                      </li>
                                      <li className={`cabin ${formValues.cabinType === "2" ? "selected" : ""}`} onClick={() => setFormValues((prevState) => ({ ...prevState, cabinType: "2" }))}>
                                        <label>
                                          <input
                                            type="radio"
                                            name="classtype"
                                            value="2"
                                            checked={
                                              formValues.cabinType === "2"
                                            }
                                            onChange={(event) =>
                                              setFormValues((prevState) => ({
                                                ...prevState,
                                                cabinType: event.target.value,
                                              }))
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span>Premium Economy</span>
                                        </label>
                                      </li>
                                      <li className={`cabin ${formValues.cabinType === "3" ? "selected" : ""}`} onClick={() => setFormValues((prevState) => ({ ...prevState, cabinType: "3" }))}>
                                        <label>
                                          <input
                                            type="radio"
                                            name="classtype"
                                            value="3"
                                            checked={
                                              formValues.cabinType === "3"
                                            }
                                            onChange={(event) =>
                                              setFormValues((prevState) => ({
                                                ...prevState,
                                                cabinType: event.target.value,
                                              }))
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span>Business</span>
                                        </label>
                                      </li>
                                      <li className={`cabin ${formValues.cabinType === "4" ? "selected" : ""}`} onClick={() => setFormValues((prevState) => ({ ...prevState, cabinType: "4" }))}>
                                        <label>
                                          <input
                                            type="radio"
                                            name="classtype"
                                            value="4"
                                            checked={
                                              formValues.cabinType === "4"
                                            }
                                            onChange={(event) =>
                                              setFormValues((prevState) => ({
                                                ...prevState,
                                                cabinType: event.target.value,
                                              }))
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span>First</span>
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                  {error && (
                                    <div className="error">{error}</div>
                                  )}
                                  <div className="bottom_row">
                                    <button
                                      type="button"
                                      className="done_button"
                                      onClick={hideTravellerBlock}
                                    >
                                      Done
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* <!-- traveller design end here--> */}
                          </div>
                          {searchOverlayVisible && (
                            <div className="search_overlay"></div>
                          )}
                          {activeField && <div className="activefield"></div>}
                          {isLoading === false ? (
                          <button type="submit" className="searchButton">
                            Search
                          </button>
                          ) : <button type="submit" className="searchButton" style={{padding: '0px 0px'}}>
                          <img src={LoaderBtn} style={{width: '150px', height: '48px'}} alt="LoaderBtn" />
                        </button> }
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  {engineError === true ? (
                  <div style={{color: '#ffd900', fontWeight: '700'}}><p style={{background: '#f5f5e0', color: '#005dba', padding: '7px', border: '1px solid #f1f188'}}> Please enter a different Origin and Destination/City airport!</p></div>
                  ) : null}
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
     {/* </div> */}
    </>
  );
}
