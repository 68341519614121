/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import SITE_CONFIG from '../SiteController/SiteController';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { isNull } from "lodash";
import axios from "axios";
import {
    browserName,
    isAndroid,
    isDesktop,
    isIOS,
    isMobile,
  } from "react-device-detect";
  import { DateRangePicker } from "react-date-range";
  import transport from "../resources/images/transport.png";
  import swap from "../resources/images/icons/swap.png";
  import calandar from '../resources/images/icons/calendar.svg';
  import user from '../resources/images/icons/user-black.svg';
  import Loader from '../resources/loader/loader.gif';
  import LoaderBtn from '../resources/loader/wifi-search-css-spinner-unscreen.gif'

export default function ModifySearch({ searchId, originOption, destinationOption, departDate, returnDate, pax, cabin }) {
  //For Box Hide And Show
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  const [tripType, setTripType] = useState(true);
const [isActive, setIsActive] = useState(false);
const [selectTripType, setSelectTripType] = useState(2);
const [org, setOrg] = useState(originOption)
const [selectedOriginOption, setSelectedOriginOption] = useState(null);
const [initialSelectedOriginOption, setInitialSelectedOriginOption] = useState(originOption);
//console.log(selectedOriginOption)
const [destinationAirportModify, setDestinationAirportModify] = useState("");
const [responseData, setResponseData] = useState(null);
const [originAirportModify, setOriginAirportModify] = useState("");
const [APIData, setAPIData] = useState([]);
const[isSearchVisibleModify, setSearchVisibleModify] = useState(true);
const [filteredOriginResults, setFilteredOriginResults] = useState([]);
  const [filteredDestinationResults, setFilteredDestinationResults] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [isVisibledes, setIsVisibledes] = useState(true);
  const [selectedDestinationOption, setSelectedDestinationOption] = useState(null);
  const [initialSelectedDestinationOption, setInitialSelectedDestinationOption] = useState(destinationOption)
  const [selectedOneDate, setSelectedOneDate] = useState(new Date());
  const [calendarVisible, setCalendarVisible] = useState(false);
  const today = new Date();
  const oneWeekFromToday = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000); // Adding milliseconds for one week
  const oneYearFromToday = new Date();
  oneYearFromToday.setFullYear(today.getFullYear() + 1); // Add one year to the current date
  //const [selectedStartDate, setSelectedStartDate] = useState(today);
  const [selectedStartDate, setSelectedStartDate] = useState(
    departDate ? new Date(departDate) : today
);
  //const [selectedEndDate, setSelectedEndDate] = useState(oneWeekFromToday);
  const [selectedEndDate, setSelectedEndDate] = useState(returnDate ? new Date(returnDate) : oneWeekFromToday);
  const [adultModify, setAdultModify] = useState(1);
  const [childModify, setChildModify] = useState(0);
  const [infantModify, setInfantModify] = useState(0);
  const [infantWsModify, setInfantWsModify] = useState(0);
  const [errorModify, setErrorModify] = useState("");
  const [travellerBlockVisible, setTravellerBlockVisible] = useState(false);
  const [activeFieldModify, setActiveFieldModify] = useState(false);
  const [totalTravellerModify, setTotalTravellerModify] = useState(`${pax} Travellor(s)`);
  const [isLoading, setIsLoading] = useState(false);
  const [engineError, setEngineError] = useState(false);
  const [formValuesModify, setFormValuesModify] = useState({
    searchID: searchId,
    client: 2,
    segment: [
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: "",
        originCountry: "US",
        destinationCountry: "ALL",
      },
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: "",
      },
    ],
    searchDirectFlight: false,
    flexibleSearch: false,
    tripType: "",
    adults: "1",
    child: 0,
    infants: 0,
    infantsWs: 0,
    cabinType: cabin,
    airline: "All",
    currencyCode: "USD",
    siteId: siteID,
    source: "Online",
    media: siteName,
    sID: "",
    rID: "",
    locale: "en",
    isNearBy: false,
    limit: 200,
    userIP: "",
    serverIP: "",
    device: "",
    browser: browserName,
    // ...(isRound ? {} : { dateOfReturn }),
  });
  const [calendarValue, setCalendarValue] = useState([
    formValuesModify.segment[0]?.travelDate
      ? new Date(formValuesModify.segment[0].travelDate)
      : null,
    formValuesModify.segment[1]?.travelDate
      ? new Date(formValuesModify.segment[1].travelDate)
      : null,
  ]);
  ////console.log(calendarValue);
//   const toggleSearchModify = () => {
//     setSearchVisibleModify(false);
// }

  const calculateTraveller = useCallback(() => {
    const totalCount = adultModify + childModify + infantModify + infantWsModify;
    setTotalTravellerModify(totalCount || "Travellor(s)");
  }, [adultModify, childModify, infantModify, infantWsModify]);

  useEffect(() => {
    calculateTraveller();
  }, [adultModify, childModify, infantModify, infantWsModify, calculateTraveller]);

  useEffect(() => {
    const fetchDataModify = async () => {
      const cachedData = localStorage.getItem("cachedData");
      // if (cachedData) {
      //   const parsedData = JSON.parse(cachedData);
      //   setAPIData(parsedData);
      //   //setFilteredResults(parsedData);
      // } else {
        try {
          // const response = await axios.get(`http://54.70.41.205/flights/CrossAirportList?data=${originAirportModify}&authcode=farehuts`);
          const response = await axios.get(`https://www.tgsapi.com/flights/CrossAirportList?data=${originAirportModify}&authcode=farehuts`);
  
          const fetchedData = response.data;
          localStorage.setItem("cachedData", JSON.stringify(fetchedData));
          setAPIData(fetchedData);
        } catch (error) {
          // Handle the error, e.g., display an error message
          //console.error(error);
        // }
      }
    };
    fetchDataModify();
  }, [originAirportModify]);
  const searchItems = (event) => {
    const searchValue = event.target.value;
    setOriginAirportModify(searchValue);
  
    if (searchValue.length > 2) {
      const matchingAirportCode = APIData.find(
        (item) => item.airportCode.toLowerCase() === searchValue.toLowerCase()
      );
  
      const filteredByName = APIData.filter(
        (item) =>
          item.airportCode.toLowerCase() !== searchValue.toLowerCase() &&
          (item.airportName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.cityName.toLowerCase().includes(searchValue.toLowerCase()))
      ).slice(0, 9);
  
      const filteredResults = matchingAirportCode
        ? [matchingAirportCode, ...filteredByName]
        : filteredByName;
  
      setFilteredOriginResults(filteredResults);
    } else {
      setFilteredOriginResults([]);
    }
  };

  useEffect(() => {
    const fetchDataModify = async () => {
      const cachedData = localStorage.getItem("cachedData");
      // if (cachedData) {
      //   const parsedData = JSON.parse(cachedData);
      //   setAPIData(parsedData);
      //   //setFilteredResults(parsedData);
      // } else {
        try {
          // const response = await axios.get(`http://54.70.41.205/flights/CrossAirportList?data=${destinationAirportModify}&authcode=farehuts`);
          const response = await axios.get(`https://www.tgsapi.com/flights/CrossAirportList?data=${destinationAirportModify}&authcode=farehuts`);
  
          const fetchedData = response.data;
          localStorage.setItem("cachedData", JSON.stringify(fetchedData));
          setAPIData(fetchedData);
        } catch (error) {
          // Handle the error, e.g., display an error message
          //console.error(error);
        }
        //setFilteredResults(fetchedData);
      // }
    };
    fetchDataModify();
  }, [destinationAirportModify]);

  const searchItemsDestination = (event) => {
    event.persist();
    const searchValueDestination = event.target.value;
    setDestinationAirportModify(searchValueDestination);
  
    if (searchValueDestination.length > 2) {
      const matchingAirportCode = APIData.find(
        (item) =>
          item.airportCode.toLowerCase() === searchValueDestination.toLowerCase()
      );
  
      const filteredByName = APIData.filter(
        (item) =>
          item.airportCode.toLowerCase() !== searchValueDestination.toLowerCase() &&
          (item.airportName.toLowerCase().includes(searchValueDestination.toLowerCase()) ||
            item.cityName.toLowerCase().includes(searchValueDestination.toLowerCase()))
      ).slice(0, 9);
  
      const filteredResults = matchingAirportCode
        ? [matchingAirportCode, ...filteredByName]
        : filteredByName;
  
      setFilteredDestinationResults(filteredResults);
      //console.log('here we have', filteredResults);
    } else {
      setFilteredDestinationResults([]);
    }
  };

  


  
  const showTravellerBlock = () => {
    setTravellerBlockVisible(true);
    setActiveFieldModify(true);
    ////console.log(travellerBlockVisible);
  };

  const hideTravellerBlock = () => {
    setTravellerBlockVisible(false);
    setActiveFieldModify(false);
    calculateTraveller();
  };

  const handleMinusClick = (pax, event) => {
    event.stopPropagation();
    setFormValuesModify((prevState) => ({
      ...prevState,
      adults: adultModify, // Assuming your formValues object has 'adults' field
      children: childModify, // Assuming your formValues object has 'children' field
      infants: infantModify, // Assuming your formValues object has 'infants' field
      infantsWs: infantWsModify, // Assuming your formValues object has 'infantsWs' field
      cabinType: prevState.cabinType, // Retain the cabinType value
    }));
    if (pax === "adult") {
      setAdultModify((prevAdult) => Math.max(prevAdult - 1, 1));
      if (infantModify > adultModify - 1) {
        setInfantModify((prevInfant) => Math.max(prevInfant - 1, 0));
      }
      if (infantWsModify > adultModify - 1) {
        setInfantWsModify((prevInfantWs) => Math.max(prevInfantWs - 1, 0));
      }
    } else if (pax === "child") {
      setChildModify((prevChild) => Math.max(prevChild - 1, 0));
    } else if (pax === "infant" && infantModify <= adultModify) {
      setInfantModify((prevInfant) => Math.max(prevInfant - 1, 0));
    } else if (pax === "infantWs") {
      setInfantWsModify((prevInfantWs) => Math.max(prevInfantWs - 1, 0));
    }
    setErrorModify("");
    calculateTraveller();
  };

  // const handlePlusClick = (pax, event) => {
  //   event.stopPropagation();
  //   const totalCount = adultModify + childModify + infantModify + infantWsModify;
  //   if (totalCount < 9) {
  //     setFormValuesModify((prevState) => ({
  //       ...prevState,
  //       adults: adultModify, // Assuming your formValues object has 'adults' field
  //       children: childModify, // Assuming your formValues object has 'children' field
  //       infants: infantModify, // Assuming your formValues object has 'infants' field
  //       infantsWs: infantWsModify, // Assuming your formValues object has 'infantsWs' field
  //       cabinType: prevState.cabinType, // Retain the cabinType value
  //     }));
  //     if (pax === "adult") {
  //       setAdultModify((prevAdult) => prevAdult + 1);
  //     } else if (pax === "child") {
  //       setChildModify((prevChild) => prevChild + 1);
  //     } else if (pax === "infant") {
  //       if (infantModify < adultModify - 0) {
  //         // Constraint: infant should be less than adult
  //         setInfantModify((prevInfant) => prevInfant + 1);
  //       } else {
  //         setErrorModify("Infant lap can't be greater than adult");
  //       }
  //     } else if (pax === "infantWs") {
  //       if (infantWsModify < adultModify - 0) {
  //         // Constraint: infantWs should be less than adult
  //         setInfantWsModify((prevInfantWs) => prevInfantWs + 1);
  //       } else {
  //         setErrorModify("Infant on seat can't be greater than adult");
  //       }
  //     }
  //   } else {
  //     setErrorModify("Total traveler can't be greater than 9");
  //   }
  //   calculateTraveller();
  // };

  const handlePlusClick = (pax, event) => {
    event.stopPropagation();
    const totalCount = adultModify + childModify + infantModify + infantWsModify;

    if (totalCount < 9) {
      if (pax === "adult") {
        setAdultModify((prevAdult) => prevAdult + 1);
      } else if (pax === "child") {
        setChildModify((prevChild) => prevChild + 1);
      } else if (pax === "infant") {
        if (infantModify < adultModify) {
          setInfantModify((prevInfant) => prevInfant + 1);
        } else {
          // Handle the error condition if infant count exceeds adult count
        }
      } else if (pax === "infantWs") {
        if (infantWsModify < adultModify) {
          setInfantWsModify((prevInfantWs) => prevInfantWs + 1);
        } else {
          // Handle the error condition if infantWs count exceeds adult count
        }
      }
    } else {
      // Handle the error condition if the total traveler count exceeds 9
    }
  };

  useEffect(() => {
    setFormValuesModify((prevState) => ({
      ...prevState,
      adults: adultModify,
      child: childModify,
      infants: infantModify,
      infantsWs: infantWsModify,
    }));
  }, [adultModify, childModify, infantModify, infantWsModify]);

  
  const handleSelect = (ranges) => {
    setSelectedStartDate(ranges.selection.startDate);
    setSelectedEndDate(ranges.selection.endDate);
  };
  const handleApply = () => {
    ////console.log("Selected Range:", selectedStartDate, selectedEndDate);
    // You can perform any actions here with the selected range
    setCalendarVisible(false);
  };

  const handleCancel = () => {
    setSelectedStartDate([
      {
        startDate: today,
        key: "selection",
      },
    ]);
    setSelectedEndDate([
      {
        endDate: today,
        key: "selection",
      },
    ]);
    setCalendarVisible(false);
  };

  const isDayDisabled = (date) => {
    return date < today;
  };
const handleSubmit = async (e) => {
  //setIsLoading(true);
  ////console.log("handleSubmit called");
    ////console.log(navigator.userAgent);
    ////console.log("hello");
    e.preventDefault();
    //document.getElementById('FORMTO').style.display = 'none';
    const deviceName = isMobile
      ? "Mobile Device"
      : isDesktop
      ? "Desktop"
      : isAndroid
      ? "Android"
      : isIOS;
    const response = await axios.get("https://api.ipify.org?format=json");
    const userIP = response.data.ip;
    const newSearchID = uuidv4().slice(0, 16);

    

    // Convert formValues to JSON format
    

    // Navigate to the result page with the searchID in the URL
    //navigate(`/home?searchID=${newSearchID}`);
    
    let updatedSegment;
    if (selectTripType === 2) {
      updatedSegment = [
        {
          originAirport: formValuesModify.segment[0].originAirport,
          destinationAirport: formValuesModify.segment[0].destinationAirport,
          travelDate: formValuesModify.segment[0].travelDate,
          originCountry: "US",
          destinationCountry: "ALL",
        },
        {
          originAirport: formValuesModify.segment[0].destinationAirport,
          destinationAirport: formValuesModify.segment[0].originAirport,
          travelDate: formValuesModify.segment[1].travelDate || "",
        },
      ];
    } else {
      if(selectTripType === 1){
      updatedSegment = [
        {
          originAirport: formValuesModify.segment[0].originAirport,
          destinationAirport: formValuesModify.segment[0].destinationAirport,
          travelDate: formValuesModify.segment[0].travelDate,
          originCountry: "US",
          destinationCountry: "ALL",
        },
      ];
    }}
    //console.warn(updatedSegment);
    const tripTypeAsString = selectTripType.toString();
    const updatedFormValues = {
      ...formValuesModify,
      tripType: tripTypeAsString,
      segment: updatedSegment,
      //searchID: newSearchID,
      userIP: userIP,
      device: deviceName,
    };
  
    //setFormValues(updatedFormValues);

    const jsonData = JSON.stringify(updatedFormValues);
    //console.log(jsonData);
    //console.log(updatedFormValues);

    //searchFlights();
    if(formValuesModify.segment[0].originAirport !== formValuesModify.segment[0].destinationAirport){
      setEngineError(false)
      setIsLoading(true)
    try {
      const response = await axios.post(
        `${apiIP}:7080/api/flight-result`,
        updatedFormValues
      );

      if (response.status === 200) {
        setIsLoading(false);
        setResponseData(response.data); // Update the state with the response data
        ////console.log(response.data);
        const mergedData = {
            responseData: response.data,
            formData: updatedFormValues,
        };
        navigate(`/flight-list?searchID=${formValuesModify.searchID}`, { state: mergedData });
      } else {
        //console.error("Request failed with status:", response.status);
      }
    } catch (error) {
      //console.error("Error making POST request:", error);
    }
  } else {
    setEngineError(true)
  }
  };
  ////console.log(isActive);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };
  const handleTripTypeChange = (event) => {
    setSelectTripType(Number(event.target.value));
    
  };
  
  const handleClickOrigin = () => {
    setIsVisible(!isVisible);
  };

  const handleClickDestination = () => {
    setIsVisibledes(!isVisibledes);
  };
  const handleOnSelectOrigin = (item) => {
    setSelectedOriginOption(item);
    setOriginAirportModify(item.airportCode);
    setFormValuesModify((prevState) => ({
      ...prevState,
      segment: [
        {
          ...prevState.segment[0],
          originAirport: item.airportCode.toUpperCase(),
        },
        ...prevState.segment.slice(1),
      ],
    }));
    // Handle selection logic here
  };

  const handleOnSelectDestination = (item) => {
    setSelectedDestinationOption(item);
    setDestinationAirportModify(item.airportCode);
    setFormValuesModify((prevState) => ({
      ...prevState,
      segment: [
        {
          ...prevState.segment[0],
          destinationAirport: item.airportCode.toUpperCase(),
        },
        ...prevState.segment.slice(1),
      ],
    }));
    // Handle selection logic here
  };
  
  
  const handleOneWaySelect = (range) => {
    setSelectedOneDate(range.selection.startDate);
  };
  

  const handleInputFocus = () => {
    setCalendarVisible(true);
  };
  
  ////console.log(calendarValue);
  function formatDate(date) {
    const formattedDate = new Date(date);
    const month = formattedDate.getMonth() + 1;
    const day = formattedDate.getDate();
    const year = formattedDate.getFullYear();
    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;
  }
  const HandleSwapping = () => {
    setSelectedDestinationOption(selectedOriginOption);
    setSelectedOriginOption(selectedDestinationOption);
  };





const navigate = useNavigate();
const getCabinTypeLabel = (cabinTypeValue) => {
  switch (cabinTypeValue) {
    case "1":
      return "Economy";
    case "2":
      return "Business";
    case "3":
      return "Premium Economy";
    case "4":
      return "First";
    default:
      return "Unknown";
  }
};

useEffect(() => {
  function handleResize() {
    if (window.innerWidth <= 768) {
      // On smaller screens, display calendars vertically
      setDirection('vertical');
      setMonths(1);
    } else {
      // On larger screens, display calendars horizontally
      setDirection('horizontal');
      setMonths(2);
    }
  }

  // Initial setup
  handleResize();

  // Add event listener to update the layout when the screen size changes
  window.addEventListener('resize', handleResize);

  // Clean up the event listener on component unmount
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

const [direction, setDirection] = useState('horizontal');
const [months, setMonths] = useState(2);
//console.log(originOption)
  return (
    <>
    <div style={{display: 'none'}}>
    {responseData ? (
        <div>
          <h2>API Response:</h2>
          <pre>{JSON.stringify(responseData, null, 2)}</pre>
        </div>
      ) : (
        <div>
          <p>No response yet. Click the button to fetch data.</p>
          <button className="btn btn-primary" onClick={handleSubmit}>
            Click Me
          </button>
        </div>
      )}
      {isLoading && (
        <div style={{width: '100%', position: 'relative'}}>        
        <div class="loader-overlay">
            <div style={{position: 'absolute', textAlign: 'center', color: 'aliceblue', top: '140px'}}>
                <p>{formValuesModify.segment[0].originAirport}-{selectedOriginOption.airportName}  - {formValuesModify.segment[0].destinationAirport}-{selectedDestinationOption.airportName}</p>
                <p>Departure Date: {formValuesModify.segment[0].travelDate} - Return Date: {formValuesModify.segment[1].travelDate}</p>
                <p>Passenger(s) {formValuesModify.adults}Adult/{formValuesModify.child}Child/{formValuesModify.infants}Infant/{formValuesModify.infantsWs}InfantWs</p>        
            </div>
            
            <img src={Loader} alt="Loader" />
        </div>
        </div>
      )}
      </div>
    {isSearchVisibleModify && (
    
                                  <form id='FORMTO' onSubmit={handleSubmit}>
                                    <h4>Book Flight Tickets</h4>
									{/* <span class="close-search-lg" style={{cursor: 'pointer'}} onClick={toggleSearchModify}>X</span>
									<span class="close-search-sm" style={{cursor: 'pointer'}} onClick={toggleSearchModify}>X</span> */}
									{/* <input id="tripType" name="tripType" value="RT" type="hidden" /> */}
                                    {/* <!--Radio start here --> */}									 
                                    <div class="wrapper2">
                                    <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <input
  type="radio"
  className="btn-check"
  name="btnradio"
  id="btnradio1"
  onChange={(event) => {
    handleClick(event);
    handleTripTypeChange(event); // Pass the event object here
  }}
  value="2"
  checked={selectTripType === 2}
  onClick={(e) => {
    setTripType(true);
  }}
  autoComplete="off"
  defaultChecked
/>
                        <label
                          className="btn btn-outline-primary"
                          htmlFor="btnradio1"
                          style={{
                            padding: "8px",
                            borderRadius: "3px",
                            borderColor: "orangered",
                            margin: "5px",
                          }}
                        >
                          Round Trip
                        </label>

                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id="btnradio2"
                          value="1"
                          checked={selectTripType === 1}
                          onClick={(e) => {
                            setTripType(false);
                            
                          }}
                          onChange={handleTripTypeChange}
                          autoComplete="off"
                        />
                        <label
                          className="btn btn-outline-primary"
                          htmlFor="btnradio2"
                          style={{
                            padding: "8px",
                            borderRadius: "3px",
                            borderColor: "orangered",
                            margin: "5px",
                          }}
                        >
                          One Way
                        </label>
                      </div>
                                    </div>
                                    {/* <!--Radio end here --> */}
                                    <div class="EngineBox">
                                        <div class="row">
                                            <div class="col-lg-8 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-7 col-md-12">
                                                        <div class="holder relative2">
                                                            <div class="leavto2 input_block from_input dcol-50">
														<span class="icons bi bi-geo-alt-fill"></span>
                            {selectedOriginOption !== null || initialSelectedOriginOption === null ? (
                            
														<input id="txtleavingFrom" type="text"
                            //  value={
                            //             selectedOriginOption
                            //               ? selectedOriginOption.airportCode.toUpperCase()
                            //               : formValuesModify.segment[0].originAirport.toUpperCase()
                            //           }
                            value={
                              selectedOriginOption
                                ? selectedOriginOption.airportCode.toUpperCase()
                                : originAirportModify.toUpperCase()
                            }
                                      onChange={(event) => {
                                        searchItems(event);
                                        setSelectedOriginOption(null);
                                        // setFormValuesModify((prevState) => ({
                                        //   ...prevState,
                                        //   segment: [
                                        //     {
                                        //       ...prevState.segment[0],
                                        //       originAirport: event.target.value.toUpperCase(),
                                        //     },
                                        //     ...prevState.segment.slice(1),
                                        //   ],
                                        // }));
                                      }}
                                      onFocus={() => {
                                        setIsVisible(true)
                                        // Hide the <p> element when the input field is focused
                                        //document.querySelector('.OriginAirport').style.display = 'none';
                                      }}
                                      onBlur={() => {
                                        // Show the <p> element when the input field loses focus
                                        document.querySelector('.OriginAirport').style.display = 'block';
                                      }}
                                      //onClick={setIsVisible(true)}
                                      style={{ textTransform: "uppercase" }}
                                      required
                                       placeholder='Leaving From' />
                                        ) : (
                                        <input id="txtleavingFrom" type="text"
                                        value={
                                          initialSelectedOriginOption
                                          ? initialSelectedOriginOption
                                          : null
                                          }
                                          onChange={(event) => {
                                            searchItems(event);
                                            setInitialSelectedOriginOption(null);
                                            // setSelectedOriginOption(null);
                                          }}
                                      onFocus={() => {
                                        setIsVisible(true)
                                        // Hide the <p> element when the input field is focused
                                        //document.querySelector('.OriginAirport').style.display = 'none';
                                      }}
                                      onBlur={() => {
                                        // Show the <p> element when the input field loses focus
                                        document.querySelector('.OriginAirport').style.display = 'block';
                                      }}
                                      //onClick={setIsVisible(true)}
                                      style={{ textTransform: "uppercase" }}
                                      required
                                       //placeholder='Leaving From' 
                                       />
                                       )}
														<label for="input" placeholder="" style={{display: 'none'}}></label>
                                                        <p className="OriginAirport" style={{marginTop: '-38px'}}>
                                      {selectedOriginOption
                                        ? selectedOriginOption.airportName +
                                          (selectedOriginOption.countryName
                                            ? " , " +
                                              selectedOriginOption.countryName
                                            : "")
                                        : ""}
                                    </p>
                                    <ul
                                  className="hoverEffect"
                                    style={{
                                      backgroundColor: "white",
                                      textAlign: "left",
                                      fontSize: "12px",
                                      width: "100%",
                                      // display: "flex",
                                      position: "absolute",
                                      zIndex: "99",
                                      paddingLeft: "0rem"
                                    }}
                                    onClick={handleClickOrigin}
                                  >
                                    {originAirportModify.length > 2
                                      ? filteredOriginResults.map((item) => {
                                          return (
                                            <>
                                              {isVisible && (
                                                <li key={item.airportCode}
                                                  style={{
                                                    display: "block",
                                                    padding: "5px",
                                                    border:
                                                      "0.5px dashed #FFA500",
                                                    width: "100%",
                                                    // textAlign: "centre",
                                                    backgroundColor:
                                                      selectedOriginOption ===
                                                      item
                                                        ? "lightblue"
                                                        : "white",
                                                        cursor: 'pointer',
                                                        height: '50px',
                                                        fontSize: '12px'
                                                  }}
                                                  className="hoverEffect"
                                                  onClick={() =>{
                                                    handleOnSelectOrigin(item);
                                                    handleClickOrigin()}
                                                  }
                                                  //onClick={handleClickOrigin}
                                                >
                                                  <div style={{display: 'flex'}}>
                                                  <img
                                                    src={transport}
                                                    style={{height: '15px', marginRight: '8px'}}
                                                    alt=""
                                                    onClick={handleClickOrigin}
                                                  />
                                                  <center
                                                    style={{
                                                      padding: "0px",
                                                      height: "2px",
                                                      display: "table-caption",
                                                      margin: "0px",
                                                    }}
                                                    onClick={handleClickOrigin}
                                                  >
                                                    {item.airportCode}-{item.airportName}, {item.cityName}, {item.countryCode}
                                                  </center>{" "}
                                                  </div>
                                                  {/* <br /> */}
                                                   {" "}
                                                  
                                                 
                                                </li>
                                              )}
                                              {/* <li style={{ display: 'block',padding: "5px",border: '1px solid #222', textAlign: 'centre' }}></li> */}
                                            </>
                                          );
                                        })
                                      : null}
                                  </ul>
                                
                            
                                                            </div>
															
                                                            <div  class="swap_icon2"><img src={swap} alt="" style={{ transition: "all 0.5s", cursor:'pointer' }}
                                    onClick={HandleSwapping} /></div>
															
                                                            <div class="leavto2 input_block to_input dcol-50">		
														<span class="icons bi bi-geo-alt-fill"></span>
                            {selectedDestinationOption !== null || initialSelectedDestinationOption === null ? (
														<input id="txtGoingTo" type="text" 
                            // value={
                            //           selectedDestinationOption
                            //             ? selectedDestinationOption.airportCode.toUpperCase()
                            //             : formValuesModify.segment[0]
                            //                 .destinationAirport.toUpperCase()
                            //         }
                            value={
                              selectedDestinationOption
                                ? selectedDestinationOption.airportCode.toUpperCase()
                                : destinationAirportModify.toUpperCase()
                            }
                                    onChange={(event) => {
                                      searchItemsDestination(event);
                                      setSelectedDestinationOption(null);
                                      // setFormValuesModify((prevState) => ({
                                      //   ...prevState,
                                      //   segment: [
                                      //     {
                                      //       ...prevState.segment[0],
                                      //       destinationAirport:
                                      //         event.target.value.toUpperCase(),
                                      //     },
                                      //     ...prevState.segment.slice(1),
                                      //   ],
                                      // }));
                                    }} 
                                    onFocus={() => {
                                      setIsVisibledes(true)
                                      // Hide the <p> element when the input field is focused
                                      //document.querySelector('.DestinationAirport').style.display = 'none';
                                    }}
                                    onBlur={() => {
                                      // Show the <p> element when the input field loses focus
                                      document.querySelector('.DestinationAirport').style.display = 'block';
                                    }}
                                    //onClick={setIsVisibledes(true)}
                                    style={{ textTransform: "uppercase" }}
                                    required
                                    placeholder='Going To' />
                            ) : (
                              <input id="txtGoingTo" type="text" 
                            // value={
                            //           selectedDestinationOption
                            //             ? selectedDestinationOption.airportCode.toUpperCase()
                            //             : formValuesModify.segment[0]
                            //                 .destinationAirport.toUpperCase()
                            //         }
                            value={
                              initialSelectedDestinationOption
                                ? initialSelectedDestinationOption
                                : null
                            }
                                    onChange={(event) => {
                                      searchItemsDestination(event);
                                      setInitialSelectedDestinationOption(null);
                                      // setFormValuesModify((prevState) => ({
                                      //   ...prevState,
                                      //   segment: [
                                      //     {
                                      //       ...prevState.segment[0],
                                      //       destinationAirport:
                                      //         event.target.value.toUpperCase(),
                                      //     },
                                      //     ...prevState.segment.slice(1),
                                      //   ],
                                      // }));
                                    }} 
                                    onFocus={() => {
                                      setIsVisibledes(true)
                                      // Hide the <p> element when the input field is focused
                                      //document.querySelector('.DestinationAirport').style.display = 'none';
                                    }}
                                    onBlur={() => {
                                      // Show the <p> element when the input field loses focus
                                      document.querySelector('.DestinationAirport').style.display = 'block';
                                    }}
                                    //onClick={setIsVisibledes(true)}
                                    style={{ textTransform: "uppercase" }}
                                    required
                                    placeholder='Going To' />
                            )}
														<label for="input" placeholder="" style={{display: 'none'}}></label>
                                                        <p className="DestinationAirport" style={{marginTop: '-38px'}}>
                                    {selectedDestinationOption
                                      ? selectedDestinationOption.airportName +
                                        (selectedDestinationOption.countryName
                                          ? " , " +
                                            selectedDestinationOption.countryName
                                          : "")
                                      : ""}
                                  </p>
                                  <ul
                                    style={{
                                      backgroundColor: "white",
                                      textAlign: "left",
                                      fontSize: "12px",
                                      width: "100%",
                                      // display: "flex",
                                      position: "absolute",
                                      zIndex: "99",
                                      paddingLeft: "0rem"
                                    }}
                                    onClick={handleClickDestination}
                                  >
                                    {destinationAirportModify.length > 2
                                      ? filteredDestinationResults.map(
                                          (item) => {
                                            return (
                                              <>
                                                {isVisibledes && (
                                                  <li key={item.airportCode}
                                                    style={{
                                                      display: "block",
                                                    padding: "5px",
                                                    border:
                                                      "0.5px dashed #FFA500",
                                                    width: "100%",
                                                    //textAlign: "centre",
                                                      backgroundColor:
                                                        selectedDestinationOption ===
                                                        item
                                                          ? "lightblue"
                                                          : "white",
                                                          cursor: 'pointer',
                                                        height: '50px',
                                                        fontSize: '12px'
                                                    }}
                                                    onClick={() =>
                                                      handleOnSelectDestination(
                                                        item
                                                      )
                                                    }
                                                  >
                                                    <div style={{display: 'flex'}}>
                                                   <img
                                                    src={transport}
                                                    style={{height: '15px', marginRight: '8px'}}
                                                    alt=""
                                                  />
                                                  <center
                                                    style={{
                                                      padding: "0px",
                                                      height: "2px",
                                                      display: "table-caption",
                                                      margin: "0px",
                                                    }}
                                                  >
                                                    {item.airportCode}-{item.airportName}, {item.cityName}, {item.countryCode}
                                                  </center>{" "}
                                                  </div>
                                                  {/* <br />  */}
                                                  
                                                  
                                                  </li>
                                                )}
                                                {/* <li style={{ display: 'block',padding: "5px", textAlign: 'centre' }}>name: {item.airportName}</li> */}
                                              </>
                                            );
                                          }
                                        )
                                      : null}
                                  </ul>
														<i class="inputClear flyingClear" style={{display: 'none'}}></i>
                                                            </div>
															
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5 col-md-12">
                                                    {tripType ? (
                                <div className="dates">
                                  <div className="search_overlay"></div>

                                  <div
                                    className="leavto input_block from_input dcol-50"
                                    id="departDiv"
                                  >
                                    <span className="label_text">
                                      Departure
                                    </span>

                                    <input
                                      type="text"
                                      value={selectedStartDate.toLocaleDateString()}
                                      onFocus={handleInputFocus}
                                    />

                                    <span className="icons">
                                      <img src={calandar} alt="" />
                                    </span>
                                    <div className="two-month-range-calendar-container" style={{zIndex: '5'}}>
                                      {/* <h2>Two-Month Range Calendar</h2> */}
                                      {calendarVisible && (
                                        <div>
                                          <div
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <DateRangePicker
                                              onChange={(selectedDates) => {
                                                handleSelect(selectedDates);

                                                // Update selectedStartDate and selectedEndDate directly
                                                setSelectedStartDate(
                                                  selectedDates.selection
                                                    .startDate
                                                );
                                                setSelectedEndDate(
                                                  selectedDates.selection
                                                    .endDate
                                                );

                                                setFormValuesModify((prevState) => ({
                                                  ...prevState,
                                                  segment: [
                                                    {
                                                      ...prevState.segment[0],
                                                      travelDate: formatDate(
                                                        selectedDates.selection
                                                          .startDate
                                                      ),
                                                    },
                                                    {
                                                      ...prevState.segment[1],
                                                      travelDate: formatDate(
                                                        selectedDates.selection
                                                          .endDate
                                                      ),
                                                    },
                                                  ],
                                                }));

                                                setCalendarValue(selectedDates);
                                              }}
                                              ranges={[
                                                {
                                                  startDate: selectedStartDate,
                                                  endDate: selectedEndDate,
                                                  key: "selection",
                                                },
                                              ]}
                                              showSelectionPreview={true}
                                              moveRangeOnFirstSelection={false}
                                              months={months}
                                              showMonthAndYearPickers={true} // Show month and year picker buttons
                                              showDateDisplay={true} // Show the date display on the top
                                              direction={direction}
                                              minDate={today}
                                              maxDate={oneYearFromToday}
                                              isDayBlocked={isDayDisabled} // Disable past dates
                                              style={{
                                                width: "300px",
                                                height: "250px",
                                                borderColor: "gray",
                                                borderRadius: "3px",
                                              }}
                                            />
                                            <div
                                              className="button-container"
                                              style={{
                                                backgroundColor: "white",
                                                display: "flex",
                                                flexDirection: "row-reverse",
                                                alignItems: "center",
                                                margin: "5px",
                                              }}
                                            >
                                              <button style={{zIndex: '2'}} onClick={handleApply}>
                                                Apply
                                              </button>
                                              {/* <button onClick={handleCancel}>
                                                Cancel
                                              </button> */}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div>
                                    <div className="search_overlay"></div>

                                    <div
                                      className="leavto input_block from_input dcol-50"
                                      id="returnDiv"
                                      // style={{ width: "48%" }}
                                    >
                                      <span className="label_text">Return</span>
                                      <input
                                        type="text"
                                        value={selectedEndDate.toLocaleDateString()}
                                        onFocus={handleInputFocus}
                                      />
                                      <span className="icons">
                                        <img src={calandar} alt="" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="dates">
                                  <div className="search_overlay"></div>
                                  <div
                                    className="leavto input_block from_input dcol-50"
                                    id="departDiv"
                                    style={{ width: "100%" }}
                                  >
                                    <span className="label_text">
                                      Departure
                                    </span>
                                    <input
                                      type="text"
                                      value={selectedOneDate.toLocaleDateString()}
                                      onFocus={handleInputFocus}
                                    />
                                    <span className="icons">
                                      <img src={calandar} alt="" />
                                    </span>
                                    {calendarVisible && (
                                      <div style={{ backgroundColor: "white" }}>
                                        <div
                                          className="range-calendar-container"
                                          style={{ position: "absolute" }}
                                        >
                                          {/* <h2>Range Calendar</h2> */}
                                          <DateRangePicker
                                            onChange={(e) => {
                                              handleOneWaySelect(e);
                                              setFormValuesModify((prevState) => ({
                                                ...prevState,
                                                segment: [
                                                  {
                                                    ...prevState.segment[0],
                                                    travelDate: formatDate(
                                                      selectedOneDate.toLocaleDateString()
                                                    ),
                                                  },
                                                ],
                                              }));
                                            }}
                                            ranges={[
                                              {
                                                startDate: selectedOneDate,
                                                endDate: selectedOneDate,
                                                key: "selection",
                                              },
                                            ]}
                                            months={months}
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            direction={direction}
                                            minDate={today}
                                            maxDate={oneYearFromToday}
                                            isDayBlocked={isDayDisabled} // Disable past dates
                                            style={{
                                              width: "300px",
                                              height: "250px",
                                            }}
                                          />
                                          <div
                                            className="button-container"
                                            style={{
                                              backgroundColor: "white",
                                              display: "flex",
                                              flexDirection: "row-reverse",
                                              alignItems: "center",
                                              margin: "0px",
                                            }}
                                          >
                                            <button style={{zIndex: '2'}} onClick={handleApply}>
                                              Apply
                                            </button>
                                            {/* <button onClick={handleCancel}>
                                              Cancel
                                            </button> */}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-12">
                                            <div className="class_div relative">
                            <div
                              className="input_block traveller_click"
                              
                            >
                              <span className="label_text">
                                Traveler(s) & Cabin{" "}
                                <i className="bi bi-caret-down-fill traveller_icon"></i>
                              </span>
                              <input
                                type="text"
                                value={
                                  totalTravellerModify === 0
                                    ? "Traveler(s)"
                                    : `${totalTravellerModify} Traveler(s), ${getCabinTypeLabel(
                                        formValuesModify.cabinType
                                      )}`
                                }
                                onClick={showTravellerBlock}
                                className="inputField traveller cursor"
                                readOnly
                              />
                              <span className="icons person-icon">
                                <img src={user} alt="" />
                              </span>
                            </div>

                            
                            {/* <!-- traveller design start--> */}
                            {travellerBlockVisible && (
                              <div
                                className="traveller_block2"
                                
                              >
                                <div className="input_block d-block d-sm-none ">
                                  <a href="/" className="searchwindowclose">
                                    <i className="bi bi-x"></i>
                                  </a>
                                  <input
                                    type="text"
                                    value="Travelers Economy"
                                    className="inputField traveller cursor"
                                    readOnly
                                  />
                                  <span className="icons person-icon bi bi-person "></span>
                                </div>
                                <div className="innerSet">
                                  <ul className="traveller_list">
                                    <li>
                                      <div className="traveller_title">
                                        Adults
                                      </div>
                                      <div className="actionbtn">
                                        <span
                                          className="addicons minus"
                                          onClick={(e) =>
                                            handleMinusClick("adult", e)
                                          }
                                          data-value="adult"
                                        >
                                          <i className="bi bi-dash"></i>
                                        </span>
                                        <input
                                          id="adult"
                                          name="adult"
                                          className="number"
                                          value={
                                            adultModify ? adultModify : formValuesModify.adults
                                          }
                                          readOnly
                                          type="text"
                                        />
                                        <span
                                          className="addicons plus"
                                          onClick={(e) =>
                                            handlePlusClick("adult", e)
                                          }
                                          data-value="adult"
                                        >
                                          <i className="bi bi-plus-lg"></i>
                                        </span>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="traveller_title">
                                        Children{" "}
                                        <span className="small">
                                          (2-11 Yrs)
                                        </span>
                                      </div>
                                      <div className="actionbtn">
                                        <span
                                          className="addicons minus"
                                          onClick={(e) =>
                                            handleMinusClick("child", e)
                                          }
                                          data-value="child"
                                        >
                                          <i className="bi bi-dash"></i>
                                        </span>
                                        <input
                                          id="child"
                                          name="child"
                                          className="number"
                                          value={
                                            childModify ? childModify : formValuesModify.child
                                          }
                                          readOnly
                                          type="text"
                                        />
                                        <span
                                          className="addicons plus"
                                          onClick={(e) =>
                                            handlePlusClick("child", e)
                                          }
                                          data-value="child"
                                        >
                                          <i className="bi bi-plus-lg"></i>
                                        </span>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="traveller_title">
                                        Infants{" "}
                                        <span className="small">(lap)</span>
                                      </div>
                                      <div className="actionbtn">
                                        <span
                                          className="addicons minus"
                                          onClick={(e) =>
                                            handleMinusClick("infant", e)
                                          }
                                          data-value="infant"
                                        >
                                          <i className="bi bi-dash"></i>
                                        </span>
                                        <input
                                          id="infant"
                                          name="infant"
                                          className="number"
                                          value={
                                            infantModify ? infantModify : formValuesModify.infants
                                          }
                                          readOnly
                                          type="text"
                                        />
                                        <span
                                          className="addicons plus"
                                          onClick={(e) =>
                                            handlePlusClick("infant", e)
                                          }
                                          data-value="infant"
                                        >
                                          <i className="bi bi-plus-lg"></i>
                                        </span>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="traveller_title">
                                        Infants{" "}
                                        <span className="small">(On Seat)</span>
                                      </div>
                                      <div className="actionbtn">
                                        <span
                                          className="addicons minus"
                                          onClick={(e) =>
                                            handleMinusClick("infantWs", e)
                                          }
                                          data-value="infantWs"
                                        >
                                          <i className="bi bi-dash"></i>
                                        </span>
                                        <input
                                          id="infantWs"
                                          name="infantWs"
                                          className="number"
                                          value={
                                            infantWsModify
                                              ? infantWsModify
                                              : formValuesModify.infantsWs
                                          }
                                          readOnly
                                          type="text"
                                        />
                                        <span
                                          className="addicons plus"
                                          onClick={(e) =>
                                            handlePlusClick("infantWs", e)
                                          }
                                          data-value="infantWs"
                                        >
                                          <i className="bi bi-plus-lg"></i>
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                  <div className="clearfix"></div>
                                  <div className="cabinClass_row">
                                    <label className="traveller_label">
                                      Cabin
                                    </label>
                                    <input
                                      id="cabin"
                                      name="cabin"
                                      type="hidden"
                                      value={formValuesModify.cabinType}
                                      onChange={(event) =>
                                        setFormValuesModify((prevState) => ({
                                          ...prevState,
                                          cabinType: event.target.value,
                                          ...prevState.segment.slice(0),
                                        }))
                                      }
                                    />
                                    <ul className="inputSet cabinClass">
                                      <li className={`cabin ${formValuesModify.cabinType === "1" ? "selected" : ""}`} onClick={() => setFormValuesModify((prevState) => ({ ...prevState, cabinType: "1" }))} style={{
                                            // border: "1px",
                                            // borderColor: "orange",
                                          }}>
                                        <label
                                          
                                        >
                                          <input
                                            type="radio"
                                            name="classtype"
                                            value="1"
                                            checked={
                                              formValuesModify.cabinType === "1"
                                            }
                                            onChange={(event) =>
                                              setFormValuesModify((prevState) => ({
                                                ...prevState,
                                                cabinType: event.target.value,
                                              }))
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span>Economy</span>
                                        </label>
                                      </li>
                                      <li className={`cabin ${formValuesModify.cabinType === "2" ? "selected" : ""}`} onClick={() => setFormValuesModify((prevState) => ({ ...prevState, cabinType: "2" }))}>
                                        <label>
                                          <input
                                            type="radio"
                                            name="classtype"
                                            value="2"
                                            checked={
                                              formValuesModify.cabinType === "2"
                                            }
                                            onChange={(event) =>
                                              setFormValuesModify((prevState) => ({
                                                ...prevState,
                                                cabinType: event.target.value,
                                              }))
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span>Business</span>
                                        </label>
                                      </li>
                                      <li className={`cabin ${formValuesModify.cabinType === "3" ? "selected" : ""}`} onClick={() => setFormValuesModify((prevState) => ({ ...prevState, cabinType: "3" }))}>
                                        <label>
                                          <input
                                            type="radio"
                                            name="classtype"
                                            value="3"
                                            checked={
                                              formValuesModify.cabinType === "3"
                                            }
                                            onChange={(event) =>
                                              setFormValuesModify((prevState) => ({
                                                ...prevState,
                                                cabinType: event.target.value,
                                              }))
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span>Premium Economy</span>
                                        </label>
                                      </li>
                                      <li className={`cabin ${formValuesModify.cabinType === "4" ? "selected" : ""}`} onClick={() => setFormValuesModify((prevState) => ({ ...prevState, cabinType: "4" }))}>
                                        <label>
                                          <input
                                            type="radio"
                                            name="classtype"
                                            value="4"
                                            checked={
                                              formValuesModify.cabinType === "4"
                                            }
                                            onChange={(event) =>
                                              setFormValuesModify((prevState) => ({
                                                ...prevState,
                                                cabinType: event.target.value,
                                              }))
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                          />
                                          <span>First</span>
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                  {errorModify && (
                                    <div className="error">{errorModify}</div>
                                  )}
                                  <div className="bottom_row">
                                    <button
                                      type="button"
                                      className="done_button"
                                      onClick={hideTravellerBlock}
                                    >
                                      Done
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* <!-- traveller design end here--> */}
                          </div>
                          {isLoading === false ? (
                          <button type="submit" className="searchButton">
                            Search
                          </button>
                          ) : <button type="submit" className="searchButton" style={{padding: '0px 0px'}}>
                          <img src={LoaderBtn} style={{width: '150px', height: '48px'}} alt="" />
                        </button> }
                          {activeFieldModify && <div className="activefield"></div>}
                                               
                                            </div>
                                        </div>
                                        {engineError === true ? (
                  <div style={{color: '#ffd900', fontWeight: '700'}}><p style={{background: '#f5f5e0', color: '#005dba', padding: '7px', border: '1px solid #f1f188'}}> Please enter a different Origin and Destination/City airport!</p></div>
                  ) : null}
                                    </div>
                                    </form>
                                
    )}
    </>
  )
}
