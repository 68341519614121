import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Grid, Text, Button, Icon, Flex, List, ListItem } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import Searchbar from '../Shared/Searchbar';
import Footer from '../components/Footer/footer';
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import SITE_CONFIG from '../SiteController/SiteController';
import {
    browserName,
    isAndroid,
    isDesktop,
    isIOS,
    isMobile,
  } from "react-device-detect";
  import MetaTitle from '../pages/metaTitle';
  import Loader from '../resources/loader/loader.gif';
  import Logo from '../resources/images/logo-png--28.png';
  import Phone2 from '../resources/images/airline_gallary/phone-2.gif'
  import '../resources/css/ppc1.css';
  import CallBackto from '../resources/images/airline_gallary/callback-to.jpg';
  import Close from '../resources/images/airline_gallary/close-icon.png';
  import BritAirline from '../resources/images/airline_gallary/British_airlines.png';
  import Calling1 from '../resources/images/airline_gallary/callingGirl1.png'

// FlightCard component with onClick handler
const FlightCard = ({ origin, destination, startDate, endDate, price, onClick }) => (
  <Box
    p={6}
    borderRadius="lg"
    boxShadow="md"
    bg="gray.50"
    border="1px solid"
    borderColor="gray.200"
    position="relative"
    _hover={{ boxShadow: "lg", cursor: "pointer" }}
    onClick={() => onClick({ origin, destination, startDate, endDate, price })} // Pass values to parent
  >
    <Box textAlign="left">
    <Text fontSize="3xl" fontWeight="bold" color="gray.700">
        {origin}
      </Text>
      <Text fontSize="md" color="gray.400" mt={1}>
        {startDate}
      </Text>

      
      <Flex direction="column" alignItems="flex-start" mr={2}>
          <ArrowBackIcon />
          <ArrowForwardIcon />
        </Flex>
      
        <Text fontSize="3xl" fontWeight="bold" color="gray.500">
          {destination}
        </Text>
        
        <Text fontSize="md" color="gray.400">
          {endDate}
        </Text>
    </Box>

    <Box
      position="absolute"
      top="0"
      right="0"
      bg="black"
      color="white"
      p={4}
      borderTopRightRadius="lg"
      borderBottomLeftRadius="lg"
      textAlign="right"
    >
      <Text fontSize="lg" fontWeight="bold">
        {price}*
      </Text>
      <Button colorScheme="red" size="sm" mt={2}>
        Book Now
      </Button>
    </Box>
  </Box>
);

const Latam = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const airlineName = searchParams.get('aname');
  const { apiIP, siteName, siteID, disclaimer } = SITE_CONFIG;
  const navigate = useNavigate();
  const [searchId, setSearchId] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [popD, setPopD] = useState(true)
const [formValues, setFormValues] = useState({
  searchID: searchId,
  client: 2,
  segment: [
    {
      originAirport: "",
      destinationAirport: "",
      travelDate: "",
      originCountry: "US",
      destinationCountry: "ALL",
    },
    {
      originAirport: "",
      destinationAirport: "",
      travelDate: "",
    },
  ],
  searchDirectFlight: false,
  flexibleSearch: false,
  tripType: "",
  adults: 1,
  child: 0,
  infants: 0,
  infantsWs: 0,
  cabinType: "1",
  airline: "LA",
  currencyCode: "USD",
  siteId: siteID,
  source: "Online",
  media: siteName,
  sID: "",
  rID: "",
  locale: "en",
  isNearBy: false,
  limit: 200,
  userIP: "",
  serverIP: "",
  device: "",
  browser: browserName,
});



const handleCardClick = (data) => {
  // Set formValues based on the clicked card's data
  const updatedValues = {
    ...formValues,
    segment: [
      {
        ...formValues.segment[0],
        originAirport: data.origin, 
        destinationAirport: data.destination,
        travelDate: formatDate(data.startDate),
      },
      {
        ...formValues.segment[1],
        originAirport: data.destination,
        destinationAirport: data.origin,
        travelDate: formatDate(data.endDate),
      },
    ],
    tripType: "2",
  };

  // Update the formValues state
  setFormValues(updatedValues);

  // Call API with the updated formValues after state update
  callApi(updatedValues);
};

const callApi = async (payload) => {
  const deviceName = isMobile
      ? "Mobile Device"
      : isDesktop
      ? "Desktop"
      : isAndroid
      ? "Android"
      : isIOS;
  const response = await axios.get("https://api.ipify.org?format=json");
  const userIP = response.data.ip;
  const newSearchID = uuidv4().slice(0, 16);

  // Update the formValues with new searchID and userIP
  const updatedFormValues = {
    ...payload,
    searchID: newSearchID,
    userIP: userIP,
    device: deviceName,
  };

  // Log the API payload
  console.log("API Payload: ", updatedFormValues);
  setIsLoading(true)
  // Make the API call
  try {
    const response = await axios.post(
      `${apiIP}:7080/api/flight-result`,
      updatedFormValues
    );

    if (response.status === 200) {
      setResponseData(response.data); // Update the state with the response data
      console.log(response.data);
      const mergedData = {
        responseData: response.data,
        formData: updatedFormValues,
      };
      setIsLoading(false)
      navigate(`/flight-list?searchID=${newSearchID}`, { state: mergedData });
    } else {
      setIsLoading(false)
      console.error("Request failed with status:", response.status);
    }
  } catch (error) {
    setIsLoading(false)
    console.error("Error making POST request:", error);
  }
};
function formatDate(date) {
  const formattedDate = new Date(date);
  const month = formattedDate.getMonth() + 1;
  const day = formattedDate.getDate();
  const year = formattedDate.getFullYear();
  return `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}/${year}`;
}

useEffect(() => {
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const bottomCallBar = document.querySelector('.bottom-call-bar2');
    
    if (bottomCallBar) {
      if (scrollPosition > 200) {
        bottomCallBar.style.display = 'block';
      } else {
        bottomCallBar.style.display = 'none';
      }
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

useEffect(() => {
  if (typeof window.gtag === 'function') {
      window.gtag('config', 'G-4T4E0J0CXE', {
          'page_path': location.pathname + location.search,
          'page_title': `${airlineName} | Cheap Flights | Rawfares`,
      });
  }
}, [location, airlineName]);
  return (
    <>
    <MetaTitle pageTitle='Book Flight Reservation with Latam |   Rawfares' url={`${apiIP}/airfare-deals?tfn=1536&aname=Latam&utm_source=cpc`} />
    <header class="headers _headers">
        <div class="container">            
            <div class="headerInr _header-logo active">
                <div class="">
                    <Link to="/">
						<img style={{width: '150px'}} src={Logo} alt="logo" />
					</Link>
                </div>
                <div class="rightCall">					
                    <Link to="tel:+1-888-415-1536" style={{textDecoration: 'none !important'}}>
					<span><img src={Phone2} alt="logo" /> +1-888-415-1536</span></Link>
                </div>
                {/* <!-- <i class="mobile_menu_switcher mobile_menu_switcher-airline"></i> --> */}
            </div>
        </div>
    </header>
    <section class="banners">
		<div class="bannerInner">
			<div class="clickFull">
				 <Link to="tel: +1-888-415-1536" class="ddka"></Link>
				 <p class="inertitle">
					<span>Find Latam Airline Ticket</span>
				 </p>
				 <div class="airLineimg">
					<img src={CallBackto} />
				</div>	

		     <div class="contant">
			<Link to="tel: +1-888-415-1536">				
				<span class="nbrs">Booking, Changes & Cancellation</span>
			</Link>
		   </div>

			<h2 style={{marginBottom: '15px'}}>
				<b>No Hold - Call Answered in 5 Sec</b>
			</h2>

			<div class="numbers">
				<Link to="tel: +1-888-415-1536">
					<span class="ringing_phone"></span>
					<span class="nbrs">  +1-888-415-1536</span>
				</Link>
			</div>

			</div>
			
		</div>
	</section>
  {popD === true ? (
  <div id="ppcboxes">
	   <div class="home-ppc-popup">
		  <div id="dialogppc" class="windowpc">
      <div style={{cursor: 'pointer'}} id="close_id" onClick={() => {setPopD(false)}}  class="ppc-close">
			 <img src={Close} alt="cheap flight" width="30" height="30" /> 
			  </div> 
			 <div class="popup-dsgn-box">
				 <div class="gAcontent">
					
					<div class="popheader">            
						<div class="Inrheadepop">
							<div class="poplogo">
								<Link to="/">
									<img src={Logo} alt="logo" />
								</Link>
							</div>
							<div class="mainpop">					
								<Link to="tel: +1-888-415-1536" style={{textDecoration: 'none !important'}}>
								<img src={Phone2} alt="logo" /> +1-888-415-1536</Link>
							</div>
							
						</div>
					</div>

					<p class="airlinestitle">
						<span>Latam Airlines Ticket</span>
					 </p>
					
				 
				 </div>
				 
					<div class="usplst">
					<div>New Booking</div>
					<div>Changes</div>
					<div>Cancellation</div>
					<div>Customer Service</div>
					<Link to="tel: +1-888-415-1536" class="svnSpt"></Link>
					</div>
				 
				 <div class="flightImage">
				  <img src={BritAirline} alt="" />
					</div>
				 <div class="girlCalling">
						<img src={Calling1} />
					</div>
				 <p class="callTxt">No Hold - Call Answered in 5 Sec</p>

				 <div class="tfn-nmbr">
					<Link to="tel: +1-888-415-1536">
						<span class="ringing_phone"></span>
						<span>+1-888-415-1536</span>
					</Link>
				</div>
													 
				 <p class="pohlp"><Link to="tel:+1-888-415-1536" style={{textDecoration: 'none'}}>24/7 Helpline</Link></p>
				 
			 </div>
		  </div>
		  <div id="maskppc"></div>
	</div>
	</div> 
  ) : null }
    {isLoading && (
        <div className="loader-overlay">
          <img src={Loader} alt="Loader" />
        </div>
      )}
      <Searchbar backgroundImage="https://imgfolders.com/rawfares/rawfaresUS/home_banner/cover.webp" />
      <Box p={5} maxWidth="1200px" mx="auto">
        <Box mb={8} textAlign="center">
          <Text 
            fontSize={["2xl", "3xl", "4xl"]} // Responsive font sizes for mobile, tablet, and desktop
            fontWeight="bold" 
            mb={4}
            whiteSpace="nowrap" // Prevents text from wrapping
            wordBreak="keep-all" // Ensures words don’t break mid-word
          >
          LATAM FLIGHT DEALS
          </Text>
          <Text fontSize="sm" color="gray.600">
            * Fares on this page are updated as of Aug 07, 2024 at 9:26 AM EST. They are for One Way or Round Trips as mentioned
            alongside. These include all <Text as="span" color="red.500">taxes and fees </Text> and Fares based on historical data, may change without prior notice, and can't be guaranteed until booking confirmation. Please read our <Text as="span" color="red.500">Terms & Conditions</Text> carefully terms & conditions carefully.
          </Text>
        </Box>
        <Grid templateColumns="repeat(auto-fit, minmax(320px, 1fr))" gap={6}>
        <FlightCard origin="DFW" destination="DEN" startDate="Nov 02, 2024" endDate="Dec 06, 2024" price="$98.00" onClick={handleCardClick} />
          <FlightCard origin="LAS" destination="LAX" startDate="Nov 20, 2024" endDate="Dec 06, 2024" price="$99.00" onClick={handleCardClick} />
          <FlightCard origin="JFK" destination="PHX" startDate="Nov 01, 2024" endDate="Dec 06, 2024" price="$175.00" onClick={handleCardClick} />
          <FlightCard origin="LAS" destination="SFO" startDate="Nov 06, 2024" endDate="Dec 10, 2024" price="$250.59" onClick={handleCardClick} />
          <FlightCard origin="ORD" destination="HNL" startDate="Nov 19, 2024" endDate="Dec 06, 2024" price="$275.00" onClick={handleCardClick} />
          <FlightCard origin="NYC" destination="ATL" startDate="Nov 23, 2024" endDate="Dec 06, 2024" price="$375.00" onClick={handleCardClick} />
          <FlightCard origin="SEA" destination="PHL" startDate="Nov 29, 2024" endDate="Dec 06, 2024" price="$400.25" onClick={handleCardClick} />
          <FlightCard origin="SFO" destination="LAX" startDate="Nov 20, 2024" endDate="Dec 06, 2024" price="$595.00" onClick={handleCardClick} />
          <FlightCard origin="MSP" destination="TPA" startDate="Nov 20, 2024" endDate="Dec 06, 2024" price="$112.00" onClick={handleCardClick} />
        </Grid>
        <Box mt={8}>
        <Text fontSize="lg" color="gray.600">LATAM Airlines is a major airline group based in Latin America, formed through the merger of LAN Airlines (Chile) and TAM Airlines (Brazil). It is one of the largest airline groups in the region. Here are some key details about LATAM:</Text>
        <List spacing={3}>
             <ListItem>
                 <b>1. Fleet:</b> LATAM operates a diverse fleet that includes aircraft from both Boeing and Airbus, such as the Boeing 787 Dreamliner and Airbus A320 series.
             </ListItem>
             <ListItem>
                 <b>2. Destinations:</b> LATAM offers an extensive network of domestic and international flights, connecting numerous cities across South America, Central America, North America, Europe, and Oceania.
             </ListItem>
             <ListItem>
                 <b>3. Frequent Flyer Program:</b> LATAM's loyalty program is called LATAM Pass. Members can earn points for flights and other activities, which can be redeemed for tickets, upgrades, and other rewards. LATAM Pass also offers various tiers with different benefits.
             </ListItem>
             <ListItem>
                <b>4. Services:</b> LATAM provides different classes of service:
                <List spacing={3}>
                <ListItem>
                Economy Class: Standard seating with various amenities depending on the route.
                </ListItem>
                <ListItem>
                Premium Economy: Available on select routes, offering more comfort and additional perks.
                </ListItem>
                <ListItem>
                Business Class: Features lie-flat seats, enhanced dining options, and access to lounges.
                </ListItem>
                </List>
             </ListItem>
             <ListItem>
                <b>5. Partnerships:</b> LATAM was a member of the Oneworld alliance but left the alliance in 2020 due to strategic changes. The airline has various codeshare agreements with other airlines to extend its network and connectivity.
             </ListItem>
             <ListItem>
                <b>6. Hubs:</b> LATAM operates major hubs in Santiago (SCL), São Paulo (GRU), and Bogotá (BOG), among other locations, which serve as central points for its operations and connections.
             </ListItem>
         </List>
        </Box>
        <Text fontSize="sm" color="gray.600">
            {disclaimer}
          </Text>
      </Box>
      <Footer />
    </>
  );
};

export default Latam;
