/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SITE_CONFIG from '../SiteController/SiteController';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "../resources/css/airline-style.css";
import background from '../resources/images/air-deals/img-airlines.jpg'
import backTab from "../resources/images/tabsbg.png";
import tabsArrow from "../resources/images/tabsarow.png";
import Arror from '../resources/images/icons/arowimg.png'
import Searchbar from "../Shared/Searchbar";
import Footer from "../components/Footer/footer";
import IMG1 from '../resources/images/footertop1.png';
import IMG2 from '../resources/images/footertop2.png';
import IMG3 from '../resources/images/footertop3.png';
import UA from '../resources/images/airline/United-Airlines.png';
import NK from '../resources/images/airline/spirit-Airlines.png';
import F9 from '../resources/images/airline/frontier-airlines.png';
import DealsGlobe from '../resources/images/globepine.png'
import footerBottom from '../resources/images/footerbottom.png'
import MetaTitle from "./metaTitle";

function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat(
    "en-US",
    dateOptions
  ).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat(
    "en-US",
    timeOptions
  ).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

export default function AirlinesDetails(event) {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  // const [response1, setResponse1] = useState(null);
  // const [response2, setResponse2] = useState(null);
  // useEffect(() => {
  //   const handleMessage = (event) => {
  //     const receivedData = event.data;
  //     //console.log('Received data:', receivedData);

  //     // Ensure the received data is not related to Webpack hot updates
  //     if (
  //       typeof receivedData === 'object' && // Check if it's an object
  //       receivedData !== null && // Ensure it's not null
  //       !('webpackHotUpdate' in receivedData) // Exclude Webpack HMR messages
  //     ) {
  //       //console.log('Valid data received:', receivedData);
  //       // Process the valid data accordingly
  //       // Example: SetResponse1 and setResponse2 based on receivedData structure
  //       if ('response1' in receivedData) {
  //         setResponse1(receivedData.response1.response[0]);
  //         //console.log(response1)
  //       }
  //       if ('response2' in receivedData) {
  //         setResponse2(receivedData.response2);
  //         //console.log(response2)
  //       }
  //     } else {
  //       //console.warn('Ignoring non-relevant data:', receivedData);
  //     }
  //   };

  //   window.addEventListener('message', handleMessage);

  //   return () => {
  //     window.removeEventListener('message', handleMessage);
  //   };
  // }, []);
  // useEffect(() => {
  //   //console.log('Response 1 updated:', response1);
  // }, [response1]);
  
  // useEffect(() => {
  //   //console.log('Response 2 updated:', response2);
  // }, [response2]);
  const location = useLocation();
  // const params = new URLSearchParams(location.search);
  //   const encodedData = params.get('data');
  
  const { response1, response2 } = location.state || {};
  // let response1, response2;
  // const decodedData = JSON.parse(decodeURIComponent(encodedData));
  //   response1 = decodedData.response1;
  //   response2 = decodedData.response2;
   const { airlineName } = useParams();

 // Split the airlineName using the hyphen separator to get individual parts
//  const parts = airlineName.split('-');

//  // Get the last part as the URL
//  const pageValue = parts.pop();

//  // Get the remaining parts as the Page Value
//  const url = parts.join('-');
const parts = airlineName.split('-');

// Remove the last part ('flights')
parts.pop();

// Get the remaining parts as the URL


// Get the last part as the Page Value
const pageValue = parts.pop();
const url = parts.join('-');
 // Now, url will be 'american-airlines' and pageValue will be 'aa'
 ////console.log('URL:', url);
 ////console.log('Page Value:', pageValue);
  
  //const [backgroundImage, setBackgroundImage] = useState('https://imgfolders.com/farehutz/AA.png');
  const [firstResponseData, setFirstResponseData] = useState(response1?.response[0] || null);
  const [secondResponseData, setSecondResponseData] = useState(response2 || null);
  
   const [reloadData, setReloadData] = useState({
     pageType: "Airline",
     pageValue: pageValue,
     siteId: siteID,
     url: url
   })

   const fetchData = async () => {
    
 ////console.log(reloadData)
     try {
       let response;
       if (!response1) {
         response = await axios.post(`${apiIP}:9090/api/Airlines-Details`, reloadData);
         // Set firstResponseData state with the response if response1 is null
         if (response.status === 200) {
           setFirstResponseData(response.data.response[0]);
           //console.log("Response from the first API:", response.data);
           ////console.log(deals)
           const response2 = await axios.post(`${apiIP}:9191/api/Airlines-Routes`,
           { froCity:"",
           toCity:"",
           airline: pageValue,
           cabinClass:"Y",
           tripType:"2",
           page:"",
           currency:"USD",
           siteId: siteID,
           departDate:"",
           countryName:"US",
           countryCode:"US",
           cityName:"" }
           );
           setSecondResponseData(response2.data);
           ////console.log("responswe23",response2);
         }
            
            
       } else {
         // Use the existing response1 if available
         setFirstResponseData(response1.response[0]);
       }
       ////console.log(response);
       // Update state with response data if needed
     } catch (error) {
       ////console.error('Error fetching data:', error);
       // Handle errors appropriately
     }
   };

   useEffect(() => {
     // Check if response1 or response2 is null, then call fetchData
     if (!response1 || !response2) {
       fetchData();
     }
   }, [url, pageValue, response1, response2]);
   
   const [selectedFlight, setSelectedFlight] = useState();
   useEffect(() => {
    if (secondResponseData && secondResponseData.response.length > 0) {
      setSelectedFlight(secondResponseData.response[0]);
    }
  }, [secondResponseData]);
   ////console.log(secondResponseData?.response[0])
   ////console.log(selectedFlight)
  const handleFroCityClick = (item) => {
    setSelectedFlight(item);
  };

  
    // const divStyle = {
    //   backgroundImage: `url(${background})`
      
    // };
    
    //const [backgroundImage, setBackgroundImage] = useState('https://imgfolders.com/farehutz/AA.png');


    // const changeBackgroundImage = () => {
    //   const imageName = `${url}.png`; // Assuming `url` contains the image name
    //   setBackgroundImage(`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/airlinecover_banner/${imageName}`);
    // };
    
    // useEffect(() => {
    //   changeBackgroundImage();
    // }, [url]); // Trigger the change when `url` changes
  
    // useEffect(() => {
    //   return () => {
    //     // Reset the background image to the initial value when leaving the page
    //     setBackgroundImage('../resources/images/main_banner.webp');
    //   };
    // }, []);
     const imageNames = ['allegiant-air.webp','frontier-airlines.webp', 'american-airlines.webp', 'hawaiian-airlines.webp', 'jetBlue-airways.webp', 'lufthansa-airlines.webp', 'spirit-airlines.webp', 'sun-country-airlines.webp', 'united-airlines.webp'];
     const filteredImages = imageNames
     .filter(name => name !== `${url}.webp`)
     .slice(0, 3); // Limiting to 3 images

     const imageNameToAirline = {
      'allegiant-air.webp': 'allegiant-air',
      'frontier-airlines.webp': 'frontier-airlines',
      'american-airlines.webp': 'american-airlines',
      'hawaiian-airlines.webp': 'hawaiian-airlines',
      'jetBlue-airways.webp': 'jetblue-airways',
      'lufthansa-airlines.webp': 'lufthansa-airlines',
      'spirit-airlines.webp': 'spirit-airlines',
      'sun-country-airlines.webp': 'sun-country-airlines',
      'united-airlines.webp': 'united-airlines'
    };
    const imageNameToAirlineCode = {
      'allegiant-air.webp': 'g4',
      'frontier-airlines.webp': 'f9',
      'american-airlines.webp': 'aa',
      'hawaiian-airlines.webp': 'ha',
      'jetBlue-airways.webp': 'b6',
      'lufthansa-airlines.webp': 'lh',
      'spirit-airlines.webp': 'nk',
      'sun-country-airlines.webp': 'sy',
      'united-airlines.webp': 'ua'
    };
    
    // Now, in your handleSideClick function, use this mapping to get the airline name corresponding to the clicked image
    const handleSideClick = (pageValue, imageName) => {
      const airlineName = imageNameToAirline[imageName];
      if (airlineName) {
        setReloadData({
          ...reloadData,
          pageValue,
          url: airlineName,
        });
        //fetchData();
        handleAirlineClicked();
      }
    };
    

    const getAirlineCode = (name) => {
      // Define a mapping of airline names to their respective codes
      const airlineCodes = {
        "Alaska Airlines": "AS",
       // "Delta Air Lines": "DL",
        "United Airlines": "UA",
        "Lufthansa Airlines": "LH",
        "Frontier Airlines": "F9",
        "Spirit Airlines": "NK",
        "JetBlue Airways": "B6",
        "Hawaiian Airlines": "HA",
        "Sun Country Airlines": "SY"
        // Add more airline names and codes as needed
      };
    
      return airlineCodes[name];
    };
    
    // Function to create a URL with the airline code appended to the airline name
    const createURL = (name) => {
      const airlineCode = getAirlineCode(name);
      return `https://www.rawfares.com/airline/${name.toLowerCase().replace(/\s+/g, '-')}-${airlineCode.toLowerCase()}-flights`;
    };

    const [airlines, setAirlines] = useState({
      pageType: "Airline",
      pageValue: "",
      siteId: siteID,
      url: ""
    });

    const [deals, setDeals] = useState({
      froCity:"",
      toCity:"",
      airline: airlines.pageValue,
      cabinClass:"Y",
      tripType:"2",
      page:"",
      currency:"USD",
      siteId: siteID,
      departDate:"",
      countryName:"US",
      countryCode:"US",
      cityName:""
  });
    const [responseData, setResponseData] = useState(null);
    //const navigate = useNavigate();
    useEffect(() => {
      // Use a useEffect to make the API call whenever airlines state changes
      if (airlines.pageValue && airlines.url) {
        handleAirlineClicked();
      }
    }, [airlines]);
  
//       const handleAirlineClicked = async () => {
//         document.body.style.overflowY = 'hidden';
//       const homeBody = document.querySelector('.HomeBody');
// if (homeBody) {
//   const elementsToBlur = homeBody.querySelectorAll(':not(.loader-overlay)');
//   elementsToBlur.forEach((element) => {
//     element.style.filter = 'blur(8px)';
//   });
// }
//       setIsLoading(true)
//       //console.log(airlines);
//       //console.log(deals)
//         try {
//           const response1 = await axios.post(
//             "http://localhost:9090/api/Airlines-Details",
//             airlines
//           );
    
//           if (response1.status === 200) {
//             setResponseData(response1.data);
//             //console.log("Response from the first API:", response1.data);
//             //console.log(deals)
//             const response2 = await axios.post("http://localhost:9191/api/Airlines-Routes", deals);
    
//             if (response2.status === 200) {
//               const Data = {
//                 response1: response1.data,
//                 response2: response2.data
//               };
//               //console.log("Response from the Second API:", response2.data);
//               const encodedData = encodeURIComponent(JSON.stringify(Data));
//   const navigationPath = airlines.pageValue.length === 2
//     ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
//     : `/flights-to/${airlines.url}-${airlines.pageValue}`;

// //   const newTab = window.open(navigationPath + `?data=${encodedData}`, '_blank');
// //   if (newTab) {
// //     newTab.focus();
// //   } else {
// //     //console.error('Failed to open new tab. Please check your browser settings or extensions.');
// //   }

//   //setIsLoading(false);

//               navigate(navigationPath, {
//                 state: Data
//               });
//             document.body.style.overflowY = 'none';
//       const homeBody = document.querySelector('.HomeBody');
// if (homeBody) {
//   const elementsToBlur = homeBody.querySelectorAll(':not(.loader-overlay)');
//   elementsToBlur.forEach((element) => {
//     element.style.filter = 'none';
//   });
// }
//             setIsLoading(false)
//             } else {
//               //console.error("Request to the second API failed with status:", response2.status);
//             }
//           } else {
//             //console.error("Request to the first API failed with status:", response1.status);
//           }
//         } catch (error) {
//           //console.error("Error making one of the API requests:", error);
//         }
//       };

const handleAirlineClicked = async () => {
//     setIsLoading(true);
//     try {
//       const response1 = await axios.post("http://localhost:9090/api/Airlines-Details", airlines);

//       if (response1.status === 200) {
//         setResponseData(response1.data);
//         // Handle response1 data as needed
//         const response2 = await axios.post("http://localhost:9191/api/Airlines-Routes", deals);

//         if (response2.status === 200) {
//           // Handle response2 data as needed
        
//           const Data = {
//             response1: response1.data,
//             response2: response2.data
//           };
//           openNewTabWithData(Data);
//           setIsLoading(false);
//         } else {
//           //console.error("Request to the second API failed with status:", response2.status);
//         }
//       } else {
//         //console.error("Request to the first API failed with status:", response1.status);
//       }
//     } catch (error) {
//       //console.error("Error making one of the API requests:", error);
//     }
//   };

//   const openNewTabWithData = (Data) => {
  const navigationPath = airlines.pageValue.length === 2
    ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
    : `/flights-to/${airlines.url}-${airlines.pageValue}`;

  const newTab = window.open(navigationPath, '_blank');
  if (newTab) {
      ////console.log('Received data in new tab:');
    newTab.onload = function() {
      newTab.postMessage(window.location.origin);
    };
  } else {
    ////console.error('Failed to open new tab. Please check your browser settings or extensions.');
  }

  // Navigate to the new page with data passed via state
  //navigate(navigationPath);
};
    
    
  
    const handleCardClick = (pageType, pageValue, url) => {
      // Set the new values for airlines based on the card clicked
      setAirlines({
        ...airlines,
        pageType,
        pageValue,
        url,
      });
    };

    const openLink = (url) => {
  window.open(url, '_blank');
  };
  const standardizeAirlineName = (name) => {
    // Define a mapping of variations to a standard name
    const nameVariations = {
      'allegiant-air': 'Allegiant Airlines',
      'frontier-airlines': 'Frontier Airlines',
      'american-airlines': 'American Airlines',
      'hawaiian-airlines': 'Hawaiian Airlines',
      'jetblue-airways': 'JetBlue Airways',
      'lufthansa-airlines': 'Lufthansa Airlines',
      'spirit-airlines': 'Spirit Airlines',
      'sun-country-airlines': 'Sun Country Airlines',
      'united-airlines': 'United Airlines'
      // Add more variations as needed
    };
  
    return nameVariations[name] || name; // If a variation is found, return the standardized name, otherwise return the original name
  };
  
  // Apply the standardization to create an array of standardized airline names from filteredImages
  const airlineNamesFromImages = filteredImages.map(imageName => standardizeAirlineName(imageNameToAirline[imageName]));

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
    <MetaTitle pageTitle={firstResponseData?.metaTitle} pageDescription={firstResponseData?.metaDesc} url={`${apiIP}/airline/${airlineName}`} />
      <Searchbar backgroundImage={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/airlinecover_banner_plan/${firstResponseData?.pageValue}.webp`} />
      
          <div class="dels-sction"  >
            <div class="best-cntnr">
              <div class="col-first">
                <h1>Book a Flights on {firstResponseData?.pageName} Reservations</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: firstResponseData?.contentData,
                  }}
                ></div>

                {/* <p>{firstResponseData?.dealCode}</p> */}

                {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.    
	  </p> */}
                {/* <div class="cntrbox">
		 <div class="middle-img">
			 <img src="resources/images/airline/img-boxes1.png" alt=""/>
		</div>
		 <div class="middle-img">
			 <img src="resources/images/airline/img-boxes2.png" alt=""/>
		</div>
		 </div> */}

                {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.   
	  </p> */}

                {/* <ul>
		<li>injected humour</li>
		<li>many variations of passages</li>
		<li>dictionary of over 200 Latin</li>	
		
		</ul> */}

                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p> */}
              </div>

              <div class="col-last">
              {filteredImages.map((imageName, index) => (
        <div className="sidebox" key={index}>
          <div onClick={() => handleCardClick("Airline", imageNameToAirlineCode[imageName], imageNameToAirline[imageName])} style={{cursor: 'pointer'}}>
            <img
              src={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/airlineside_banner/${imageName}`}
              alt={imageNameToAirline[imageName]}
            />
          </div>
        </div>
      ))}

                <div class="side_airs">
  <h4>Top Airlines</h4>
  <ul>
    {["Alaska Airlines", /*"Delta Air Lines", */"United Airlines", "Lufthansa Airlines", "Frontier Airlines", "Spirit Airlines", "JetBlue Airways", "Hawaiian Airlines", "Sun Country Airlines"].filter(airlineName => (
      // Check if the airline name is not equal to firstResponseData.pageName and not present in airlineNamesFromImages
      firstResponseData?.pageName !== airlineName && !airlineNamesFromImages.includes(airlineName)
    )).map(airlineName => (
      <li key={airlineName}>
        <Link to={createURL(airlineName)} target='_blank' onClick={() => fetchData()}>
          {airlineName} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  </ul>
</div>
              </div>

              <div class="full-width">
              {windowWidth > 430 && (
                <div class="tabgcolor">
                  <h3>
                    Flight Deals from {secondResponseData?.response[0]?.froCityName} to{" "}
                    {secondResponseData?.response[0]?.toCityName}
                  </h3>
                  <div
                    class="maintabs"
                    style={{
                      height: "auto",
                      backgroundImage: `url(https://imgfolders.com/rawfares/rawfaresUS/airline_detail/airlinecover_banner/${firstResponseData?.pageValue}.webp)`,
                      backgroundSize: "cover",
                    }}
                  >
                    <div class="tab">
  <div class="tabs_heading">Flight From</div>
  {secondResponseData &&
  secondResponseData.response &&
  Array.isArray(secondResponseData.response) ? (
    secondResponseData.response.map((item, index) => {
      // Remove "intl airport" from the city name
      const cityName = item.froCityName.replace(" Intl Airport", "");

      return (
        <button
          class={`linkstab ${item === selectedFlight ? 'selected' : ''}`}
          key={index}
          onClick={() => handleFroCityClick(item)}
        >
          <img src={DealsGlobe} alt="DealsGlobe" />{" "}
          {cityName}
          <span class="tbarow">
            <img src={tabsArrow} alt="tabsArrow" />
          </span>
        </button>
      );
    })
  ) : (
    <p>No responses available</p>
  )}
  {/* <!--//////////More Button////////////--> */}
</div>

                    {/* <!--Tabs--> */}
                    {selectedFlight ? (
                      <div id="newyorkcity" class="bottomright">
                        <h3 style={{ color: "#fff" }}>
                          Fly To {selectedFlight.toCityName}
                        </h3>
                        <div class="cityfaremain">
                          <div class="citydate">
                            <p>
                              Depart Date-
                              {
                                formatDateAndTime(selectedFlight.depDate)
                                  .formattedDateString
                              }
                            </p>
                            <p>
                              Return Date-
                              {
                                formatDateAndTime(selectedFlight.retDate)
                                  .formattedDateString
                              }
                            </p>
                          </div>
                          <div class="city-fare">
                            <p>Fare*</p>
                            <p>
                              ${selectedFlight.totalPrice} {/*<sup>00</sup>*/}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p>Select a city to view details.</p>
                    )}

                    <div style={{ clear: "both" }}></div>
                  </div>

                  {/* <script>
function openCity(evt, cityName) {
  var i, tabcontent, linkstab;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  linkstab = document.getElementsByClassName("linkstab");
  for (i = 0; i < linkstab.length; i++) {
    linkstab[i].className = linkstab[i].className.replace(" active", "");
  }
  document.getElementById(cityName).style.display = "block";
  evt.currentTarget.className += " active";
}
// Get the element with id="defaultOpen" and click on it
document.getElementById("defaultOpen").click();
</script> */}

                  <div style={{ clear: "both" }}></div>
                  <p class="dismer">
                    <span>Disclamer:-</span> All fares on RawFares.com are
                    quoted in USD. These fare have Originated from historical
                    data. Might vary and cannot be promised at the time of
                    booking.
                  </p>
                </div>
              )}
                 <h3 style={{ paddingTop: "20px" }}>{firstResponseData?.airportHub}</h3>
                 <div
                  dangerouslySetInnerHTML={{
                    __html: firstResponseData?.travelTrends,
                  }}
                ></div>
               {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, dummy
                  text of the printing and typesetting industry. Lorem Ipsum has
                  been the industry's standard dummy text ever since the 1500s,
                  when an unknown printer took a galley of type and scrambled it
                  to make a type specimen book. It has survived not only five
                  centuries
                </p> */}

                {/* <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary, making this the first true generator on the
                  Internet. It uses a dictionary of over 200 Latin words,
                  combined with a handful of model sentence structures, to
                  generate Lorem Ipsum which looks reasonable. The generated
                  Lorem Ipsum is therefore always free from repetition, injected
                  humour, or non-characteristic words etc. by injected humour,
                  or randomised words which don't look even slightly believable.
                  If you are going to use a passage of Lorem Ipsum, you need to
                  be sure there isn't anything embarrassing hidden in the middle
                  of text. All the Lorem Ipsum generators on the Internet tend
                  to repeat predefined chunks as necessary, making this the
                  first true generator on the Internet. It uses a dictionary of
                  over 200 Latin words, combined with a handful of model
                  sentence structures, to generate Lorem Ipsum which looks
                  reasonable.
                </p> */}

                <div class="threeimg">
                  <div style={{cursor: 'pointer'}} onClick={(e) => {handleCardClick("City", "mex", "mexico-city"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}}>
                    <img src={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/footer_banner/mexico-city.webp`} alt="mexico-city" />
                  </div>
                  <div style={{cursor: 'pointer'}} onClick={(e) => {handleCardClick("City", "abq", "albuquerque"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}}>
                    <img src={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/footer_banner/albuquerque.webp`} alt="albuquerque" />
                  </div>
                  <div style={{cursor: 'pointer'}} onClick={(e) => {handleCardClick("City", "ord", "chicago"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}}>
                    <img src={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/footer_banner/Chicago.webp`} alt="Chicago" />
                  </div>
                </div>

                <div class="threeimg">
                  <div style={{cursor: 'pointer'}} onClick={(e) => {handleCardClick("City", "was", "washington"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}}>
                    <img
                      src={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/footer_banner/washington.webp`}
                      alt="washington"
                    />
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        <div style={{clear: 'both'}}></div>
        <Footer />
      
    </>
  );
}
