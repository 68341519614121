import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SITE_CONFIG from '../SiteController/SiteController';
import { DateRangePicker } from "react-date-range";
import axios from "axios";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme css file
 import '../resources/css/enquiry/style.css'
import Footer from '../components/Footer/footer';

export default function HotelEnquiry() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
    const today = new Date();
    const location = useLocation();
    const { hotelFormValues } = location.state || {};
  const oneWeekFromToday = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000); // Adding milliseconds for one week
  const oneYearFromToday = new Date();
  oneYearFromToday.setFullYear(today.getFullYear() + 1); // Add one year to the current date
  const [selectedStartDate, setSelectedStartDate] = useState(today);
  const [selectedEndDate, setSelectedEndDate] = useState(oneWeekFromToday);
  const [selectedOneDate, setSelectedOneDate] = useState(selectedStartDate);
  const [direction, setDirection] = useState('horizontal');
  const [months, setMonths] = useState(1);

    const [calendarVisible, setCalendarVisible] = useState(false);
    const [departCalendarVisible, setDepartCalendarVisible] = useState(false);
    const [firstName, setFirstName] = useState('')
    const totalPassengers = parseInt(hotelFormValues.adults) + parseInt(hotelFormValues.child) + parseInt(hotelFormValues.infants) + parseInt(hotelFormValues.infantsWs);
  const handleInputFocus = () => {
    setCalendarVisible(true);
  };

  const handleApply = () => {
    ////console.log("Selected Range:", selectedStartDate, selectedEndDate);
    // You can perform any actions here with the selected range
    setCalendarVisible(false);
  };

  const handleSelect = (ranges) => {
    setSelectedStartDate(ranges.selection.startDate);
    setSelectedEndDate(ranges.selection.endDate);
    setSelectedOneDate(ranges.selection.startDate);
  };

  function formatDate(date) {
    const formattedDate = new Date(date);
    const month = formattedDate.getMonth() + 1;
    const day = formattedDate.getDate();
    const year = formattedDate.getFullYear();
    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;
  }
  const [hotelName, setHotelName] = useState('');
  const [ lastName, setLastName] = useState('');
  const [ phoneNumber, setPhoneNumber] = useState('');
  const [ email, setEmail ] = useState('')
  const [formValues, setFormValues] = useState({
    arrivalDate: hotelFormValues.checkin,
    departDate: hotelFormValues.checkout,
    hotelName: '',
    numberOfGuest: totalPassengers,
    firstName: '',
    lastName: "",
    phoneNumber: "",
    email: ""
  });

  const navigate = useNavigate();


  const [calendarValue, setCalendarValue] = useState([
    formValues.arrivalDate
      ? new Date(formValues.arrivalDate)
      : null,
    formValues.departDate
      ? new Date(formValues.departDate)
      : null,
  ]);

  const isDayDisabled = (date) => {
    return date < today;
  };

  const handleOneWaySelect = (range) => {
    setSelectedOneDate(range.selection.startDate);
  };

  console.log(hotelFormValues)
  
  const handleChangeHotelName = (e) => {
    const newHotel = e.target.value;
    setHotelName(newHotel);
    setFormValues(prevValues => ({
      ...prevValues,
      hotelName: newHotel
    }));
  };

  const handleChangeFirstName = (e) => {
    const newFirst = e.target.value;
    setFirstName(newFirst);
    setFormValues(prevValues => ({
      ...prevValues,
      firstName: newFirst
    }));
  };

  const handleChangeLastName = (e) => {
    const newLast = e.target.value;
    setLastName(newLast);
    setFormValues(prevValues => ({
      ...prevValues,
      lastName: newLast
    }));
  };

  const handleChangePhoneNumber = (e) => {
    const newPhone = e.target.value;
    setPhoneNumber(newPhone);
    setFormValues(prevValues => ({
      ...prevValues,
      phoneNumber: newPhone
    }));
  };

  const handleChangeEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setFormValues(prevValues => ({
      ...prevValues,
      email: newEmail
    }));
  };

  const handleSubmit = async (e) => {
    console.log(formValues)
    if(formValues.firstName !== '' && formValues.lastName !== '' && formValues.phoneNumber !== '' && formValues.email !== '' && formValues.hotelName !== ''){
    try {
      // if (!phoneNumber || !email) {
      //     alert('Please fill in both phone number and email.');
      //     return;
      //   }
    // Send data to the server
    const response = await axios.post(`${apiIP}:1000/api/hotel-enquiry`, {
      formValues: formValues
    });

    if (response.status === 200) {
      //alert('Form submitted successfully!');
      // Reset form fields
      //setPhoneNumber('');
      //setEmail('');
      navigate('/thankyou')
    } else {
      alert('Form submission failed.');
    }
  } catch (error) {
    ////console.error(error);
    //alert('Form submission failed.');
  }
} else {
  alert("Please fill the complete form")
}
  };
  return (
  //   <div className='hotelEnquiry'>
  //     <h1>Hotel Enquiry Form</h1>
	// <div class="main-agile">
	// 	{/* <form /*action="#" method="post"> */}
        
	// 		<input  id="datepicker" name="Text" type="text" value={hotelFormValues.checkin} onfocus="this.value = '';" onblur="if (this.value == '') {this.value = 'Arrival date';}" onClick={() => {setCalendarVisible(!calendarVisible)}} required="" />
  //           <div className="two-month-range-calendar-container" style={{zIndex: '5'}}>
  //                                     {/* <h2>Two-Month Range Calendar</h2> */}
  //                                     {calendarVisible && (
  //                                     <div style={{ backgroundColor: "white" }}>
  //                                       <div
  //                                         className="range-calendar-container"
  //                                         style={{ position: "absolute" }}
  //                                       >
  //                                         {/* <h2>Range Calendar</h2> */}
  //                                         <DateRangePicker
  //                                           onChange={(e) => {
  //                                             handleOneWaySelect(e);
  //                                             setFormValues((prevState) => ({
  //                                               ...prevState,
  //                                               segment: [
  //                                                 {
  //                                                   ...prevState.segment[0],
  //                                                   travelDate: formatDate(
  //                                                     selectedOneDate.toLocaleDateString()
  //                                                   ),
  //                                                 },
  //                                               ],
  //                                             }));
  //                                           }}
  //                                           ranges={[
  //                                             {
  //                                               startDate: selectedOneDate,
  //                                               endDate: selectedOneDate,
  //                                               key: "selection",
  //                                             },
  //                                           ]}
  //                                           months={months}
  //                                           showSelectionPreview={true}
  //                                           moveRangeOnFirstSelection={false}
  //                                           direction={direction}
  //                                           minDate={today}
  //                                           maxDate={oneYearFromToday}
  //                                           isDayBlocked={isDayDisabled} // Disable past dates
  //                                           style={{
  //                                             width: "300px",
  //                                             height: "250px",
  //                                           }}
  //                                         />
  //                                         <div
  //                                           className="button-container"
  //                                           style={{
  //                                             backgroundColor: "white",
  //                                             display: "flex",
  //                                             flexDirection: "row-reverse",
  //                                             alignItems: "center",
  //                                             margin: "0px",
  //                                           }}
  //                                         >
  //                                           <button style={{zIndex: '5'}} onClick={handleApply}>
  //                                             Apply
  //                                           </button>
  //                                           {/* <button style={{zIndex: '1'}} onClick={handleCancel}>
  //                                             Cancel
  //                                           </button> */}
  //                                         </div>
  //                                       </div>
  //                                     </div>
  //                                   )}
  //                                   </div>
	// 		<input  id="datepicker1" class="cal2" name="Text" type="text" /*value="Departure date"*/ value={hotelFormValues.checkout} onfocus="this.value = '';" onblur="if (this.value == '') {this.value = 'Departure date';}" onClick={() => {setDepartCalendarVisible(!departCalendarVisible)}} required="" />
  //           <div className="two-month-range-calendar-container" style={{zIndex: '5'}}>
  //                                     {/* <h2>Two-Month Range Calendar</h2> */}
  //                                     {departCalendarVisible && (
  //                                     <div style={{ backgroundColor: "white" }}>
  //                                       <div
  //                                         className="range-calendar-container"
  //                                         style={{ position: "absolute" }}
  //                                       >
  //                                         {/* <h2>Range Calendar</h2> */}
  //                                         <DateRangePicker
  //                                           onChange={(e) => {
  //                                             handleOneWaySelect(e);
  //                                             setFormValues((prevState) => ({
  //                                               ...prevState,
  //                                               segment: [
  //                                                 {
  //                                                   ...prevState.segment[0],
  //                                                   travelDate: formatDate(
  //                                                     selectedOneDate.toLocaleDateString()
  //                                                   ),
  //                                                 },
  //                                               ],
  //                                             }));
  //                                           }}
  //                                           ranges={[
  //                                             {
  //                                               startDate: selectedOneDate,
  //                                               endDate: selectedOneDate,
  //                                               key: "selection",
  //                                             },
  //                                           ]}
  //                                           months={months}
  //                                           showSelectionPreview={true}
  //                                           moveRangeOnFirstSelection={false}
  //                                           direction={direction}
  //                                           minDate={today}
  //                                           maxDate={oneYearFromToday}
  //                                           isDayBlocked={isDayDisabled} // Disable past dates
  //                                           style={{
  //                                             width: "300px",
  //                                             height: "250px",
  //                                           }}
  //                                         />
  //                                         <div
  //                                           className="button-container"
  //                                           style={{
  //                                             backgroundColor: "white",
  //                                             display: "flex",
  //                                             flexDirection: "row-reverse",
  //                                             alignItems: "center",
  //                                             margin: "0px",
  //                                           }}
  //                                         >
  //                                           <button style={{zIndex: '5'}} onClick={handleApply}>
  //                                             Apply
  //                                           </button>
  //                                           {/* <button style={{zIndex: '1'}} onClick={handleCancel}>
  //                                             Cancel
  //                                           </button> */}
  //                                         </div>
  //                                       </div>
  //                                     </div>
  //                                   )}
  //           </div>
	// 		<input type="text"  class="hname" name="name" placeholder="Hotel name" value={hotelName} onChange={handleChangeHotelName} required="" />
  //     {/* <input type="text"  class="num" value={totalPassengers} required="" /> */}
  //     <input type="text"  class="gname cal2" name="name" placeholder="Hotel name" value={`${totalPassengers} Guest`}  required="" />
	// 		{/* <select class="num">
	// 			<option value="2 Guests">2 Guests</option>
	// 			<option value="3 Guests">3 Guests</option>
	// 			<option value="4 Guests">4 Guests</option>
	// 			<option value="more">More</option>
	// 		</select> */}
	// 		<input type="text"  class="fname" name="First name" placeholder="First name" value={firstName} onChange={handleChangeFirstName} required="" />
	// 		<input type="text"  class="lname" name="Last name" placeholder="Last name" value={lastName} onChange={handleChangeLastName} required="" />
	// 		<input type="text"  class="pnum" name="Phone number" placeholder="Phone number" value={phoneNumber} onChange={handleChangePhoneNumber} required="" />
	// 		<input type="text"  class="email" name="Email" placeholder="Email" value={email} onChange={handleChangeEmail} required="" />
	// 		<input type="submit" value="Send Enquiry" onClick={handleSubmit} />
	// 	{/* </form> */}
	// </div>
  //   <Footer />
	// {/* <div class="footer-w3l">
	// 	<p class="agileinfo"> &copy; 2024 Hotel Enquiry Form. All Rights Reserved | Design by <a href="#">W3layouts</a></p>
	// </div> */}
  //   </div>
  <div className='HForm' >
  <div className='container form-style'>
      <h1 className='mb-5 hed-ut'>Hotel Enquiry </h1>
     <form className="row ">
  <div className="col-lg-6 mb-4">
    <input type="text" className="form-control" placeholder="Arival Date" value={hotelFormValues.checkin} aria-label="Arival Date" />
    <div className="two-month-range-calendar-container" style={{zIndex: '5'}}>
                                       {/* <h2>Two-Month Range Calendar</h2> */}
                                       {calendarVisible && (
                                       <div style={{ backgroundColor: "white" }}>
                                         <div
                                           className="range-calendar-container"
                                          style={{ position: "absolute" }}
                                         >
                                           {/* <h2>Range Calendar</h2> */}
                                           <DateRangePicker
                                             onChange={(e) => {
                                               handleOneWaySelect(e);
                                               setFormValues((prevState) => ({
                                             ...prevState,
                                                 segment: [
                                                   {
                                                     ...prevState.segment[0],
                                                     travelDate: formatDate(
                                                       selectedOneDate.toLocaleDateString()
                                                     ),
                                                   },
                                                 ],
                                               }));
                                             }}
                                             ranges={[
                                               {
                                                 startDate: selectedOneDate,
                                                 endDate: selectedOneDate,
                                                 key: "selection",
                                               },
                                             ]}
                                           months={months}
                                             showSelectionPreview={true}
                                             moveRangeOnFirstSelection={false}
                                            direction={direction}
                                             minDate={today}
                                             maxDate={oneYearFromToday}
                                             isDayBlocked={isDayDisabled} // Disable past dates
                                             style={{
                                               width: "300px",
                                               height: "250px",
                                             }}
                                           />
                                           <div
                                             className="button-container"
                                             style={{
                                               backgroundColor: "white",
                                               display: "flex",
                                               flexDirection: "row-reverse",
                                               alignItems: "center",
                                               margin: "0px",
                                             }}
                                           >
                                             <button style={{zIndex: '5'}} onClick={handleApply}>
                                               Apply
                                             </button>
                                             {/* <button style={{zIndex: '1'}} onClick={handleCancel}>
                                               Cancel
                                             </button> */}
                                           </div>
                                         </div>
                                       </div>
                                     )}
                                     </div>
  </div>
  <div className="col-lg-6 mb-4">
    <input type="text" className="form-control" placeholder="Departure Date" value={hotelFormValues.checkout} aria-label="Departure Date" />
    <div className="two-month-range-calendar-container" style={{zIndex: '5'}}>
                                       {/* <h2>Two-Month Range Calendar</h2> */}
                                      {departCalendarVisible && (
                                      <div style={{ backgroundColor: "white" }}>
                                         <div
                                           className="range-calendar-container"
                                           style={{ position: "absolute" }}
                                         >
                                           {/* <h2>Range Calendar</h2> */}
                                         <DateRangePicker
                                             onChange={(e) => {
                                               handleOneWaySelect(e);
                                               setFormValues((prevState) => ({
                                                 ...prevState,
                                                 segment: [
                                                   {
                                                     ...prevState.segment[0],
                                                     travelDate: formatDate(
                                                       selectedOneDate.toLocaleDateString()
                                                     ),
                                                   },
                                                 ],
                                               }));
                                             }}
                                             ranges={[
                                               {
                                                 startDate: selectedOneDate,
                                                 endDate: selectedOneDate,
                                                 key: "selection",
                                               },
                                             ]}
                                             months={months}
                                             showSelectionPreview={true}
                                             moveRangeOnFirstSelection={false}
                                             direction={direction}
                                             minDate={today}
                                             maxDate={oneYearFromToday}
                                             isDayBlocked={isDayDisabled} // Disable past dates
                                             style={{
                                               width: "300px",
                                               height: "250px",
                                             }}
                                           />
                                           <div
                                             className="button-container"
                                             style={{
                                               backgroundColor: "white",
                                               display: "flex",
                                               flexDirection: "row-reverse",
                                               alignItems: "center",
                                               margin: "0px",
                                             }}
                                           >
                                            <button style={{zIndex: '5'}} onClick={handleApply}>
                                               Apply
                                             </button>
                                             {/* <button style={{zIndex: '1'}} onClick={handleCancel}>
                                               Cancel
                                             </button> */}
                                           </div>
                                         </div>
                                       </div>
                                     )}
             </div>
  </div>
  <div className="col-lg-6 mb-4">
    <input type="text" className="form-control" placeholder="Hotel  name" value={hotelName} onChange={handleChangeHotelName} aria-label="Hotel name" />
  </div>
  <div className="col-lg-6 mb-4">
  
  <input type="text" className="form-control" value={`${totalPassengers} Guest`} aria-label="Hotel name" />

  </div>
  <div className="col-lg-6 mb-4">
    <input type="text" className="form-control" placeholder="First name" value={firstName} onChange={handleChangeFirstName} aria-label="First name" />
  </div>
  <div className="col-lg-6 mb-4">
    <input type="text" className="form-control" placeholder="Last name" value={lastName} onChange={handleChangeLastName} aria-label="Last name" />
  </div>
  <div className="col-lg-6 mb-4">
    <input type="text" className="form-control" placeholder="Phone Number" value={phoneNumber} onChange={handleChangePhoneNumber} aria-label="Phone Number" />
  </div>
  <div className="col-lg-6 mb-4">
    <input type="email" className="form-control" placeholder="Email" value={email} onChange={handleChangeEmail} aria-label="Email" />
  </div>
  <div class="col-lg-12">
    <button type="submit" class="btn btn-primary py-2 px-4 dubt" onClick={handleSubmit}>Submit</button>
  </div>
</form>
     </div>
  </div>
  )
}
