// import React, { useState } from 'react';

// const CookieNotice = () => {
//   const [accepted, setAccepted] = useState(false);

//   const handleAccept = () => {
//     // Perform actions when the user accepts the cookies (e.g., set a cookie consent flag)
//     // For example: document.cookie = 'cookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';
//     document.cookie = 'cookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';
//     setAccepted(true);
//   };

//   const handleLearnMore = () => {
//     // You can define the URL for your cookie policy page or information here
//     window.open('/cookie-policy', '_blank');
//   };

//   if (accepted) {
//     return null; // If cookies are accepted, don't display the notice
//   }

//   return (
//     <div className="cookie-notice">
//       Our site uses cookies so we can provide you with the best possible web experience.{' '}
//       <a href="#/" onClick={handleLearnMore}>
//         Click here to learn more about how we use cookies.
//       </a>{' '}
//       <button onClick={handleAccept}>Accept</button>
//     </div>
//   );
// };

// export default CookieNotice;

import React, { useState, useEffect } from 'react';
import '../resources/css/Cookies.css'

const CookieNotice = () => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem('cookieConsent');
    if (isAccepted) {
      setAccepted(true);
    }
  }, []);

  const handleAccept = () => {
    // Set a flag or value to remember the user's choice
    localStorage.setItem('cookieConsent', 'true');
    setAccepted(true);
  };

//   const handleLearnMore = () => {
//     // You can define the URL for your cookie policy page or information here
//     window.open('/cookie-policy', '_blank');
//   };

  if (accepted) {
    return null; // If cookies are accepted, don't display the notice
  }

  return (
    <div className="cookie-notice">
      We use cookies for safer and more dynamic experiences for users. Click here to explore detailed information on the usage of cookies.{' '}
      {/* <a href="#/" onClick={handleLearnMore}>
        Click here to learn more about how we use cookies.
      </a>{' '} */}
      <button className='accept' onClick={handleAccept}>Accept</button>
    </div>
  );
};

export default CookieNotice;

