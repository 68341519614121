/* eslint-disable no-unused-vars */
import React from 'react';
import '../resources/css/postTicket.css'
import Footer from '../components/Footer/footer';
import { Link } from 'react-router-dom';
import MetaTitle from './metaTitle';
import SITE_CONFIG from '../SiteController/SiteController';




const styles = {
  blogHead: {
    alignItems: 'center',
    backgroundColor: '#00214266',
    backgroundImage: 'url()',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    color: '#fff',
    display: 'flex',
    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif',
    fontSize: '40px',
    height: '200px',
    justifyContent: 'center',
    textAlign: 'center',
    textShadow: '1px 2px 2px #222',
    width: '100%',
  }
};

export default function PostTicketing() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
    <>
    <MetaTitle url={`${apiIP}/taxes-and-fees`} pageTitle="Post Ticketing Fee - Rawfares" pageDescription="Find and cross-check Post Ticketing Fees, Cancellation & Refunds, and Special Services on Rawfares." />
    <body>
   
    
{/* <style type="text/css">
.blog-head { background-image:url('/resources/images/blog-body.jpg');background-repeat:no-repeat;background-attachment: fixed;background-size:100% 100%; }
</style> */}

    {/* <!--Header Ends Here--> */}
    <div class="blog-head" style={styles.blogHead}>
        <span>Post Ticketing Fees</span>
    </div>
    <div style={{backgroundColor: '#ffffff'}}>
    <div class="aboutus">
	<h3>OUR POST-TICKETING FEES</h3>
	<table width="100%" border="1" cellspacing="0" cellpadding="0">
		
		<tr>
            <td width="39%" style={{textAlign: 'center', padding: '10px'}}>Applies To </td>
            <td width="33%" style={{textAlign: 'center', padding: '10px'}}>Applied For </td>
            <td width="28%" style={{textAlign: 'center', padding: '10px'}}>Amount per Ticket </td>
        </tr>
        <tr>
            <td rowspan="3" style={{textAlign: 'left', padding: '10px'}}>Agent Assisted Cancellation</td>
            <td style={{textAlign: 'left', padding: '10px'}}>Cancellation within 4 Hours of booking and Prior to Midnight of the booking date </td>
            <td style={{textAlign: 'center', padding: '10px'}}>Free of Charge </td>
        </tr>
        <tr>
            <td style={{textAlign: 'left', padding: '10px'}}>Cancellation after 4 hours of booking but Prior to Midnight </td>
            <td style={{textAlign: 'center', padding: '10px'}}>$100 Per Booking </td>
        </tr>
		
		<tr>
            <td style={{textAlign: 'left', padding: '10px'}}>Cancellation after Midnight and within 24 hours of booking (USA websites Only) </td>
            <td style={{textAlign: 'center', padding: '10px'}}>$100 Per Booking </td>
        </tr>	  
    </table>
	{/* <!--next table--> */}
	<br style={{height: '15px'}} />
	
	<h3>Cancellation & Refunds (beyond 24 hrs)</h3>
	<table width="100%" border="1" cellspacing="0" cellpadding="0">
		
		
        <tbody>
		<tr>
            <td width="39%" rowspan="2" style={{textAlign: 'left', padding: '10px'}}>Conditions Applied to </td>
            <td colspan="2" style={{textAlign: 'center', padding: '10px'}}>Air - Economy </td>
            <td colspan="2" style={{textAlign: 'center', padding: '10px'}}>Air - Business/First </td>
        </tr>
        <tr>
            <td width="17%" style={{textAlign: 'center', padding: '10px'}}>Domestic </td>
            <td width="12%" style={{textAlign: 'center', padding: '10px'}}>International </td>
			<td width="14%" style={{textAlign: 'center', padding: '10px'}}>Domestic </td>
            <td width="18%" style={{textAlign: 'center', padding: '10px'}}>International </td>
            
        </tr>
		
		<tr>
            <td style={{textAlign: 'left', padding: '10px'}}>Agent assisted cancellation 1st w/Future Credit </td>
            <td style={{textAlign: 'center', padding: '10px'}}>$100 </td>
            <td style={{textAlign: 'center', padding: '10px'}}>$200 </td>
			<td style={{textAlign: 'center', padding: '10px'}}>$300 </td>
            <td style={{textAlign: 'center', padding: '10px'}}>$300 </td>
        </tr>
		
		<tr>
            <td style={{textAlign: 'left', padding: '10px'}}>Agent assisted cancellation 2nd w/Refund </td>
            <td style={{textAlign: 'center', padding: '10px'}}>$200 </td>
            <td style={{textAlign: 'center', padding: '10px'}}>$300 </td>
			<td style={{textAlign: 'center', padding: '10px'}}>$500 </td>
            <td style={{textAlign: 'center', padding: '10px'}}>$500 </td>
        </tr>
		
		<tr>
            <td style={{textAlign: 'center', padding: '10px'}}>&nbsp; </td>
            <td style={{textAlign: 'center', padding: '10px'}}>&nbsp; </td>
            <td style={{textAlign: 'center', padding: '10px'}}>&nbsp; </td>
			<td style={{textAlign: 'center', padding: '10px'}}>&nbsp; </td>
            <td style={{textAlign: 'center', padding: '10px'}}>&nbsp; </td>
        </tr>
			
		<tr>
            <td colspan="5" style={{textAlign: 'left', padding: '10px'}}>Changes to existing tickets(Exchange) </td>
        </tr>
			
		<tr>
      <td width="39%" style={{textAlign: 'left', padding: '10px'}}>Within 4 hours</td>
      <td width="16%" style={{textAlign: 'center', padding: '10px'}}>$25</td>
      <td width="18%" style={{textAlign: 'center', padding: '10px'}}>$50</td>
      <td width="11%" style={{textAlign: 'center', padding: '10px'}}>$50</td>
      <td width="16%" style={{textAlign: 'center', padding: '10px'}}>$50</td>
    </tr>
    <tr>
      <td style={{textAlign: 'left', padding: '10px'}}>Within 10 days of new travel date</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$200</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$250</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$500</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$500</td>
    </tr>
    <tr>
      <td style={{textAlign: 'left', padding: '10px'}}>Beyond 10 days of new travel date</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$200</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$250</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$500</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$500</td>
    </tr>	
		
		
    </tbody>
	
	</table>
	
	<br style={{height: '15px'}} />
	
	<h3>Special Services</h3>
	<table width="100%" border="1" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td width="39%" style={{textAlign: 'left', padding: '10px'}}>Agent Assisted Refund or assistance with obtaining a Future Airline Credit Due any of possible reasons (Our Service fees are applicable upon success, but do not include airline fees)</td>
      <td width="34%" style={{textAlign: 'center', padding: '10px'}}>Passenger Name Misspelling</td>
      <td width="27%" style={{textAlign: 'center', padding: '10px'}}>$50</td>
    </tr>
    <tr>
      <td style={{textAlign: 'center', padding: '10px'}}>&nbsp;</td>
      <td style={{textAlign: 'center', padding: '10px'}}>Visa/Passport Services</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$50</td>
    </tr>
    <tr>
      <td style={{textAlign: 'center', padding: '10px'}}>&nbsp;</td>
      <td style={{textAlign: 'center', padding: '10px'}}>Baggage Related Service</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$25</td>
    </tr>
    <tr>
      <td style={{textAlign: 'left', padding: '10px'}}>&nbsp;</td>
      <td style={{textAlign: 'center', padding: '10px'}}>Baggage Related Service</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$25</td>
    </tr>
    <tr>
      <td style={{textAlign: 'left', padding: '10px'}}>&nbsp;</td>
      <td style={{textAlign: 'center', padding: '10px'}}>No-Show Services</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$25</td>
    </tr>
    <tr>
      <td style={{textAlign: 'left', padding: '10px'}}>&nbsp;</td>
      <td style={{textAlign: 'center', padding: '10px'}}>Denied Boarding Services</td>
      <td style={{textAlign: 'center', padding: '10px'}}>$25</td>
    </tr>
  </tbody>
</table>
	
	<p>We offer Special Discounts under our CEP Policy. CEP Policy covers the Military, Senior Citizens (over 64 years), Natural Disasters, Bereavement, Youth (between 16 and 25 years), terminally ill, and people with Disabilities. </p>
	
	<p>All Airline Refunds/Future Credits are subject to each airline's fare rules, <a href="/">policies</a>, and procedures Service fees will be converted into your local currency on the payment page. Passenger types = Adult, child, senior, infant, student, military. All post-ticketing service fees are non-refundable and are subject to change without notice. <a href="/"> rawfares.com</a> fees, additional fares collected, and other charges are in addition to any airline, hotel, or car rental fees and charges.</p>
	
	<p>You will only be charged the final total amount. Government-imposed taxes and fees are subject to change. You will only be charged the final total amount as shown.</p>
	
	<ul style={{paddingLeft: '12px'}}>
	<li>Most of our airline tickets are non-refundable. Only available if our Travel Suppliers fare rules allow cancellation and refunds, and we have accepted your request for a refund, you are not a "no show" (most "no show" bookings are ineligible for any waiver from suppliers for refund processing), and if we are able to secure waivers from suppliers to process this requested cancellation and refund.</li>
		
	<li>Airline Refunds/Future credits are subject to airline fare rules, policies, and procedures.</li>
		
	<li>Special Services - All Services noted are on a Request Basis ONLY and are subject to each Airline's review and approval process along with their fare rules, policies, and procedures. Name Misspelling - The passenger name on their airline ticket does not match their passport or other universally accepted government ID Visa/Passport - A Visa/Passport decline letter is normally required in order to process a request <Link to="/airline-baggage-fee">Baggage</Link> - please retain all receipts and baggage tags No- Show - Documentation advising why you were unable to make your scheduled departure will be required Denied Boarding - Documentation as why you were denied boarding of your scheduled departure will be required Duplicate Tickets - Copies of all tickets, reflecting exact same itineraries booked with us will be required in order to process a refund request.</li>
	</ul>
	
	
	
	</div>
    </div>
    <Footer />
</body>
</>
  )
}
