import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MetaTitle from './metaTitle';
import '../resources/css/contact-style.css';
import Footer from '../components/Footer/footer';
import SITE_CONFIG from '../SiteController/SiteController';
//import background from "../resources/images/search_banner/Homepage-Main-banner.png";

export default function Privacy() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
    <>
    {/* <Helmet>
        <title>About us - RawFares</title>
        <meta name="description" content="This is About page of www.rawfares.com" />
        {/* Other meta tags 
        <link rel="canonical" href={`${apiIP}/about`} />
        {/* Additional meta tags for homepage 
      </Helmet> */}
    <MetaTitle pageTitle='Privacy Policy - RawFares' pageDescription="RawFares takes pride in being your gateway to major destinations and the off-the-beaten-path gems that await your discovery." url={`${apiIP}/privacy-policy`} />
    <div class="container-fluid" /*style={{marginTop: '75px'}}*/>
			
			<div class="row">
			<div class="content_page_title1" style={{marginTop: '75px'}}>
			<div class="container">
			<h1>Privacy Policy </h1>
			</div>
			</div>
				
		<div class="textBox">
        <div class="container">
          <div class="row">
			<div class="content-wrap">
                <p>We respect your privacy and our privacy policy is designed to help in understanding the collection, use,
and also safeguarding of information you may give to RawFares, along with this to review and revise the
information you provide. Kindly visit this website, and also you will accept the practices that have been
described herein.</p>
                
				<div class="clearfix"></div>
				
				{/* <div class="right_image">
					<img src="resources/images/about-img1.png" style={{width: '100%'}} alt="" />
				  </div> */}
                <h3>Details of Personal Information as is collected by us</h3> 
                 <p> We always store and keep a copy of the information that you enter or you give us information through
other mediums while using our website. The information is all used to identify individuals and consists of
the first and last of the traveler, your contact number, your postal number, your email address, and
billing details, and many more. We may also request you to enter the information related to hotel room
preference, frequent flyer, and car rental program details. On the other hand, sometimes you deny to
enter your detailed information to us, in that case, we ask to give basic information about you, which is
required for you to enter in the register, for being a member with us in order to complete the travel
profile, to involve in a contest, survey, or sweepstakes or raising the question to us, or making other
transaction through our website.</p> 
                    <p>When you book a reservation for someone through the website, we may request you for a piece of
information as well as a traveling preference of that individual(s). You must seek consent from them
prior to providing us their personal details and travel preferences as they can assess their account to
view or modify their information as needed.</p>
<p>We can also obtain both personal and non-personal information about you every so often from business
partners, Affiliated Entities, or other different sources to merge with our account details. The
information which we obtain is included of updated delivery and address detail, purchase history, and
demographic information. On the basis of privacy setting, and that of your friend, we can use the
information which is provided by you to share on social media in regards to your specific location for
providing the correct content. We are also able to collect some of your information, whenever you visit
our official website. For example, we will collect your IP Address, detail of the web browser, and the
referring website. We are able to collect information about your online activity, such as the road trip
viewed, and all related to the booking. The reason for collecting information automatically is to
customize your experience and to prevent any fraudulent activity.</p>
				  <h3>How we use your Personal Information</h3>
                <p>
                We use your personal information, for sensitive billing information like cardholder name, CC number,
and the expiry date in order to place the travel booking which you have proposed over our website. We
also use your information, to provide product and services that you requested to provide you with travel
confirmation, and updates as well, we properly manage your account to provide you with the processing
bills, notification related to travel, to speak to you over the phone, to answer your question with the
relevant information, to compute interest in, and to enhance our product, service and the website,
notifying you about the special offer or deals, notifying you for products and service which may you get
interested of, customize the usage of the website, for rewarding you for any of such programs that you
select to join, to seek information from you, it includes a survey in regards to for resolving disputes,

collect fees and solve the problems, for inhibiting fraudulent/illegal activity, to enforce the Terms and
conditioned delineate by us although it has been explained to you at the collection point.</p>
                <p>We endeavor to make it easy for you to enjoy the benefit of travel-related opportunities which are
available on our website. In order to make you aware of the same, we sent email messages to you,
which consist of all the detailed information related to travel-related interests. For instance, if you
search for a flight for New York (NYC) on our website, and you have saved your itinerary detail or you
have not booked a flight with us, then we may send you a message or email for reminding you about the
safe travel itinerary or about a special fair to NYC. We believe their email messages will help you with
useful information regarding travel-related special offers which is available on our website.</p>
<p><b>Note :-</b>It is important to know that you can opt not to receive email messages in any such email which is
sent by us.</p>
				  
				  <h3>With whom all we share your details</h3>
                <p>
                By making reservations through this website, you allow us to disclose to our suppliers the information
which is required for completing the booking and deliver the best-associated travel services. These kinds
of suppliers do not come under control by RawFares and individual information which is disclosed to
them is subjected to applicable supplier’s policy and security practices. Therefore, we appreciate you
reviewing the privacy policy(ies) of any of the travel supplier products which you have purchased
through the website. Independent suppliers who provide services/functions on our behalf are included
in CC processing, customer service, business analytics, marketing, survey distribution, and also fraud
prevention. We sometimes give authority to independent suppliers for collecting details on our behalf,
which is necessary to operate our website features, or facilitating the online advertisement delivery that
is customized per your interest. These independent suppliers have the right to collect necessary
information only when required to depict their functions only, also they are not allowed to use the
information for any other purpose. They too have to opt for security practices that we adhere to on our
own. A business partner who has collaborated with us and to whom we offer products and services
jointly, and whose product and service are offered through our website. You can be intimate with us If
any individual entity is involved with our product and service, which you have requested because it is
visible either with our band name or all alone. If you wish to use these services, which are completely
optional, we can share details about you such as a personal detail. Please take note that we do not
control the privacy policy of this independent business.</p>
                <p>You may also be directed from this website to the other website as well, also we may share your details
regarding the routing website too. You have not been restricted to the limit regards to the referring
website, usage of personal details, we would also appreciate you for you review their privacy policies.</p>
<p>We may share personal details with our corporate affiliates whenever the requirement is there for the
same. This sharing also authorizes us to provide essential information regarding products and services,
both related to travel and the one which is not, which might be of interest to you. The company and
corporates who are associated with us, up to an extent can access your personal details whereas they
will use the personal information by following the practices which have been described in the document
as well. They are also under compliance along with all the laws which are governed by the government
and you will be provided with an option, to opt-out from commercial email in the future.</p>
<h3>We also may share your Information</h3>
<p>We believe to initiate a proper investigation, prevent or take steps regarding illegal/suspected illegal
activities, for protecting the rights, property, or security of our company or for this website, our
customer or other, and also in accordance with our Terms and Conditions and other restricted
agreements. Along with some corporate transactions such as mergers, consolidate, divestiture, sale of
assets, or unconvincing bankruptcy events.</p>
<p>On the other hand, whatsoever is described above you will be given notification, whenever your
personal information is being shared with independent entities, you also have an option to opt for not
to disclose or share your personal details in future. We may share unacknowledged information with
advertisers, investors, and independent entities. For example, you can tell our advertiser about the total
number of visitors who usually visit our website. This kind of information does not contain personal
detail with it and it can only be used to develop content</p>
<h3>FURTHER QUESTIONS</h3>
<p>If in case, you have any concern or question regarding the Privacy Policy or the cancellation and the
information submitted to RawFares.</p>
<h3>Please direct your inquiries: +1-888-415-1536</h3>
<p>Our Privacy Policy is governed by and construed in accordance with the State of law, other than such
rules, regulations, case law that would result in the application of the law of the jurisdiction other than
the state of the United States. If any part of the Privacy Policy is determined to be unenforceable/invalid
pursuant to applicable law, then the unenforceable/invalid provision will be deemed superseded by a
valid. an enforceable contract closely matches the intent of the original provision and the remainder of
the Privacy Policy will continue in force and effect.</p>
              </div>	
          </div>
        </div>
      </div>
				
			
				
		      </div>
				
			</div> 
      <Footer />
      </>
  )
}