// import {
//     useCollect,
//     CardNumberInput,
//     CardExpirationInput,
//     CheckNameInput,
//     CardCVVInput,
//     CheckAccountInput,
//     CheckRoutingNumberInput,
//     ValidationContainer,
//     ValidationContainerFields

// } from "@lydiagoulding/react-nmi-collectjs";
// import '../resources/css/card-style.css';
// import { useCallback, useState } from "react";

// export default function PaymentFormNMI() {
//     const [ collect, response, reset ] = useCollect({
//         validCss: {
//             "color": "green",
//             "background-color": "cyan"
//         }
//     });
//     const onSubmit = useCallback((e) => {
//         e.preventDefault();
//         collect.startPaymentRequest();
//     }, [collect]);

//     const [tab, setTab] = useState('credit');

//     const onRestart = useCallback((e) => {
//         e.preventDefault();
//         reset();
//     }, [reset]);

//     return (<form style={{
//         'maxWidth': '500px',
//         'margin': "0 auto"
//     }}>
//         <div>
//             <button onClick={(e) => {
//                 e.preventDefault();
//                 setTab('credit')
//                 collect.configure();
//             }}
//             >
//                 CreditCard
//             </button>
//             <button onClick={(e) => {
//                 e.preventDefault();
//                 setTab('check');
//                 collect.configure();
//             }}>
//                 Checks
//             </button>
//         </div>
//         {tab === 'credit' && (
//             <>
            
//                 <ValidationContainer validationFields={[ValidationContainerFields.ccNumber]}  >
//                 {({isValid, errors}) => (
//                             <label>
//                                 Credit Card Number
//                                 {isValid ? (
//                                     <span>Valid!</span>
//                                 ) : (
//                                     <ul>
//                                         {errors.map(error => (
//                                             <li>{error.message}</li>
//                                         ))}
//                                     </ul>
//                                 )}
//                                 <CardNumberInput class="form-control fntinpt pay-type"/>
//                             </label>
//                         )
//                     }
//                 </ValidationContainer>
                
                    

//                 <label>
//                     Expiration
//                     <CardExpirationInput className="form-control fntinpt" />
//                 </label>
//                 <label>
//                     CVV
//                     <CardCVVInput/>
//                 </label>
//             </>
//         )}

//         {tab === 'check' && (
//             <>
//                 <label>
//                     Routing
//                     <CheckNameInput/>
//                 </label>
//                 <label>
//                     Account Number
//                     <CheckAccountInput/>
//                 </label>
//                 <label>
//                     Name
//                     <CheckRoutingNumberInput/>
//                 </label>
//             </>
//         )}

//         <button onClick={onSubmit}>Submit</button>
//         <div>
//             {JSON.stringify(response)}
//         </div>
//         <button onClick={onRestart}>Restart</button>
//     </form>);
// }









import {
    useCollect,
    CardNumberInput,
    CardExpirationInput,
    CheckNameInput,
    CardCVVInput,
    CheckAccountInput,
    CheckRoutingNumberInput,
    ValidationContainer,
    ValidationContainerFields
} from "@lydiagoulding/react-nmi-collectjs";
import '../resources/css/card-style.css';
import { useCallback, useState } from "react";
import axios from 'axios';

export default function PaymentFormNMI() {
    const [collect, response, reset] = useCollect({
        validCss: {
            "color": "green",
            "background-color": "cyan"
        }
    });
    const [tab, setTab] = useState('credit');
    const [amount, setAmount] = useState('');
    const [message, setMessage] = useState('');

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        try {
            const { token, errors } = await collect.startPaymentRequest();
            if (errors) {
                setMessage('Failed to tokenize card details');
                console.log('Failed to tokenize card details')
                return;
            }
            if (token) {
                const response = await axios.post('http://localhost:5000/api/payment', {
                    amount,
                    token
                });
                setMessage('Payment Successful');
            } else {
                setMessage('Failed to tokenize card details');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Payment Failed');
        }
    }, [collect, amount]);

    const onRestart = useCallback((e) => {
        e.preventDefault();
        reset();
    }, [reset]);

    return (
        <form style={{
            'maxWidth': '500px',
            'margin': "0 auto"
        }}>
            <div>
                <label>Amount</label>
                <input
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
            </div>
            <div>
                <button onClick={(e) => {
                    e.preventDefault();
                    setTab('credit');
                    collect.configure();
                }}>
                    CreditCard
                </button>
                <button onClick={(e) => {
                    e.preventDefault();
                    setTab('check');
                    collect.configure();
                }}>
                    Checks
                </button>
            </div>
            {tab === 'credit' && (
                <>
                    <ValidationContainer validationFields={[ValidationContainerFields.ccNumber]}>
                        {({ isValid, errors }) => (
                            <label>
                                Credit Card Number
                                {isValid ? (
                                    <span>Valid!</span>
                                ) : (
                                    <ul>
                                        {errors.map(error => (
                                            <li key={error.message}>{error.message}</li>
                                        ))}
                                    </ul>
                                )}
                                <CardNumberInput className="form-control fntinpt pay-type" />
                            </label>
                        )}
                    </ValidationContainer>
                    <label>
                        Expiration
                        <CardExpirationInput className="form-control fntinpt" />
                    </label>
                    <label>
                        CVV
                        <CardCVVInput />
                    </label>
                </>
            )}
            {tab === 'check' && (
                <>
                    <label>
                        Routing
                        <CheckNameInput />
                    </label>
                    <label>
                        Account Number
                        <CheckAccountInput />
                    </label>
                    <label>
                        Name
                        <CheckRoutingNumberInput />
                    </label>
                </>
            )}
            <button onClick={onSubmit}>Submit</button>
            <div>
                {message}
            </div>
            <button onClick={onRestart}>Restart</button>
        </form>
    );
}
