import React from 'react'

export default function footer2() {
  return (
    <>
    <div className="copyright-block">
        <div className="container">
        <p style={{textAlign: "center ", color: 'white', fontSize: '13px'}}>Rawfares is represented by Holiay Breakz Group LLC which is registered in 5830 N Valentine Ave #102, Fresno, CA 93711 USA<br/>Rawfares.com is an independent travel portal with no third party association. By using Rawfares.com, you agree that Rawfares is not accountable for any loss - direct or indirect, arising of offers, materials or links to other sites found on this website. In case of queries, reach us directly at our Contact Number +1-888-415-1536 or, simply email at support@rawfares.com</p>
            <div className="copyright"> Copyrights &copy; 2022-2024 rawfares.com, All Rights Reserved. </div>
        </div>
    </div>
    <div class="visible-xs">
    <div class="bottom-call-bar2" style={{bottom: '0px', fontFamily: 'Arial, Helvetica, sans-serif'}}>    
    <a id="a_contactNo" href="tel:+1-888-415-1536">
      <div class="call-text">Call &amp; Get Unpublished Flight Deals!</div>
      <div class="PH_contactNo2">+1-888-415-1536</div>
      </a> </div>
  </div>
  </>
  )
}
