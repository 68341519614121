/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../resources/css/airline-style.css';
//import backTab from '../resources/images/tabsbg.png';
import tabsArrow from '../resources/images/tabsarow.png'
import Arror from '../resources/images/icons/arowimg.png'
import Searchbar from '../Shared/Searchbar';
import Footer from '../components/Footer/footer';
import MetaTitle from './metaTitle';
import DealsGlobe from '../resources/images/globepine.png';
import SITE_CONFIG from '../SiteController/SiteController';



function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat('en-US', dateOptions).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat('en-US', timeOptions).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

export default function DestinationDetails() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  const location = useLocation();
  const { response1, response2 } = location.state || {};
  ////console.log(location.state)

  const { airlineName } = useParams();

// Split the airlineName using the hyphen separator to get individual parts
const parts = airlineName.split('-');

// Get the last part as the URL
const pageValue = parts.pop();

// Get the remaining parts as the Page Value
const url = parts.join('-');
  const [firstResponseData, setFirstResponseData] = useState(response1?.response[0] || null);
  const [secondResponseData, setSecondResponseData] = useState(response2 || null);
  const [reloadData, setReloadData] = useState({
    pageType: "City",
    pageValue: pageValue,
    siteId: siteID,
    url: url
  })

  const fetchData = async () => {
    
	////console.log(reloadData)
		try {
		  let response;
		  if (!response1) {
			response = await axios.post(`${apiIP}:9090/api/Airlines-Details`, reloadData);
			// Set firstResponseData state with the response if response1 is null
			if (response.status === 200) {
			  setFirstResponseData(response.data.response[0]);
			  ////console.log("Response from the first API:", response.data);
			  ////console.log(deals)
			  const response2 = await axios.post(`${apiIP}:9191/api/Airlines-Routes`,
			  { froCity:"",
			  toCity:"",
			  airline: "",
			  cabinClass:"Y",
			  tripType:"2",
			  page:"",
			  currency:"USD",
			  siteId: siteID,
			  departDate:"",
			  countryName:"US",
			  countryCode:"US",
			  cityName:"" }
			  );
			  setSecondResponseData(response2.data);
			  ////console.log("responswe23",response2);
			}
				
				
		  } else {
			// Use the existing response1 if available
			setFirstResponseData(response1.response[0]);
		  }
		  ////console.log(response);
		  // Update state with response data if needed
		} catch (error) {
		  ////console.error('Error fetching data:', error);
		  // Handle errors appropriately
		}
	  };
	
	  useEffect(() => {
		// Check if response1 or response2 is null, then call fetchData
		if (!response1 || !response2) {
		  fetchData();
		}
	  }, [url, pageValue, response1, response2]);

  const [selectedFlight, setSelectedFlight] = useState();
  useEffect(() => {
    if (secondResponseData && secondResponseData.response.length > 0) {
      setSelectedFlight(secondResponseData.response[0]);
    }
  }, [secondResponseData]);

  const handleFroCityClick = (item) => {
    setSelectedFlight(item);
  };

  const imageNames = ['albuquerque.webp', 'amsterdam.webp','atlanta.webp', 'chicago.webp', 'los-angeles.webp', 'mexico-city.webp', 'miami.webp', 'new-york.webp', 'san-francisco.webp', 'washington.webp'];
    const filteredImages = imageNames
    .filter(name => name !== `${url}.webp`)
    .slice(0, 3); // Limiting to 3 images

    const imageNameToAirline = {
      'albuquerque.webp': 'albuquerque',
      'amsterdam.webp': 'amsterdam',
      'atlanta.webp': 'atlanta',
      'chicago.webp': 'chicago',
      'los-angeles.webp': 'los-angeles',
      'mexico-city.webp': 'mexico-city',
      'miami.webp': 'miami',
      'new-york.webp': 'new-york',
      'san-francisco.webp': 'san-francisco',
      'washington.webp': 'washington'
    };
    const imageNameToAirlineCode = {
      'albuquerque.webp': 'abq',
      'amsterdam.webp': 'ams',
      'atlanta.webp': 'atl',
      'chicago.webp': 'ord',
      'los-angeles.webp': 'lax',
      'mexico-city.webp': 'mex',
      'miami.webp': 'mia',
      'new-york.webp': 'nyc',
      'san-francisco.webp': 'sfo',
      'washington.webp': 'was'
    };

    const handleSideClick = (pageValue, url) => {
      setReloadData({
        ...reloadData,
        pageValue,
        url,
      });
    };

    const getAirlineCode = (name) => {
      // Define a mapping of airline names to their respective codes
      const airlineCodes = {
        "Albuquerque": "ABQ",
        "Washington": "was",
        "New York": "NYC",
        "San Francisco": "SFO",
        "Miami": "MIA",
        "Mexico City": "MEX",
        "Chicago": "ord",
        "Los Angeles": "lax",
        "Spirit Airlines": "NK"
        // Add more airline names and codes as needed
      };
    
      return airlineCodes[name];
    };
    
    // Function to create a URL with the airline code appended to the airline name
    const createURL = (name) => {
      const airlineCode = getAirlineCode(name);
      return `https://www.rawfares.com/flights-to/${name.toLowerCase().replace(/\s+/g, '-')}-${airlineCode.toLowerCase()}`;
    };

	const [airlines, setAirlines] = useState({
        pageType: "Airline",
        pageValue: "",
        siteId: siteID,
        url: ""
      });

      const [deals, setDeals] = useState({
        froCity:"",
        toCity:"",
        airline: airlines.pageValue,
        cabinClass:"Y",
        tripType:"2",
        page:"",
        currency:"USD",
        siteId: siteID,
        departDate:"",
        countryName:"US",
        countryCode:"US",
        cityName:""
    });
      //const [responseData, setResponseData] = useState(null);
      //const navigate = useNavigate();
      useEffect(() => {
        // Use a useEffect to make the API call whenever airlines state changes
        if (airlines.pageValue && airlines.url) {
          handleAirlineClicked();
        }
      }, [airlines]);
    
//       const handleAirlineClicked = async () => {
//         document.body.style.overflowY = 'hidden';
//       const homeBody = document.querySelector('.HomeBody');
// if (homeBody) {
//   const elementsToBlur = homeBody.querySelectorAll(':not(.loader-overlay)');
//   elementsToBlur.forEach((element) => {
//     element.style.filter = 'blur(8px)';
//   });
// }
//       setIsLoading(true)
//       //console.log(airlines);
//       //console.log(deals)
//         try {
//           const response1 = await axios.post(
//             "http://localhost:9090/api/Airlines-Details",
//             airlines
//           );
      
//           if (response1.status === 200) {
//             setResponseData(response1.data);
//             //console.log("Response from the first API:", response1.data);
//             //console.log(deals)
//             const response2 = await axios.post("http://localhost:9191/api/Airlines-Routes", deals);
      
//             if (response2.status === 200) {
//               const Data = {
//                 response1: response1.data,
//                 response2: response2.data
//               };
//               //console.log("Response from the Second API:", response2.data);
//               const encodedData = encodeURIComponent(JSON.stringify(Data));
//   const navigationPath = airlines.pageValue.length === 2
//     ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
//     : `/flights-to/${airlines.url}-${airlines.pageValue}`;

// //   const newTab = window.open(navigationPath + `?data=${encodedData}`, '_blank');
// //   if (newTab) {
// //     newTab.focus();
// //   } else {
// //     //console.error('Failed to open new tab. Please check your browser settings or extensions.');
// //   }

//   //setIsLoading(false);

//               navigate(navigationPath, {
//                 state: Data
//               });
//             document.body.style.overflowY = 'none';
//       const homeBody = document.querySelector('.HomeBody');
// if (homeBody) {
//   const elementsToBlur = homeBody.querySelectorAll(':not(.loader-overlay)');
//   elementsToBlur.forEach((element) => {
//     element.style.filter = 'none';
//   });
// }
//             setIsLoading(false)
//             } else {
//               //console.error("Request to the second API failed with status:", response2.status);
//             }
//           } else {
//             //console.error("Request to the first API failed with status:", response1.status);
//           }
//         } catch (error) {
//           //console.error("Error making one of the API requests:", error);
//         }
//       };

const handleAirlineClicked = async () => {
//     setIsLoading(true);
//     try {
//       const response1 = await axios.post("http://localhost:9090/api/Airlines-Details", airlines);

//       if (response1.status === 200) {
//         setResponseData(response1.data);
//         // Handle response1 data as needed
//         const response2 = await axios.post("http://localhost:9191/api/Airlines-Routes", deals);

//         if (response2.status === 200) {
//           // Handle response2 data as needed
          
//           const Data = {
//             response1: response1.data,
//             response2: response2.data
//           };
//           openNewTabWithData(Data);
//           setIsLoading(false);
//         } else {
//           //console.error("Request to the second API failed with status:", response2.status);
//         }
//       } else {
//         //console.error("Request to the first API failed with status:", response1.status);
//       }
//     } catch (error) {
//       //console.error("Error making one of the API requests:", error);
//     }
//   };

//   const openNewTabWithData = (Data) => {
    const navigationPath = airlines.pageValue.length === 2
      ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
      : `/flights-to/${airlines.url}-${airlines.pageValue}`;

    const newTab = window.open(navigationPath, '_blank');
    if (newTab) {
        ////console.log('Received data in new tab:');
      newTab.onload = function() {
        newTab.postMessage(window.location.origin);
      };
    } else {
      ////console.error('Failed to open new tab. Please check your browser settings or extensions.');
    }

    // Navigate to the new page with data passed via state
    //navigate(navigationPath);
  };
      
      
    
      const handleCardClick = (pageType, pageValue, url) => {
        // Set the new values for airlines based on the card clicked
        setAirlines({
          ...airlines,
          pageType,
          pageValue,
          url,
        });
      };

      const standardizeAirlineName = (name) => {
        // Define a mapping of variations to a standard name
        const nameVariations = {
          'albuquerque': 'Albuquerque',
          'amsterdam': 'Amsterdam',
          'atlanta': 'Atlanta',
          'chicago': 'Chicago',
          'los-angeles': 'Los Angeles',
          'mexico-city': 'Mexico City',
          'miami': 'Miami',
          'new-york': 'New York',
          'san-francisco': 'San Francisco',
          'washington': 'Washington'
          // Add more variations as needed
        };
      
        return nameVariations[name] || name; // If a variation is found, return the standardized name, otherwise return the original name
      };
      
      // Apply the standardization to create an array of standardized airline names from filteredImages
      const airlineNamesFromImages = filteredImages.map(imageName => standardizeAirlineName(imageNameToAirline[imageName]));


      const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
	<MetaTitle pageTitle={firstResponseData?.metaTitle} pageDescription={firstResponseData?.metaDesc} url={`${apiIP}/flights-to/${airlineName}`} />
    <Searchbar backgroundImage={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/destinationcover_banner/${url}.webp`} />
    <div class="dels-sction">	
 <div class="best-cntnr">
	 
    <div class="col-first">
	 <h1>Flights to {firstResponseData?.pageName}({firstResponseData?.pageValue && firstResponseData.pageValue.toUpperCase()})</h1>
	<div dangerouslySetInnerHTML={{ __html: firstResponseData?.contentData }}></div>
			
	  {/* <p>{firstResponseData?.dealCode}</p> */}
		 
	  {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.    
	  </p> */}
		 {/* <div class="cntrbox">
		 <div class="middle-img">
			 <img src="resources/images/airline/img-boxes1.png" alt=""/>
		</div>
		 <div class="middle-img">
			 <img src="resources/images/airline/img-boxes2.png" alt=""/>
		</div>
		 </div> */}
		
		{/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.   
	  </p> */}
		
		
		{/* <ul>
		<li>injected humour</li>
		<li>many variations of passages</li>
		<li>dictionary of over 200 Latin</li>	
		
		</ul> */}
		
		{/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p> */}
		 
    </div>
	 
    <div class="col-last">
	{filteredImages.map((imageName, index) => (
        <div className="sidebox" key={index}>
          <div onClick={() => handleCardClick("City", imageNameToAirlineCode[imageName], imageNameToAirline[imageName])} style={{cursor: 'pointer'}}>
            <img
              src={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/destinationside_banner/${imageName}`}
              alt={imageNameToAirline[imageName]}
            />
          </div>
        </div>
      ))}
		
	  {/* <div class="side_airs">
		<h4>Top Destinations</h4>
		<ul>
		<li><a href="/">Alaska Airlines <img src="resources/images/arowimg.png" alt=""/></a></li>
		<li><a href="/">Delta Air lines <img src="resources/images/arowimg.png" alt=""/></a></li>
		<li><a href="/">United Airlines <img src="resources/images/arowimg.png" alt=""/></a></li>
		<li><a href="/">Alaska Airlines <img src="resources/images/arowimg.png" alt=""/></a></li>
		<li><a href="/">Delta Air lines <img src="resources/images/arowimg.png" alt=""/></a></li>
		<li><a href="/">United Airlines <img src="resources/images/arowimg.png" alt=""/></a></li>	
		</ul>
		  
		</div> */}
    <div class="side_airs">
  <h4>Top Destinations</h4>
  <ul>
    {/* {["Washington", /*"Delta Air Lines", "New York", "San Francisco", "Miami", "Mexico City", "Chicago", "Los Angeles"].map((airlineName) => (
      // Check if the airline name is not equal to firstResponseData.pageName
      firstResponseData?.pageName !== airlineName && (
        <li key={airlineName}>
          <Link to={createURL(airlineName)} onClick={() => fetchData()}>
            {airlineName} <img src="resources/images/arowimg.png" alt="" />
          </Link>
        </li>
      )
    ))} */}
    
  
    {["Washington", /*"Delta Air Lines" */ "New York", "San Francisco", "Miami", "Mexico City", "Chicago", "Los Angeles"].filter(airlineName => (
      // Check if the airline name is not equal to firstResponseData.pageName and not present in airlineNamesFromImages
      firstResponseData?.pageName !== airlineName && !airlineNamesFromImages.includes(airlineName)
    )).map(airlineName => (
      <li key={airlineName}>
        <Link to={createURL(airlineName)} target='_blank' onClick={() => fetchData()}>
          {airlineName} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  
  </ul>
</div>
		
    </div>
	 
    <div class="full-width">
    {windowWidth > 430 && (
	<div class="tabgcolor">
<h3>Flight Deals from {secondResponseData?.response[0]?.froCityName} to{" "}
                    {secondResponseData?.response[0]?.toCityName}</h3>
<div class="maintabs" style={{height: 'auto', backgroundImage: `url(https://imgfolders.com/rawfares/rawfaresUS/airline_detail/destinationcover_banner/${url}.webp)`, backgroundSize: 'cover'}}>
	
<div class="tab">
  <div class="tabs_heading">Flight From</div>	
  {secondResponseData && secondResponseData?.response && Array.isArray(secondResponseData?.response) ? (
    secondResponseData?.response.map((item, index) => (
  <button class={`linkstab ${item === selectedFlight ? 'selected' : ''}`} key={index} onClick={() => handleFroCityClick(item)}>
	  <img src={DealsGlobe} alt="DealsGlobe"/> {item.froCityName}
	<span class="tbarow"><img src={tabsArrow}  alt="tabsArrow"/></span>
	</button>
 ))
 ) : (
   <p>No responses available</p>
 )}
	{/* <!--//////////More Button////////////--> */}
	
</div>
	{/* <!--Tabs--> */}
  {selectedFlight ? (
<div id="newyorkcity" class="bottomright">
  <h3 style={{color: '#fff'}}>Fly from {selectedFlight.froCityName}</h3>
	<div class="cityfaremain">  
	<div class="citydate">
	<p>Depart Date-{formatDateAndTime(selectedFlight.depDate).formattedDateString}</p>
	<p>Return Date-{formatDateAndTime(selectedFlight.retDate).formattedDateString}</p>
	</div>	  
	<div class="city-fare">
	<p>Fare*</p>
	<p>${selectedFlight.totalPrice} {/*<sup>00</sup>*/}</p>	
	</div>
	</div> 
  </div>	
  ) : (
    <p>Select a city to view details.</p>
  )}

<div style={{clear: 'both'}}></div>	
</div>	
	
{/* <script>
function openCity(evt, cityName) {
  var i, tabcontent, linkstab;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  linkstab = document.getElementsByClassName("linkstab");
  for (i = 0; i < linkstab.length; i++) {
    linkstab[i].className = linkstab[i].className.replace(" active", "");
  }
  document.getElementById(cityName).style.display = "block";
  evt.currentTarget.className += " active";
}
// Get the element with id="defaultOpen" and click on it
document.getElementById("defaultOpen").click();
</script> */}
		
<div style={{clear: 'both'}}></div>
<p class="dismer"><span>Disclamer:-</span> All fares on rawfares.com are quoted in USD. These fare have Originated from historical data. Might vary and cannot be promised at the time of booking.</p>
		</div> 
    )}
		
	  <h3 style={{paddingTop: '20px'}}>{firstResponseData?.airportHub}</h3>
		
	  <div dangerouslySetInnerHTML={{ __html: firstResponseData?.travelTrends }}></div>
		
		{/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc. by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p> */}
		
		<div class="threeimg">
			<div onClick={(e) => {handleCardClick("Airline", "as", "alaska-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}><img src={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/footer_banner/alaska-airlines.webp`} alt="alaska-airlines"/></div>
			<div onClick={(e) => {handleCardClick("Airline", "b6", "jetblue-airways"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}><img src={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/footer_banner/jetBlue-airways.webp`} alt="jetBlue-airways"/></div>
			<div onClick={(e) => {handleCardClick("Airline", "sy", "sun-country-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}><img src={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/footer_banner/sun-country-airlines.webp`} alt="sun-country-airlines"/></div>
			
		</div>
		
		<div class="threeimg">
	    <div onClick={(e) => {handleCardClick("Airline", "f9", "frontier-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}><img src={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/footer_banner/frontier-airlines.webp`} alt="frontier-airlines"/></div> 
		</div>
        

    </div> 
         
        </div>
	
    </div>
   <div style={{clear: 'both'}}></div>
   <Footer />
    </>
  )
}
