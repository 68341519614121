import React from 'react';
import '../resources/css/enquiry/animate.css';
import '../resources/css/enquiry/bootstrap-datepicker.css';
import '../resources/css/enquiry/flaticon.css';
// import '../resources/css/enquiry/search-style.css';

export default function CarEnquiry() {
  return (
    <section class="ftco-section">
			<div class="container">
				<div class="row no-gutters">					
				<div class="col-md-8 bg-search">
				<div class="headingtop"><h2>Make your trip</h2></div>
							
					<div class="row">
					<div class="col-md-6">
						<div class="form-group-haf">
							<label for="" class="title">Pick-up location</label>
							<input type="text" placeholder="City, Airport, Station, etc" />
						</div>

						<div class="form-group-haf">
						<label for="" class="title">Drop-off location</label>
						<input type="text" placeholder="City, Airport, Station, etc" />
						</div>

					<div class="d-flex">
					<div class="formgrouph mr-2">
					<label for="" class="title">Pick-up date</label>
					<input type="text" class="form-controlfull" id="book_pick_date" placeholder="Date" />
				    </div>

				  <div class="formgrouph ml-2">
					<label for="" class="title">Drop-off date</label>
					<input type="text" class="form-controlfull" id="book_off_date" placeholder="Date" />
				  </div>
						
			      </div>

					<div class="formgrouph">
					<label for="" class="title">Pick-up time</label>
					<select name="cars" class="form-controlfull" id="Pick-up">
					<option value="select">Pick-up time</option>	
					<option value="12:00am">12:00am</option>
					<option value="12:30pm">12:30pm</option>
					<option value="01:00pm">01:00pm</option>
					<option value="01:30pm">01:30pm</option>
					</select>			

					</div>	
					</div>

					<div class="col-md-6">

						<div class="d-flex">
						<div class="formgrouph mr-2">
						<label for="" class="title">Drivers Name</label>
						<input type="text" class="form-controlfull"  placeholder="Drivers Name" />
						</div>
						<div class="formgrouph ml-2">
						<label for="" class="title">Drivers Age</label>
							<select name="cars" class="form-controlfull" id="cars">
							    <option value="35">Between 30 - 65?</option>
                                <option value="24">Below 30</option>
                                <option value="75">Above 65</option>
							</select>	
						</div>
						</div>	

						<div class="form-group-haf">
						<label for="" class="title">
							Drivers Email Adress</label>
						<input type="text" placeholder="Drivers Email Adress" />
						</div>

						<div class="d-flex">
						<div class="formgrouph mr-2">
						<label for="" class="title">Contact No.</label>
						<input type="text" class="form-controlfull" placeholder="Contact No." />
						</div>
						<div class="formgrouph ml-2">
						<label for="" class="title">Car</label>
						<select name="cars" class="form-controlfull" id="car">
							<option value="volvo">Type Car</option>
							<option value="saab">Type Car</option>
							<option value="mercedes">Type Car</option>
							<option value="audi">Type Car</option>
							</select>	
						</div>
						</div>

				 <div class="formgrouph">
				   <label for="" class="title">Car Type</label>
					<select name="cars" class="form-controlfull" id="carType">
					<option value="Car1">Car Type</option>
					<option value="Car2">Car Type</option>
					<option value="Car3">Car Type</option>
					<option value="Car4">Car Type</option>
					</select>	
				 </div>

			</div>
								
					<div class="col-md-12">
					<div class="" style={{textAlign: 'center', marginTop: '14px'}}>
					<button type="submit" class="searchbutn btnhover"> 
						Reserve Your Rental Car
						</button>
					</div>
					</div>
								
					</div>
							
					</div>
						
	  				<div class="col-md-4 d-flex align-items-center">
	  						<div class="services-wrap rounded-right w-100">	  							
	  						<div class="row d-flex mb-4">
					          <div class="col-md-12 d-flex align-self-stretch ftco-animate">
					            <div class="services w-100 text-center">
				              	<div class="icon d-flex align-items-center justify-content-center"><span class="flaticon-route"></span></div>
				              	<div class="text w-100">
					                <h3 class="heading mb-2">Choose Your Pickup Location</h3>
				                </div>
					            </div>      
					          </div>
					          <div class="col-md-12 d-flex align-self-stretch ftco-animate">
					            <div class="services w-100 text-center">
				              	<div class="icon d-flex align-items-center justify-content-center"><span class="flaticon-handshake"></span></div>
				              	<div class="text w-100">
					                <h3 class="heading mb-2">Select the Best Deal</h3>
					              </div>
					            </div>      
					          </div>
					          <div class="col-md-12 d-flex align-self-stretch ftco-animate">
					            <div class="services w-100 text-center">
				              	<div class="icon d-flex align-items-center justify-content-center"><span class="flaticon-rent"></span></div>
				              	<div class="text w-100">
					                <h3 class="heading mb-2">Reserve Your Rental Car</h3>
					              </div>
					            </div>      
					          </div>
					        </div>
					        
	  						</div>
	  					</div>
	  				</div>
				
			</div>
	</section>
  )
}
