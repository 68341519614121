/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useRef } from 'react';
import SITE_CONFIG from '../SiteController/SiteController';
//import { useRef } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
//import { useParams } from 'react-router-dom';
import countryList from '../controller/US_Controller';
import USState from '../controller/US_States';
import INState from '../controller/IN_States';
import CAState from '../controller/CA_States';
import AUState from '../controller/AU_States';
import '../resources/css/card-style.css';
import Footer2 from '../components/Footer/footer2';
import yesMark from '../resources/images/yesmark.png';
import AmericanExpress from  '../resources/images/americanExpress.png'
import Discover from '../resources/images/DiscoverNetwork.png'
import Visa from '../resources/images/visa.png';
import mastercardImage from '../resources/images/masterCard.png'
import SSL from '../resources/images/SSL.png';
import cardStrip from '../resources/images/icon_visa.png'
import Loader from '../resources/loader/loader.gif';
import MetaTitle from './metaTitle';

export default function CardMeta() {
    const [bid, setBid] = useState('');
  const [bookingRequest, setBookingRequest] = useState({ bookingID: '' });
  const [cardPayType, setCardPayType] = useState('')

  const [responseViewBooking, setResponseViewBooking] = useState('')

  // useEffect(() => {
  //   // Simulating the logic to get the bid from the URL in React
  //   const path = window.location.pathname;
  //   const bidFromPath = path.split('/').pop();
  //   setBid(bidFromPath);

  //   // Simulating the logic to decrypt TID in React
  //   let decryptedTID = '';
  //   try {
  //     let fulValue = bidFromPath;
  //     const lengths = fulValue.length;
  //     fulValue = fulValue.substring(0, lengths - 14);
  //     fulValue = fulValue.substring(7);
  //     decryptedTID = fulValue;
      
  //   } catch (error) {
  //     //console.error(error);
  //   }
  //   //console.log(decryptedTID)
  //   setBookingRequest({ ...bookingRequest, bookingID: decryptedTID });
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = window.location.pathname;
        const bidFromPath = path.split('/').pop();
        setBid(bidFromPath);
  
        let decryptedTID = '';
        let fulValue = bidFromPath;
        let cardPayType = bidFromPath;
        const lengths = fulValue.length;
        cardPayType = fulValue.substring(lengths-1);
        fulValue = fulValue.substring(0, lengths - 15);
        fulValue = fulValue.substring(7);
        decryptedTID = fulValue;
  
        const requestPayload = {
          bookingId: parseInt(decryptedTID)
        };
        //console.log(cardPayType)
        //console.log(requestPayload)
        const response = await axios.post(`${apiIP}:8585/api/viewbooking`, requestPayload);
        //console.log(response.data)
        if (
          response.data &&
          response.data.response &&
          response.data.response.length > 0 &&
          response.data.response[0].FareDetail &&
          response.data.response[0].FareDetail.length > 0
      ) {
          setResponseViewBooking(response.data.response[0].FareDetail[0]);
          //console.log(responseViewBooking);
      } else {
          //console.error("FareDetail is undefined or empty in the response");
      }

        ////console.log(responseViewBooking.data)
        //console.log(decryptedTID);
        setCardPayType(cardPayType)
        setBookingRequest({ ...bookingRequest, bookingID: decryptedTID });
      } catch (error) {
        //console.error(error);
      }
    };
  
    fetchData();
  }, []);
//   useEffect(() => {
//     // Simulating the logic to get the bid from the URL in React
//     const path = window.location.pathname;
//     const bidFromPath = path.split('/').pop();
//     setBid(bidFromPath);

//     // Simulating the logic to decrypt TID in React
//     let decryptedTID = '';
//     try {
//       let fulValue = bidFromPath;
//       // Find the index of the last alphabet
//       const lastIndex = fulValue.search(/[a-zA-Z]+$/);
//       // If an alphabet is found
//       if (lastIndex !== -1) {
//         // Extract the substring up to the last alphabet
//         fulValue = fulValue.substring(0, lastIndex);
//       }
//       // Extract only the last 5 digits
//       decryptedTID = fulValue.slice(-5);
//     } catch (error) {
//       //console.error(error);
//     }
//     //console.log(decryptedTID)
//     setBookingRequest({ ...bookingRequest, bookingID: decryptedTID });
// }, []);

  
    // useEffect(() => {
    //     // Get the current URL
    //     const currentURL = window.location.href;
    
    //     // Find the index of "cmdetail/"
    //     const cmdetailIndex = currentURL.indexOf("cmdetail/");
    
    //     if (cmdetailIndex !== -1) {
    //       // Extract the value after "cmdetail/"
    //       const extractedValue = currentURL.substring(cmdetailIndex + 9).split('/')[0];
    
    //       // Remove alphabets and dates (yyyyddmm)
    //       const numericValue = extractedValue.replace(/[^0-9]/g, '');
    
    //       if (numericValue) {
    //         //console.log("Extracted Numeric Value:", numericValue);
    //       } else {
    //         //console.error("Unable to extract the numeric value from the URL");
    //       }
    //     } else {
    //       //console.error("URL does not contain 'cmdetail/'");
    //     }
    //   }, []); 
  const { apiIP, siteName, siteID } = SITE_CONFIG;
//     const location = useLocation();
// const cardState = location.state;
const [cardNumber, setCardNumber] = useState('');
const [isLoading, setIsLoading] = useState(false);
const [numError, setNumError] = useState(false);
const [holNameError, setHolNameError] = useState(false);
const [cvvError, setCvvError] = useState(false);
const [monError, setMonError] = useState(false);
const [yeaError, setYeaError] = useState(false);
  const [maskedNumber, setMaskedNumber] = useState('');
  const [cardType, setCardType] = useState('Unknown');
  const [cvv, setCVV] = useState(''); // State for the actual CVV
const [maskedCVV, setMaskedCVV] = useState('');
  const [responseData, setResponseData] = useState(null)
  // const [cardHolder, setCardHolder] = useState('');
  // const [cvv, setCvv] = useState('');
  // const [expiryMonth, setExpiryMonth] = useState('');
  // const [expiryYear, setExpiryYear] = useState('');
  const [bookingRequest2, setBookingRequest2] = useState({
    cardDetails: {
        cardCode: cardType,
        maskCardNumber: '',
        cardNumber: '',
        cardHolderName: '',
        expiryMonth: '',
        expiryYear: '',
        cvvNo: '',
    },
    billingInfo: {
      address1: '',
      address2: '',
        city: '',
        country: '',
        state: '',
        postalCode: '',
        billingMobile: '',
    }
  });
  const cardNumberRef = useRef(null);
  const cardHolderNameRef = useRef(null);
  const expiryMonthRef = useRef(null);
  const expiryYearRef = useRef(null);
  const CvvRef = useRef(null);
  const streetAddressRef = useRef(null);
  const cityRef = useRef(null);
  const [errors, setErrors] = useState({
    cardDetails: {
      //cardCode: cardType,
      cardNumber: '',
      cardHolderName: '',
      expiryMonth: '',
      expiryYear: '',
      cvvNo: '',
    },
    billingInfo: {
      address1: '',
      address2: '',
      city: '',
      country: '',
      state: '',
      postalCode: '',
      billingMobile: '',
    },
  });
  useEffect(() => {
	setBookingRequest2((prevState) => ({
	  ...prevState,
      cardDetails : {
		// Update the first passenger
		cardNumber: cardNumber, 
    maskCardNumber: maskedNumber  
	  },
	}));
  }, [cardNumber, maskedNumber]);
  
  //const formRef = useRef(null);

  useEffect(() => {
    // Determine the card type based on the first digit
    if (/^4/.test(cardNumber)) {
      setCardType('Visa');
    } else if (/^5[1-5]/.test(cardNumber)) {
      setCardType('MasterCard');
    } else if (/^3[47]/.test(cardNumber)) {
      setCardType('AmericanExpress'); // American Express cards start with 34 or 37
    } else if (/^6(?:011|5[0-9]{2})/.test(cardNumber)) {
      setCardType('Discover'); // Discover cards start with 6011 or 65
    } else {
      setCardType('Unknown');
    }
  }, [cardNumber]);

  const handleCardNumberChange = (e) => {
    const inputCardNumber = e.target.value.replace(/\D/g, '');
    const truncatedCardNumber = inputCardNumber.slice(0, 16);
    setCardNumber(truncatedCardNumber);
    setMaskedNumber(truncatedCardNumber); // Show the full card number while typing
  };

  const handleCardNumberBlur = () => {
    // Mask the card number when the input field loses focus
    if (cardNumber.length > 4) {
      const visibleDigits = cardNumber.slice(-4);
      const maskedDigits = '**** **** **** ';
      setMaskedNumber(maskedDigits + visibleDigits);
    }
    

  };

  const handleCVVChange = (e) => {
    const inputCVV = e.target.value.replace(/\D/g, '');
    const truncatedCVV = inputCVV.slice(0, 4);

    // Store the actual CVV in bookingRequest2
    setBookingRequest2((prevState) => ({
      ...prevState,
      cardDetails: {
        ...prevState.cardDetails,
        cvvNo: truncatedCVV,
      },
    }));

    // Display the masked CVV on screen
    setMaskedCVV(truncatedCVV.length === 4 ? '****' : truncatedCVV.length === 3 ? '***' : truncatedCVV);
  };

  const handleCVVBlur = () => {
    // Display the masked CVV on blur
    setMaskedCVV(bookingRequest2.cardDetails.cvvNo.length === 4 ? '****' : bookingRequest2.cardDetails.cvvNo.length === 3 ? '***' : '');
  };

  

// Check if cardState contains the expected data
// if (!cardState || !cardState.selectedFlight || !cardState.airport || !cardState.formData || !cardState.newBookingRequest) {
//   return <p>Data is missing or invalid. Please check your data.</p>;
// }
// const totalPassengers = (cardState.formData && cardState.formData.adults ? parseInt(cardState.formData.adults) : 0) + (cardState.formData && cardState.formData.child ? parseInt(cardState.formData.child) : 0) + (cardState.formData && cardState.formData.infants ? parseInt(cardState.formData.infants) : 0) + (cardState.formData && cardState.formData.infantsWs ? parseInt(cardState.formData.infantsWs) : 0);
// const totalPriceAdults = ((cardState.formData && cardState.formData.adults ? parseInt(cardState.formData.adults) : 0) * cardState.selectedFlight.fare.adultFare).toFixed(2);
//   const totalPriceChild = ((cardState.formData && cardState.formData.child ? parseInt(cardState.formData.child) : 0) * cardState.selectedFlight.fare.childFare).toFixed(2);
//   const totalPriceInfant = ((cardState.formData && cardState.formData.infants ? parseInt(cardState.formData.infants) : 0) * cardState.selectedFlight.fare.infantFare).toFixed(2);
//   const totalPriceInfantWs = ((cardState.formData && cardState.formData.infantsWs ? parseInt(cardState.formData.infantsWs) : 0) * cardState.selectedFlight.fare.infantWsFare).toFixed(2);
//   const totalPriceSeat = (totalPassengers * 7.99);
//   //const totalWebCheckInPrice = (totalPassengers * 7.99);

//   const totalTax = cardState.selectedFlight.fare.totalTax;
// const roundedTax = totalTax.toFixed(2);

const cardImage = cardType === 'Visa' ? Visa : cardType === 'MasterCard' ? mastercardImage : cardType === 'AmericanExpress' ? AmericanExpress : cardType === 'Discover' ? Discover : null;

const [isChecked, setIsChecked] = useState(false);

        const handleCheckboxChange = (event) => {
          setIsChecked(event.target.checked);
        };

        // useEffect(() => {
        //     formRef.current = document.querySelector('form');
        //   }, []);
        
          // const handleChange = (e) => {
          //   const { name, value } = e.target;
          
          //   // Clone the current state to avoid mutating it directly
          //   const updatedBookingRequest2 = { ...bookingRequest2 };
          
          //   // Split the name attribute to get the nested field names
          //   const nameParts = name.split('.');
          //   const fieldName = nameParts[0]; // The main field name (e.g., "cardDetails")
          
          //   // Check if the field is nested
          //   if (nameParts.length > 1) {
          //     const nestedFieldName = nameParts.slice(1).join('.'); // Get the nested field name(s) (e.g., "expiryDate.expMonth")
              
          //     // Ensure the objects are defined before accessing nested properties
          //     if (!updatedBookingRequest2[fieldName]) {
          //       updatedBookingRequest2[fieldName] = [{}];
          //     }
              
          //     if (!updatedBookingRequest2[fieldName][0]) {
          //       updatedBookingRequest2[fieldName][0] = {};
          //     }
              
          //     // Update the nested field
          //     updatedBookingRequest2[fieldName][0][nestedFieldName] = value;
          //   } else {
          //     // Update the main field if it's not nested
          //     updatedBookingRequest2[fieldName] = value;
          //   }
          
          //   // Update the state with the modified object
          //   setBookingRequest2(updatedBookingRequest2);
          // };
          // const handleChange = (e) => {
          //   const { name, value } = e.target;
          
          //   // Clone the current state to avoid mutating it directly
          //   const updatedBookingRequest2 = { ...bookingRequest2 };
          
          //   // Update the field directly without nesting
          //   updatedBookingRequest2.cardDetails[name] = value;
          //   updatedBookingRequest2.billingInfo[name] = value;
          
          //   // Update the state with the modified object
          //   setBookingRequest2(updatedBookingRequest2);
          // };
          const handleChange = (e) => {
            const { name, value } = e.target;
          
            // Clone the current state to avoid mutating it directly
            const updatedBookingRequest2 = { ...bookingRequest2 };
          
            // Check if the field belongs to cardDetails or billingInfo
            if (name.startsWith('cardDetails.')) {
              // Update the field within cardDetails
              const cardDetailsName = name.replace('cardDetails.', '');
              updatedBookingRequest2.cardDetails[cardDetailsName] = value;
            } else if (name.startsWith('billingInfo.')) {
              // Update the field within billingInfo
              const billingInfoName = name.replace('billingInfo.', '');
              updatedBookingRequest2.billingInfo[billingInfoName] = value;
            } else {
              // If the field is not part of cardDetails or billingInfo,
              // update it directly in the top-level state
              updatedBookingRequest2[name] = value;
            }
            if (name === 'cardDetails.cardHolderName') {
              // Filter out non-alphabetic characters and spaces from the input
              const filteredInput = value.replace(/[^A-Za-z\s]/g, '');
              
              // Clone the current state to avoid mutating it directly
              const updatedBookingRequest2 = { ...bookingRequest2 };
          
              // Update the "cardHolderName" field within cardDetails
              updatedBookingRequest2.cardDetails.cardHolderName = filteredInput;
          
              // Update the state with the modified object
              setBookingRequest2(updatedBookingRequest2);
            }

            const isCardWith3DigitCVV = ['Visa', 'MasterCard', 'Discover'].includes(cardType);


            

  if (name === 'cardDetails.cvvNo') {
    // Filter out non-numeric characters from the input
    const filteredInput = value.replace(/[^0-9]/g, '');

    // Limit the CVV input length based on the card type
    const maxCVVLength = isCardWith3DigitCVV ? 3 : 4;
    updatedBookingRequest2.cardDetails.cvvNo = filteredInput.slice(0, maxCVVLength);
    // setMaskedCVV(maskedCVV);
    setBookingRequest2(updatedBookingRequest2);
  }

            if (name === 'billingInfo.city') {
              // Filter out non-alphabetic characters and spaces from the input
              const filteredInput = value.replace(/[^A-Za-z\s]/g, '');
              
              // Clone the current state to avoid mutating it directly
              const updatedBookingRequest2 = { ...bookingRequest2 };
          
              // Update the "cardHolderName" field within cardDetails
              updatedBookingRequest2.billingInfo.city = filteredInput;
          
              // Update the state with the modified object
              setBookingRequest2(updatedBookingRequest2);
            }
            if (name === 'billingInfo.billingMobile') {
              // Filter out non-alphabetic characters and spaces from the input
              const filteredInput = value.replace(/[^0-9]/g, '').slice(0,10);
              
              // Clone the current state to avoid mutating it directly
              const updatedBookingRequest2 = { ...bookingRequest2 };
          
              // Update the "cardHolderName" field within cardDetails
              updatedBookingRequest2.billingInfo.billingMobile = filteredInput;
          
              // Update the state with the modified object
              setBookingRequest2(updatedBookingRequest2);
            }
            if (name === 'billingInfo.postalCode') {
              // Clone the current state to avoid mutating it directly
              const updatedBookingRequest2 = { ...bookingRequest2 };
              const value = bookingRequest2.billingInfo.postalCode;
            
              if (bookingRequest2.billingInfo.country === 'US') {
                // Filter out non-alphabetic characters and spaces from the input
                const filteredInput = value.replace(/[^0-9]/g, '').slice(0,5);
            
                // Update the "zipCode" field within billingInfo
                updatedBookingRequest2.billingInfo.postalCode = filteredInput;
              } else if (bookingRequest2.billingInfo.country === 'CA') {
                // Filter out non-alphabetic characters and spaces from the input
                const filteredInput = value.replace(/[A-Z]{1,2}\d{1,2}[A-Z]{0,1} ?\d[A-Z]{2}/gi, '');
            
                // Update the "zipCode" field within billingInfo
                updatedBookingRequest2.billingInfo.postalCode = filteredInput;
              } else if (bookingRequest2.billingInfo.country === 'IN') {
                // Filter out non-alphabetic characters and spaces from the input
                const filteredInput = value.replace(/[^0-9]/g, '').slice(0,6);
            
                // Update the "zipCode" field within billingInfo
                updatedBookingRequest2.billingInfo.postalCode = filteredInput;
              } else if (bookingRequest2.billingInfo.country === 'AU') {
                // Filter out non-alphabetic characters and spaces from the input
                const filteredInput = value.replace(/[^0-9]/g, '').slice(0,4);
            
                // Update the "zipCode" field within billingInfo
                updatedBookingRequest2.billingInfo.postalCode = filteredInput;
              } else if (bookingRequest2.billingInfo.country === 'UK') {
                // Filter out non-alphabetic characters and spaces from the input
                const filteredInput = value.replace(/[A-Z][^0-9]/g, '').slice(0,4);
            
                // Update the "zipCode" field within billingInfo
                updatedBookingRequest2.billingInfo.postalCode = filteredInput;
              }
            
              // Update the state with the modified object
              setBookingRequest2(updatedBookingRequest2);
            }
            
            setBookingRequest2(updatedBookingRequest2);
          };
          
          
          
          
          
          
          
          
          
          
          
          const navigate = useNavigate();
          const formRef = useRef();
          const handleSubmit = async (e) => {
            const updatedBookingRequest = {
              ...bookingRequest,
              paymentDetails: {
                ...bookingRequest2.cardDetails,
                ...bookingRequest2.billingInfo,
                cardCode: cardType,
              },
              siteID : siteID,
              sourceMedia : 'Online',
              ancillaryProduct: [],
              isPriceChange : false,
              isSoldOut : false,
           
              };
           

              const newErrors = { ...errors };
              const currentDate = new Date();
              const currentYear = currentDate.getFullYear();
              const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-based, so add 1
const expiryDate = new Date(
                bookingRequest2.cardDetails.expiryYear,
                bookingRequest2.cardDetails.expiryMonth - 1, // Adjust for 0-based month
              );


              if (expiryDate < currentDate) {
                newErrors.cardDetails.expiryMonth = 'Please fill correctly this field';
                
              } else {
                newErrors.cardDetails.expiryMonth = ''; // Clear the error if it's valid
              }


            
    // Check for cardNumber
    if (!bookingRequest2.cardDetails.cardNumber) {
      newErrors.cardDetails.cardNumber = 'Please fill this field';
      cardNumberRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      newErrors.cardDetails.cardNumber = ''; // Clear the error if it's valid
    }
    
    // Check for cardHolderName
    if (!bookingRequest2.cardDetails.cardHolderName) {
      newErrors.cardDetails.cardHolderName = 'Please fill this field';
      cardHolderNameRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      newErrors.cardDetails.cardHolderName = ''; // Clear the error if it's valid
    }

    

    if (!bookingRequest2.cardDetails.expiryMonth) {
      newErrors.cardDetails.expiryMonth = 'Please fill this field';
      // expiryMonthRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      newErrors.cardDetails.expiryMonth = ''; // Clear the error if it's valid
    }
    

    if (!bookingRequest2.cardDetails.expiryYear) {
      newErrors.cardDetails.expiryYear = 'Please fill this field';
      //expiryYearRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      newErrors.cardDetails.expiryYear = ''; // Clear the error if it's valid
    }

    if (!bookingRequest2.cardDetails.cvvNo) {
      newErrors.cardDetails.cvvNo = 'Please fill this field';
      //CvvRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      newErrors.cardDetails.cvvNo = ''; // Clear the error if it's valid
    }
    
    // Add similar checks for expiryMonth, expiryYear, and CVV
    
    setErrors(newErrors);
              //console.log(updatedBookingRequest)
              //const cardStateWithBooking = { updatedBookingRequest };
            e.preventDefault();
            //const newErrors = { ...errors };
    for (const key in bookingRequest2.cardDetails) {
      if (!bookingRequest2.cardDetails[key]) {
        newErrors.cardDetails[key] = 'Please fill this field';
        if (key === 'cardNumber') {
          cardNumberRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (key === 'cardHolderName') {
          cardHolderNameRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        newErrors.cardDetails[key] = ''; // Clear the error if it's valid
      }
    }

    // Check for errors in billingInfo
    for (const key in bookingRequest2.billingInfo) {
      if (!bookingRequest2.billingInfo[key]) {
        newErrors.billingInfo[key] = 'Please fill this field';
        if (key === 'address1') {
          streetAddressRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (key === 'city') {
          cityRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        newErrors.billingInfo[key] = ''; // Clear the error if it's valid
      }
    }

    setErrors(newErrors);

    for (const key in newErrors.cardDetails) {
      if (newErrors.cardDetails[key]) {
        // Display validation errors for cardDetails
        //console.log('error in cardDetails')
        return;
      }
    }

    for (const key in newErrors.billingInfo) {
      if (newErrors.billingInfo[key]) {
        // Display validation errors for billingInfo
        //console.log('Error in Billing info')
        return;
      }
    }
            
            
          
                document.body.style.overflowY = 'hidden';
      const homeBody = document.querySelector('.HomeBody');
if (homeBody) {
  const elementsToBlur = homeBody.querySelectorAll(':not(.loader-overlay)');
  elementsToBlur.forEach((element) => {
    element.style.filter = 'blur(8px)';
  });
}
setIsLoading(true)
if(updatedBookingRequest){
    const padZero = (num) => (num < 10 ? `0${num}` : `${num}`);
    const baseFare = responseViewBooking.baseFare;
    const totalTax = responseViewBooking.totalTax;
    const adultMarkup = responseViewBooking.adultMarkup;
    const childMarkup = responseViewBooking.childMarkup;
    const infantMarkup = responseViewBooking.infantMarkup;
    const  infantWSMarkup = responseViewBooking.infantWsMarkup;

    let amtToPay = 0.00;
if (cardPayType.toLowerCase() === "n") {
    amtToPay = baseFare + totalTax;
} else if (cardPayType.toLowerCase() === "m") {
    amtToPay = adultMarkup + childMarkup + infantMarkup + infantWSMarkup;
} else if (cardPayType.toLowerCase() === "g") {
    amtToPay = baseFare + totalTax + adultMarkup + childMarkup + infantMarkup + infantWSMarkup;
}
//console.log(amtToPay);
const expDate =
  updatedBookingRequest.paymentDetails.expiryMonth +
  '-' +
  padZero(updatedBookingRequest.paymentDetails.expiryYear);
    const tdetails = {
        bookingId: updatedBookingRequest.bookingID,
        trnsNo: updatedBookingRequest.bookingID,
        amount: amtToPay,
        cardNo: updatedBookingRequest.paymentDetails.cardNumber,
        holderName: updatedBookingRequest.paymentDetails.cardHolderName,
        expDate: expDate,
        validFrom: expDate,
        issueNo: "wd",
        securityCode: updatedBookingRequest.paymentDetails.cvvNo,
        cardType: updatedBookingRequest.paymentDetails.cardCode,
        country: updatedBookingRequest.paymentDetails.country,
        city: updatedBookingRequest.paymentDetails.city,
        address: `${updatedBookingRequest.paymentDetails.address1}, ${updatedBookingRequest.paymentDetails.address2}`,
        coutyState: updatedBookingRequest.paymentDetails.state,
        postCode: updatedBookingRequest.paymentDetails.postalCode,
        modifiedBy: "Admin"
    }
    ////console.log(tdetails)
    try {
        //console.log("tdetails", tdetails)
        const response = await axios.post(
          `${apiIP}:8484/api/add-card`,
          tdetails
        );
  
        if (response.status === 200) {
          setResponseData(response.data); // Update the state with the response data
          //console.log(response.data);
          
            
          
           navigate(`/thankyou`, { state: { data: response.data } });
        } else {
          //navigate(`/thankyou`, { state: { data: response.data } })
          ////console.error("Request failed with status:", response.status);
        }
      } catch (error) {
        ////console.error("Error making POST request:", error);
        //console.log(tdetails)
      }
}else {
    //console.log("Error")
}
            
          }
          
  return (
    <>
    <MetaTitle url={`${apiIP}/card`}/>
    {isLoading && (
        <div className="loader-overlay">
          <img src={Loader} alt="Loader" />
        </div>
      )}
    <div class="container">
      <div style={{display: 'none'}}>
      {responseData ? (
        <div>
          <h2>API Response:</h2>
          <pre>{JSON.stringify(responseData, null, 2)}</pre>
        </div>
      ) : (
        <div>
          <p>No response yet. Click the button to fetch data.</p>
          <button className='btn btn-primary'>Click Me</button>
        </div>
      )}
      </div>
  <div class="row">
    <div class="col-md-12" >
      <div class="card-mainsummry" style={{marginTop: '75px', fontFamily: 'var(--bs-body-font-family)'}}>
        <div class="card-innersummry"><img src={yesMark} width="40" height="40" alt="yesMark"/> Card Details Form</div>
        
      <div style={{clear: 'both'}}></div>
      </div>
      <div class="row wrapper-card">
        <div class="col-md-8">
          <div class="card-detail-container">
            <h3 class="new_payment_title"> Payment and Billing </h3>
			  
            <div class="margB8 fontshow">
              <input type="radio" />
              Debit / Credit Card  
			  </div>
			  
			<div style={{width: '94%', margin: '0 auto'}}>  
			  
            <div class="row">
              <div class="col-md-4 margB8 card_title padtop6" ref={cardNumberRef}>Debit / Credit Card No. <span>*</span></div>
              <div class="col-md-6 margB18">
         <input placeholder="Debit / Credit Card No" style={{backgroundImage: `url(${cardImage})`}} class="form-control fntinpt pay-type" type="text" id="card-number" name="card-number" value={maskedNumber} onChange={handleCardNumberChange} onBlur={handleCardNumberBlur} required />	
         {/* <p>Card Type: {cardType}</p>			   */}
         {errors.cardDetails.cardNumber && <div class="show-error">{errors.cardDetails.cardNumber}</div>}
         {/* {numError === true ? (<div class="show-error">Enter Card Number Correctly</div>): null} */}
		{/* <!--<div class="show-error">Please enter at least 16 characters.</div>--> */}
        {/* {errors.firstName && } */}
				  
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 margB8 card_title padtop6" ref={cardHolderNameRef}>Card Holder Name <span>*</span></div>
              <div class="col-md-6 margB18">
                <input placeholder="Card Holder Name" id="cardHolderName" class="form-control fntinpt" type="text" name="cardDetails.cardHolderName" value={bookingRequest2.cardDetails.cardHolderName} onChange={handleChange} required/>
                {errors.cardDetails.cardHolderName && <div class="show-error">{errors.cardDetails.cardHolderName}</div>}
                {/* {holNameError === true ? (<div class="show-error">Enter Card Holder Name Correctly</div>): null} */}
				 {/* <!--<div class="show-error">Please Enter Card Holder Name</div>--> */}
              </div>
            </div>			  
            <div class="row">
              <div class="col-md-4 margB8 card_title padtop6">Expiry Date <span>*</span></div>
              <div class="col-md-4 zgroup">
                <div class="cardgroup">
                  <div class="form-group devider">
                    <select id="paymentDetails_ExpiryMonth" name="cardDetails.expiryMonth" value={bookingRequest2.cardDetails.expiryMonth} onChange={handleChange}  style={{height: '31px', fontSize: '12px'}} class="form-select has-error" aria-describedby="paymentDetails_ExpiryMonth-error" aria-invalid="true">
                      <option value="0">Month</option>
                      <option value="1">Jan (01)</option>
                      <option value="2">Feb (02)</option>
                      <option value="3">Mar (03)</option>
                      <option value="4">Apr (04)</option>
                      <option value="5">May (05)</option>
                      <option value="6">Jun (06)</option>
                      <option value="7">Jul (07)</option>
                      <option value="8">Aug (08)</option>
                      <option value="9">Sep (09)</option>
                      <option value="10">Oct (10)</option>
                      <option value="11">Nov (11)</option>
                      <option value="12">Dec (12)</option>
                    </select>
                    
                    <div id="paymentDetails_ExpiryMonth-error" class="show-error"></div>
                  </div>
                  
                  <div class="form-group">
                    <select id="paymentDetails_ExpiryYear" name="cardDetails.expiryYear" value={bookingRequest2.cardDetails.expiryYear} onChange={handleChange} style={{height: '31px', fontSize: '12px'}} class="form-select has-error" aria-describedby="paymentDetails_ExpiryYear-error" aria-invalid="true">
                      <option value="Year">Year</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                      <option value="2031">2031</option>
                      <option value="2032">2032</option>
                      <option value="2033">2033</option>
                      <option value="2034">2034</option>
                      <option value="2035">2035</option>
                      <option value="2036">2036</option>
                      <option value="2037">2037</option>
                      <option value="2038">2038</option>
                      <option value="2039">2039</option>
                      <option value="2040">2040</option>
                    </select>
                    
                    {/* <div id="paymentDetails_ExpiryYear-error" class="show-error"></div> */}
                  </div>
                  
                </div>
                
                {errors.cardDetails.expiryMonth && <div class="show-error">{errors.cardDetails.expiryMonth}</div>}
                {errors.cardDetails.expiryYear && <div class="show-error">{errors.cardDetails.expiryYear}</div>}
              </div>
             
              <div class="col-md-2 zgroup2">
                <div class="form-group2">
                  <input placeholder="Enter CVV" name="cardDetails.cvvNo" value={maskedCVV} onChange={handleCVVChange} onBlur={handleCVVBlur} style={{height: '38px', fontSize: '12px'}} class="form-control" type="text" />
                  {errors.cardDetails.cvvNo && <div class="show-error">{errors.cardDetails.cvvNo}</div>}
                </div>
              </div>
              <div class="col-md-2 zgroup3 fntsize"> 
				  3-4 Digit Number on your card. 
				</div>
            </div>		  
			  
			 <div class="row">              
              <div class="col-md-12 margB8">
			    <img src={SSL} width="286" height="52" alt="SSL"/>
          <img src={cardStrip} style={{maxWidth: '286px', width: '100%', height: 'auto', float: 'right'}} alt="cardStrip"/>
{/* <!--<span style="color:#f60;">
Safe and secure billing:</span> Your Debit/Credite Card Information is Processed by a secure SSL
				  Encrypted Transaction--> */}
              </div>
            </div> 
				
				</div>
			  <div style={{width: '94%', margin: '0 auto'}}>
			 <h3 class="new_payment_title2"> Billing Information<br/>
				 <span>(As per bank records or credit card company)</span> 
			  </h3>
			  
			  <div class="row">
				  <div class="col-md-6 margB18 card_title" ref={streetAddressRef}>
				<label>Street Address <span>*</span></label>	  
                <input placeholder="Street Address" class="form-control fntinpt" name="billingInfo.address1" type="text" value={bookingRequest2.billingInfo.address1} onChange={handleChange}/>
				{/* <div class="show-error">Street Address</div>	   */}
        {errors.billingInfo.address1 && <div class="show-error">{errors.billingInfo.address1}</div>}
              </div>
				 <div class="col-md-6 margB18 card_title">
				<label>Apt/Suite# <span>*</span></label>
                <input placeholder="Apt/Suite#" class="form-control fntinpt" name="billingInfo.address2" type="text" value={bookingRequest2.billingInfo.address2} onChange={handleChange} />
                {errors.billingInfo.address2 && <div class="show-error">{errors.billingInfo.address2}</div>}
              </div>
				  
				  
				  
              <div class="col-md-6 margB18 card_title" ref={cityRef}>
				  <label>City <span>*</span></label>
                <input placeholder="City" class="form-control fntinpt" name="billingInfo.city" type="text" value={bookingRequest2.billingInfo.city} onChange={handleChange} />
				 {errors.billingInfo.city && <div class="show-error">{errors.billingInfo.city}</div>}
       {/* <div class="show-error">Please Enter City</div> */}
              </div>
				 <div class="col-md-6 margB18 card_title">
				<label>Country <span>*</span></label>
                <select class="form-control fntinpt form-select cardgroup" aria-describedby="paymentDetails_Country-error" name="billingInfo.country" aria-invalid="true" value={bookingRequest2.billingInfo.country} onChange={handleChange} >
					{/* <option label="Select Country" value="0" selected="selected">Select Country</option> */}
          {countryList.map((country) => (
            <option key={country.ID} value={country.ID}>
              {country.Name}
            </option>
          ))}
					 </select>
           {errors.billingInfo.country && <div class="show-error">{errors.billingInfo.country}</div>}
              </div>
				  
				<div class="col-md-6 margB18 card_title">
				<label>State </label>	
        {bookingRequest2.billingInfo.country === 'IN' || bookingRequest2.billingInfo.country === 'CA' || bookingRequest2.billingInfo.country === 'AU' || bookingRequest2.billingInfo.country === 'US' ? (				
					<select class="form-control fntinpt form-select cardgroup" name="billingInfo.state" aria-invalid="false" value={bookingRequest2.billingInfo.state} onChange={handleChange}>
						{/* <option label="Select State" value="0" selected="selected">Select State</option> */}
            {bookingRequest2.billingInfo.country === 'US' && USState.map((usState) => (
             <option key={usState.ID} value={usState.ID}>
               {usState.Name}
             </option>
            ))}
            {bookingRequest2.billingInfo.country === 'IN' && INState.map((inState) =>  (
             <option key={inState.ID} value={inState.ID}>
             {inState.Name}
           </option>
            ))}
            {bookingRequest2.billingInfo.country === 'CA' && CAState.map((caState) => (
             <option key={caState.ID} value={caState.ID}>
               {caState.Name}
             </option>
            ))}
            {bookingRequest2.billingInfo.country === 'AU' && AUState.map((auState) => (
             <option key={auState.ID} value={auState.ID}>
               {auState.Name}
             </option>
            ))}
					</select>
          ) : (<input placeholder="City" class="form-control fntinpt" name="billingInfo.state" type="text" value={bookingRequest2.billingInfo.state} onChange={handleChange} />)}
          {errors.billingInfo.state && <div class="show-error">{errors.billingInfo.state}</div>}
              </div>
				  
			<div class="col-md-6 margB18 card_title">
				<label>Postal/Zip Code <span>*</span></label>
                <input placeholder="Postal/Zipe Code" class="form-control fntinpt" name="billingInfo.postalCode" type="text" value={bookingRequest2.billingInfo.postalCode} onChange={handleChange} />
				{/* <div class="show-error">Please Enter Postal Code</div> */}
        {errors.billingInfo.postalCode && <div class="show-error">{errors.billingInfo.postalCode}</div>}
              </div>
				  
				  <div class="col-md-6 margB18 card_title">
				<label>Billing Number (Associated with bank) <span>*</span></label>
                <input placeholder="Billing Mobile" class="form-control fntinpt" type="text" name="billingInfo.billingMobile" value={bookingRequest2.billingInfo.billingMobile} onChange={handleChange} />
				{/* <div class="show-error">Please enter at least 10 characters.</div>	   */}
        {errors.billingInfo.billingMobile && <div class="show-error">{errors.billingInfo.billingMobile}</div>}
              </div>
				  
				
				  
            </div>
			  </div>
          </div>
			
			
			
          
        </div>
		  
        {/* <div class="col-md-4">
			<div class="marb15 is-sticky">
          <div class="card-detail-container"> 
			  <h3 class="price_summary_title">Price Summary</h3> 			  
			<div class="booked-amount-area">
			{/* <ul>
			<li><span>Adult ({cardState.selectedFlight && cardState.selectedFlight.fare && cardState.selectedFlight.fare.adultFare ? (
  `${(cardState.formData && cardState.formData.adults ? parseInt(cardState.formData.adults) : 0)}x${cardState.selectedFlight.fare.adultFare.toFixed(2).split('.')[0]}`
) : (
  "Fallback Value or Error Handling"
)}.<sup>{cardState.selectedFlight.fare.adultFare.toFixed(2).split('.')[1]}</sup>) </span> <span>${totalPriceAdults.split('.')[0]}.<sup>{totalPriceAdults.split('.')[1]}</sup></span> </li>
		{ (cardState.formData && cardState.formData.child ? parseInt(cardState.formData.child) : 0) ? (
							<li><span>Child ({(cardState.formData && cardState.formData.child ? parseInt(cardState.formData.child) : 0)}x${cardState.selectedFlight.fare.childFare.toFixed(2).split('.')[0]}.<sup>{cardState.selectedFlight.fare.childFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>${totalPriceChild.split('.')[0]}.<sup>{totalPriceChild.split('.')[1]}</sup></span> </li>
							) : null}		

{ (cardState.formData && cardState.formData.infants ? parseInt(cardState.formData.infants) : 0) ? (
							<li><span>Infant ({(cardState.formData && cardState.formData.infants ? parseInt(cardState.formData.infants) : 0)}x${cardState.selectedFlight.fare.infantFare.toFixed(2).split('.')[0]}.<sup>{cardState.selectedFlight.fare.infantFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>${totalPriceInfant.split('.')[0]}.<sup>{totalPriceInfant.split('.')[1]}</sup></span> </li>
							) : null}

                            { (cardState.formData && cardState.formData.infantsWs ? parseInt(cardState.formData.infantsWs) : 0) ? (
							<li><span>InfantsWs ({(cardState.formData && cardState.formData.infantsWs ? parseInt(cardState.formData.infantsWs) : 0)}x${cardState.selectedFlight.fare.infantWsFare.toFixed(2).split('.')[0]}.<sup>{cardState.selectedFlight.fare.infantWsFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>${totalPriceInfantWs.split('.')[0]}.<sup>{totalPriceInfantWs.split('.')[1]}</sup></span> </li>
							) : null}
			<li><span>Taxes</span>  <span>${roundedTax.split('.')[0]}.<sup>{roundedTax.split('.')[1]}</sup></span> </li>
			{cardState.newBookingRequest.seatSelection !== null ? (	
			<li><span>Seat </span> <span>${totalPriceSeat.toFixed(2).split('.')[0]}.<sup>{totalPriceSeat.toFixed(2).split('.')[1]}</sup></span> </li>
			): null}	
            {cardState.newBookingRequest.baggage !== null && (
  <div>
    <ul>
      {cardState.newBookingRequest.baggage.firstDepFirstBaggage && cardState.newBookingRequest.baggage.secondDepFirstBaggage ? (
        <li>
          <span>Departure Carry-on Baggages: </span>
          <span>$10</span>
        </li>
      ) : null}

      {cardState.newBookingRequest.baggage.firstDepFirstBaggage && !cardState.newBookingRequest.baggage.secondDepFirstBaggage ? (
        <li>
          <span>Departure Carry-on First Baggage: </span>
          <span>$5</span>
        </li>
      ) : null}

      {!cardState.newBookingRequest.baggage.firstDepFirstBaggage && cardState.newBookingRequest.baggage.secondDepFirstBaggage ? (
        <li>
          <span>Departure Carry-on Second Baggage: </span>
          <span>$5</span>
        </li>
      ) : null}

      {cardState.newBookingRequest.baggage.firstDepCheckedBaggage && cardState.newBookingRequest.baggage.secondDepCheckedBaggage ? (
        <li>
          <span>Departure Checked Baggages: </span>
          <span>$10</span>
        </li>
      ) : null}

{cardState.newBookingRequest.baggage.firstDepCheckedBaggage && !cardState.newBookingRequest.baggage.secondDepCheckedBaggage ? (
        <li>
          <span>Departure Checked First Baggage: </span>
          <span>$5</span>
        </li>
      ) : null}

      {!cardState.newBookingRequest.baggage.firstDepCheckedBaggage && cardState.newBookingRequest.baggage.secondDepCheckedBaggage ? (
        <li>
          <span>Departure Checked Second Baggage: </span>
          <span>$5</span>
        </li>
      ) : null}
      
    </ul>
  </div>
)}

{cardState.newBookingRequest.baggage !== null && (
  <div>
    <ul>
      {cardState.newBookingRequest.baggage.firstRetFirstBaggage && cardState.newBookingRequest.baggage.secondRetFirstBaggage ? (
        <li>
          <span>Return Carry-on Baggages: </span>
          <span>$10</span>
        </li>
      ) : null}

      {cardState.newBookingRequest.baggage.firstRetFirstBaggage && !cardState.newBookingRequest.baggage.secondRetFirstBaggage ? (
        <li>
          <span>Return Carry-on First Baggage: </span>
          <span>$5</span>
        </li>
      ) : null}

      {!cardState.newBookingRequest.baggage.firstRetFirstBaggage && cardState.newBookingRequest.baggage.secondRetFirstBaggage ? (
        <li>
          <span>Return Carry-on Second Baggage: </span>
          <span>$5</span>
        </li>
      ) : null}

      {cardState.newBookingRequest.baggage.firstRetCheckedBaggage && cardState.newBookingRequest.baggage.secondRetCheckedBaggage ? (
        <li>
          <span>Return Checked Baggages: </span>
          <span>$10</span>
        </li>
      ) : null}

{cardState.newBookingRequest.baggage.firstRetCheckedBaggage && !cardState.newBookingRequest.baggage.secondRetCheckedBaggage ? (
        <li>
          <span>Return Checked First Baggage: </span>
          <span>$5</span>
        </li>
      ) : null}

      {!cardState.newBookingRequest.baggage.firstRetCheckedBaggage && cardState.newBookingRequest.baggage.secondRetCheckedBaggage ? (
        <li>
          <span>Return Checked Second Baggage: </span>
          <span>$5</span>
        </li>
      ) : null}
      
    </ul>
  </div>
)}

            {cardState.newBookingRequest.autocheckIn !== false ? (
			<li><span>Auto Check In </span> <span>$7.<sup>99</sup></span> </li>
		    ) : null}
			{cardState.newBookingRequest.flightCancelation !== false ? (
			<li><span>Flight Cancellation </span> <span>$19.<sup>99</sup></span> </li>
			) : null}	
			{/* <li><span>Flight Alert </span> <span>$35.<sup>00</sup></span> </li>	 
			{/* {cardState.newBookingRequest.bundle[0] || cardState.newBookingRequest.bundle[1] ? (	
  <li style={{ borderBottom: '0px' }}>
    <span>Bundle </span>
    {cardState.newBookingRequest.bundle[0] === true ? (
    <span>5.<sup>00</sup></span>
    ) : <span>2.<sup>50</sup></span> }
    {cardState.newBookingRequest.bundle[0] === true && cardState.newBookingRequest.bundle[1] === true ? (
      <span>6.<sup>50</sup></span>
    ) : null}
  </li>
) : null} 
{/* {cardState.newBookingRequest.bundle[0] || cardState.newBookingRequest.bundle[1] ? (  
  <li style={{ borderBottom: '0px' }}>
    <span>Bundle </span>
    {cardState.newBookingRequest.bundle[0] === true ? (
      <span>4.<sup>50</sup></span>
    ) : null}
    {cardState.newBookingRequest.bundle[1] === true ? (
      <span>2.<sup>00</sup></span>
    ) : null}
    {cardState.newBookingRequest.bundle[0] === true && cardState.newBookingRequest.bundle[1] === true ? (
      <span>6.<sup>50</sup></span> // Total price when both are true
    ) : null}
  </li>
) : null} 
{cardState.newBookingRequest.bundle[0] || cardState.newBookingRequest.bundle[1] ? (  
  <li style={{ borderBottom: '0px' }}>
    <span>Bundle </span>
    {cardState.newBookingRequest.bundle[0] === true && cardState.newBookingRequest.bundle[1] === true ? (
      <span>6.<sup>50</sup></span> // Total price when both are true
    ) : (
      <>
        {cardState.newBookingRequest.bundle[0] === true ? (
          <span>4.<sup>50</sup></span>
        ) : null}
        {cardState.newBookingRequest.bundle[1] === true ? (
          <span>2.<sup>00</sup></span>
        ) : null}
      </>
    )}
  </li>
) : null}
			</ul> 
				
				<div class="subTotal-booked">
				<h6> Total Price : <div style={{float: 'right'}}>$<span><sup></sup></span></div></h6>
				</div>
				
				<p class="note"> 
					<strong> Note:-</strong> All Price are quoted in USD.Your credit/debit card may be billed in multiple charges totaling the final total price.	Baggage allowance and fee amount are not guaranteed and are subject to change by the airline.
				
				</p>
			
			</div>			
			</div>
			</div>
			
			<div class="card-detail-container" style={{display: 'none'}}> 
			  <h3 class="new_payment_title" style={{display: 'flex'}}>
							  Book with <img src="resources/images/logo_.png" width="auto" style={{width: '42%'}} alt="" /></h3> 
				
				<hr style={{margin: '5px'}} />
						  <div class="car-sa-row">
							  <i class="fa fa-check"></i><span> Best Price Assurance</span>
						  </div>
						  <div class="car-sa-row">
							  <i class="fa fa-check"></i><span> Exclusive deals for hundreds of destinations</span>
						  </div>
						  <div class="car-sa-row">
							  <i class="fa fa-check"></i><span> 24/7 assistance by Travel Experts</span>
						  </div>
						
			</div>
			
			
			
        </div> */}
      </div>
      <div class="row">			  
		    <div class="details-submit">
            <label for="tc">
            <div style={{verticalAlign: 'top', paddingTop: '25px'}}>
                  <p style={{fontSize: '14px'}}>
                <input type="checkbox" name="agree" id="iagree" checked={isChecked} onChange={handleCheckboxChange} />
                By clicking Book Now, I agree that I have read and accepted rawfares.com
					  <a href="/" target="_blank" class="tcshow">Terms and Conditions</a> and 
					  <a href="/" target="_blank" class="ppshow">Privacy Policy</a></p>
                </div>
            </label>
          </div>			  
            {/* <!--<div class="col-md-12 card-price-select" style="text-align: center; margin-bottom: 8px;">
              <button type="button" href="#">Confirm &amp; Book</button>
            </div>--> */}
          </div>
		<div class="row">			  
		    {/* <!--<div class="details-submit">
            <label for="tc">
            <div style="vertical-align: top; padding-top: 0px;">
                  <p style="font-size: 14px;">
                <input type="checkbox" name="agree" id="iagree">
                By clicking Book Now, I agree that I have read and accepted travodeals.us 
					  <a href="/" target="_blank" class="tcshow">Terms and Conditions</a> and 
					  <a href="/" target="_blank" class="ppshow">Privacy Policy</a></p>
                </div>
            </label>
          </div>-->			   */}
            <div class="col-md-12 card-price-select" style={{textAlign: 'center', marginBottom: '8px'}}>
              <button className='confirm' type="button" disabled={!isChecked} ref={formRef} onClick={handleSubmit}>Confirm &amp; Book</button>
            </div>
          </div>
       
      
    </div>
  </div>
</div>
<Footer2 />
</>
  )
}
