import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Import airline components
import Southwest from './Southwest';
import Aeromexico from './Aeromexico';
import AirCanada from './AirCanada';
import Alaska from './Alaska';
import American from './American';
import British from './British';
import Copa from './Copa';
import Emirates from './Emirates';
import Etihad from './Etihad';
import Frontier from './Frontier';
import JetBlue from './JetBlue';
import Latam from './Latam';
import Lufthansa from './Lufthansa';
import Qatar from './Qatar';
import Spirit from './Spirit';
import Turkish from './Turkish';
import United from './United';
import Viva from './Viva';
import Volaris from './Volaris';
import SAS from './SAS';
import EgyptAir from './EgyptAir';
import Austrian from './Austrian';
import AirNewZealand from './AirNewZealand';
import AllNipponAirways from './AllNipponAirways';
import Aerolíneas from './Aerolíneas';
import Kenya from './Kenya';
import KLM from './KLM';
import VirginAtlantic from './VirginAtlantic';
import AirFrance from './AirFrance';
import AzulBrazilian from './AzulBrazilian';
import EVA from './EVA';
import GolLinhas from './GolLinhas';
import Kuwait from './Kuwait';
import Philippine from './Philippine';
import RoyalAirMaroc from './RoyalAirMaroc';
import RoyalJordanian from './RoyalJordanian';
import VirginAustralia from './VirginAustralia';
import Qantas from './Qantas';
// Import other airline components...

export default  function AirfareDeals() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const airlineName = searchParams.get('aname');
    const tfn = searchParams.get('tfn');
    const utmSource = searchParams.get('utm_source');

    // Ensure 'tfn' and 'utm_source' have fixed values
    useEffect(() => {
    if (tfn !== '1536' || utmSource !== 'cpc') {
        // Redirect to the correct URL with fixed 'tfn' and 'utm_source'
        navigate(`/airfare-deals?tfn=1536&aname=${airlineName || 'Viva Aerobus'}&utm_source=cpc`, { replace: true });
        return null;
    }
}, [tfn, utmSource, airlineName, navigate]);

useEffect(() => {
    if (typeof window.gtag === 'function') {
        window.gtag('config', 'G-4T4E0J0CXE', {
            'page_path': location.pathname + location.search,
            'page_title': `${airlineName} | Cheap Flights | Rawfares`,
        });
    }
}, [location, airlineName]);

    // Render the appropriate airline component based on 'aname'
    switch (airlineName) {
        case 'Southwest Airline':
            return <Southwest />;
        case 'Aeromexico':
            return <Aeromexico />;
        // Add cases for other airlines...
        case 'Viva Aerobus':
            return <Viva />;
        case 'Air Canada':
            return <AirCanada />; 
        case 'Alaska Airlines':
            return <Alaska />;  
        case 'American Airlines':
            return <American />; 
        case 'British Airways':
            return <British />;
        case 'Copa Airlines':
            return <Copa />;
        case 'Emirates':
            return <Emirates />;
        case 'Etihad Airways':
            return <Etihad />;
        case 'Frontier Airlines':
            return <Frontier />;
        case 'JetBlue Airlines':
            return <JetBlue />;
        case 'Latam':
            return <Latam />;
        case 'Lufthansa Airlines':
            return <Lufthansa />;
        case 'Qatar Airways':
            return <Qatar />;
        case 'Spirit Airlines':
            return <Spirit />;
        case 'Turkish Airlines':
            return <Turkish />;
        case 'United States Airline':
            return <United />;
        case 'Volaris':
            return <Volaris />;
        case 'SAS Airlines':
            return <SAS />;
        case 'EgyptAir':
            return <EgyptAir />;
        case 'Austrian Airlines':
            return <Austrian />;
        case 'Air New Zealand':
            return <AirNewZealand />;
        case 'Aerolíneas Argentinas':
            return <Aerolíneas />;
        case 'Kenya Airways':
            return <Kenya />;
        case 'KLM':
            return <KLM />;
        case 'Virgin Atlantic':
            return <VirginAtlantic />;
        case 'Air France':
            return <AirFrance />;
        case 'Azul Brazilian Airlines':
            return <AzulBrazilian />;
        case 'EVA Air':
            return <EVA />;
        case 'Gol Linhas Aéreas Inteligentes':
            return <GolLinhas />;
        case 'Kuwait Airways':
            return <Kuwait />;
        case 'Philippine Airlines':
            return <Philippine />;
        case 'Royal Jordanian':
            return <RoyalJordanian />;
        case 'Royal Air Maroc':
            return <RoyalAirMaroc />;
        case 'Virgin Australia':
            return <VirginAustralia />;
        case 'Qantas':
            return <Qantas />;
        default:
            return <div>Airline not found</div>;
    }
}
