/* eslint-disable no-unused-vars */
import React from 'react'
import Footer from '../components/Footer/footer'
import MetaTitle from './metaTitle';
import SITE_CONFIG from '../SiteController/SiteController';

export default function OurServicesFee() {
   const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
   <>
   <MetaTitle url={`${apiIP}/our-services-fee`} pageTitle="Find Our Services Fee - Rawfares" pageDescription="You can find All service fees on Rawfares" />
<body>
<table style={{width: '98%', margin: 'auto', maxWidth: '580px', marginTop: '70px'}}>
<thead>
<tr>
   <th style={{width: '60%'}}>Our Service Fees �</th>
   <th>May Apply To</th>
   <th>Code</th>
   <th>Amount</th>
</tr>
</thead>
<tbody>
<tr>
   <td>
      <div style={{color: '#303030',fontWeight: 'bold'}}>Online Air Transaction Service Fees</div>
      <div style={{fontSize: '12px'}}>On most airfares a service fee up to $35? is charged on a per-passenger, per-ticket basis. **</div>
   </td>
   <td>
       U.S. Domestic and International
   </td>
   <td>
       Fees
   </td>
   <td>
      $0.00 to $35.00
   </td>
</tr>
<tr>
   <td colspan="4" style={{textAlign: 'left'}}>
     <div>Service fees will be converted in your local currency on the payment page.</div>
     <div>*Approximate amount</div>
     <div>** Passenger types = Adult, child, senior, infant, student, military.</div>
     <div>� All transaction service fees are non-refundable and are subject to change without notice.
Government imposed taxes and fees are subject to change. You will only be charged the final total amount as shown.</div>
     <br/>
     <div style={{color: '#303030', fontWeight: 'bold'}}> Certain exceptions to the above service fees apply</div>
     <div>- Online vacation package booking service fees are up to $50 per passenger.</div>
     <div>- Business and First Class Airfares - Service fees are up to $150 per passenger.</div>
     <div>- Multi-city and Open-jaw trips - Service fees are up to $100 per passenger.</div>
     <div>- Cities with high fraud rates - Service fees are up to $40 per passenger</div>
     <div>- Alternate Date & Nearby Airport - Service fees are up to $55 per passenger</div>
     <div>- Fusion Fares/Value Deals - Service fees are up to $100 per passenger.</div>
     <div>- Unaccompanied Minor Tickets - Service fees are up to $100 per passenger.</div>
     <div>- Contact Center booking service fees: Service fees for contact center bookings (including complex multi-stop and round-the-world itineraries) may be higher than those charged for bookings made online. These service fees can range from $10 to $200 per passenger (up to $100 for Senior Citizens). Contact Center bookings include premium package benefits.</div>
     <br/>
     <br/>
     <div style={{color: '#303030'}}>Important Note: All service fees are subject to change without notice.<u> YOU WILL BE CHARGED THE FINAL TOTAL PRICE AS QUOTED REGARDLESS OF ANY CHANGE OR VARIANCE IN THE SERVICE FEES.</u> Please review the total final price carefully.</div>
     <br/>
     <div style={{color: '#303030',fontWeight: 'bold'}}>Read more about service / booking fees in our <a href="/terms-and-conditions">terms and conditions</a>.</div>
   </td>
</tr>
</tbody>
</table>

</body>
<Footer />
</>
  )
}
